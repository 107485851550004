import { faCheckSquare, faInfoCircle, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { Form, FormGroup, Input, Label, Modal, ModalBody, Table, ModalHeader, CustomInput } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import AdditionalScores from './AdditionalScores/AdditionalScores';
import { formatBearerToken, copyObject } from '../../../../../Common.functions';
import { PopupWithCloseButton } from '../../../../../Common.jsx';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { fetchDataAgnostic, postDataAgnostic, putData, deleteData } from '../../../../../Services/dataApi';
import AdminEvaluationDreyfusItem from '../AdminEvaluationQuestionTemplate/AdminEvaluationScaleItem/AdminEvaluationDreyfusItem';
import AdminEvaluationLikertItem from '../AdminEvaluationQuestionTemplate/AdminEvaluationScaleItem/AdminEvaluationLikertItem';
import EvaluationQuestionsSort from './EvaluationQuestionsSort';
import { useAlert } from 'react-alert';
import AdminEvaluationQuestionTemplate from '../AdminEvaluationQuestionTemplate/AdminEvaluationQuestionTemplate';

import './EvaluationQuestion.style.css';
import {
  defaultLikert5QuestionState,
  questionScaleKeys,
  defaultLikert10QuestionScale,
  defaultDreyfus10QuestionScale,
} from '../AdminEvaluationSettingsConstants';

import style from './EvaluationQuestions.style';
import { clone } from '../../../../../Services/schedule';
import { useHistory } from 'react-router-dom';
import EvaluationQuestionOptions from './EvaluationQuestionOptions';
import { RouterPrompt } from '../../../Common/RouterPrompt/RouterPrompt';
import Swal from 'sweetalert2';
import { arrayMove } from 'react-sortable-hoc';

const newQuestions = {
  uuid: uuidv4(),
  SortOrder: '1',
  QuestionText: '',
  labels: [],
  required: false,
  prescreen: false,
  evaluation: false,
  weightCategory: '',
  focusLimit: '',
  evaluationType: '',
  effectiveWeight: '',
};

const newAdditionalScore = {
  MinOrNoValue: 1,
  MaxOrYesValue: 2,
  EnableEvaluationForm: false,
  EnablePrescreenForm: false,
};

const placeholderOptionsTwo = ['USMLE Step 1', 'Comlex Step 1', 'Alpha Omega Alpha'];

const placeholderOptions = ['1', '2', '3'];

const EvaluationQuestions = ({ setPageChanges, pageChanges }) => {
  const [questionScale, setQuestionScale] = useState({});
  const [questionWeights, setQuestionWeights] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [editableQuestions, setEditableQuestions] = useState([]);

  const [selectedQuestionId, setSelectedQuestionId] = useState('');
  const [numOfQuestionsInWeight, setNumOfQuestionsInWeight] = useState({});
  const [additionalScoringOptions, setAdditionalScoringOptions] = useState([]);
  const [editableAdditionalScoringOptions, setEditableAdditionalScoringOptions] = useState([]);

  const [newGroupName, setNewGroupName] = useState('');
  const [additionalScoresEdited, setAdditionalScoresEdited] = useState(false);
  const [sortOrderChoices, setSortOrderChoices] = useState([]);
  const [additionalFieldOptions, setAdditionalFieldOptions] = useState([]);
  const [popupCustomLabel, setPopupCustomLabel] = useState(false);
  const [newGroupPopup, setNewGroupPopup] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const [labelUseDefault, setLabelUseDefault] = useState(false); // Dummy data. Will need to be a per-question flag i nthe future
  const [defaultLabels, setDefaultLabels] = useState([]);

  const [season, setSeason] = useState({});

  // RegistrationLimitPopover

  const dContext = useContext(DepartmentContext);

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const alert = useAlert();
  const history = useHistory();

  const calculateWeights = (questions, questionWeights) => {
    const weightsAsKeys = {};
    questionWeights.forEach((item) => {
      weightsAsKeys[item.pk_EvaluationQuestionWeight] = {
        prescreen: 0,
        evaluation: 0,
      };
    });

    questions.forEach((group) => {
      if (group.questions) {
        group.questions.forEach((item) => {
          const { pk_EvaluationQuestionWeight, EnableEvaluationForm, EnablePrescreenForm } = item;
          if (EnableEvaluationForm && weightsAsKeys[pk_EvaluationQuestionWeight]) {
            weightsAsKeys[pk_EvaluationQuestionWeight].evaluation += 1;
          }
          if (EnablePrescreenForm && weightsAsKeys[pk_EvaluationQuestionWeight]) {
            weightsAsKeys[pk_EvaluationQuestionWeight].prescreen += 1;
          }
        });
      } else {
      }
    });

    editableAdditionalScoringOptions.forEach((score) => {
      const { fk_EvaluationQuestionWeight, EnableEvaluationForm, EnablePrescreenForm } = score;
      if (EnableEvaluationForm && weightsAsKeys[fk_EvaluationQuestionWeight])
        weightsAsKeys[fk_EvaluationQuestionWeight].evaluation += 1;
      if (EnablePrescreenForm && weightsAsKeys[fk_EvaluationQuestionWeight])
        weightsAsKeys[fk_EvaluationQuestionWeight].prescreen += 1;
    });

    setNumOfQuestionsInWeight(weightsAsKeys);
  };

  const informQuestionSaved = () => {
    alert.success('Saved Changes!');
  };

  const informatQuestionSaveFailed = () => {
    alert.error('Error saving changes!');
  };

  const getAdditionalScoring = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/additionalScoring',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((result) => {
            setAdditionalScoringOptions(result.data);
            setEditableAdditionalScoringOptions(result.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  useEffect(() => {
    getQuestions();
    getDataTypes();
    getAdditionalScoring();
    // getTemplates(); // Doesn't seem to do anything.
    getWeights();
    getSeason();
    getScaleItems(); // TO DO!
  }, []);

  useEffect(() => {
    if (editableQuestions.length > 0 && questionWeights.length > 0) {
      calculateWeights(editableQuestions, questionWeights);
    }
  }, [questionWeights, editableQuestions, editableAdditionalScoringOptions]);

  useEffect(() => {
    if (questions && questions.length > 0 && Object.keys(selectedQuestion).length > 0) {
      const { groupIndex, questionIndex } = selectedQuestion;
      setSelectedQuestion({ ...questions[groupIndex].questions[questionIndex], groupIndex, questionIndex });
    }
  }, [JSON.stringify(questions)]);

  useEffect(() => {
    if (editableQuestions && JSON.stringify(editableQuestions) !== JSON.stringify(questions)) {
      setPageChanges({ ...pageChanges, questions: true });
    } else {
      setPageChanges({ ...pageChanges, questions: false });
    }
  }, [JSON.stringify(editableQuestions)]);

  useEffect(() => {
    if (
      editableAdditionalScoringOptions &&
      JSON.stringify(editableAdditionalScoringOptions) !== JSON.stringify(additionalScoringOptions)
    ) {
      setAdditionalScoresEdited(true);
      setPageChanges({ ...pageChanges, questions: true });
    } else {
      setAdditionalScoresEdited(false);
      setPageChanges({ ...pageChanges, questions: false });
    }
  }, [JSON.stringify(editableAdditionalScoringOptions)]);

  useEffect(() => {
    if (selectedQuestion) {
    }
  }, [selectedQuestion]);

  const getDataTypes = () => {
    // /department/dataTypes?pk_Department=6
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/dataTypes',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((result) => {
            const { data } = result;
            // filter by displayed properties
            const filteredObject = Object.keys(data)
              // .filter((key) => data[key].DisplayToEvaluator)
              .filter((key) => {
                const item = data[key];
                return item.Enabled && (item.Type == 1 || item.Type == 3);
              })
              .reduce((res, key) => ((res[key] = data[key]), res), {});

            setAdditionalFieldOptions(filteredObject);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const labelClick = (question) => {
    setSelectedQuestion(question);
    setPopupCustomLabel(true);
  };

  const getScaleItems = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/scale',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            const { data } = res;
            const { ScaleType } = data;
            let min = 1;
            let max = 10;
            if (ScaleType === 'likert5' || ScaleType === 'dreyfus5') {
              max = 5;
            }

            setQuestionScale({ ...data, min, max });
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getWeights = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/weightCategories',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        ).then((result) => {
          setQuestionWeights(result.data);
        });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getQuestions = () => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/questions',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((result) => {
            setIsUpdating(false);
            if (result.data && result.data.EvalQuestions) {
              const newQuestionList = clone(result.data.EvalQuestions).map((group) => {
                group.questions.forEach((q) => {
                  q.isToDelete = false;
                });
                return group;
              });

              setQuestions(newQuestionList);
              setEditableQuestions(newQuestionList);
            } else {
              setQuestions([newQuestions]);
            }

            if (result.data && result.data.DefaultScale) {
              result.data.DefaultScale.forEach((scale) => {
                delete scale.pk_QuestionOption;
              });
              setDefaultLabels(result.data.DefaultScale);
            }
          })
          .catch((err) => {
            setIsUpdating(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const updateQuestionOptions = (optionIndex, key, value) => {
    const { questionIndex, groupIndex } = selectedQuestion;
    const newQuestions = clone(editableQuestions);
    const newQuestion = clone(selectedQuestion);
    const newOptions = newQuestion.CustomScale ? clone(newQuestion.CustomScale) : clone(defaultLabels);
    newOptions[optionIndex][key] = value;
    newQuestion.CustomScale = clone(newOptions);
    newQuestions[groupIndex][questionIndex] = newQuestion;
    setEditableQuestions(newQuestions);
    setSelectedQuestion(newQuestion);
  };

  const checkScaleMatch = (term) => {
    if (questionScale === term) return true;

    return false;
  };

  const processOptions = () => {
    const { CustomScale, EnableCustomScale } = selectedQuestion;

    if (CustomScale && EnableCustomScale) {
      return CustomScale;
    } else if (defaultLabels && defaultLabels.length > 0) {
      return defaultLabels;
    } else {
      return [];
    }
  };

  const handleDatabaseDeletion = (pk_AdditionalScore) => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/additionalScore',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_AdditionalScore,
          },
          null,
          formatBearerToken(token),
        )
          .then((res) => {
            getAdditionalScoring();
            informQuestionSaved();
          })
          .catch((err) => {
            informatQuestionSaveFailed();
          })
          .finally(() => {
            setIsUpdating(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const handleAdditionalScoreDelete = (pk_AdditionalScore, tempIdentity) => {
    Swal.fire({
      title: 'Confirmation',
      icon: 'warning',
      text: 'Are you sure you want to delete this item?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'red',
      confirmButtonText: `Delete it`,
    }).then((result) => {
      if (result.value) {
        // if it has a pk, it needs ot be deleted from the datbase. Otherwise it hasn't been saved
        // to the dabatabse yet and just needs to be removed from the array
        if (pk_AdditionalScore) {
          handleDatabaseDeletion(pk_AdditionalScore);
          return;
        }

        setEditableAdditionalScoringOptions((prevState) => {
          let prevStateCopy = [...prevState];

          prevStateCopy = prevState.filter((item) => item.tempIdentity !== tempIdentity);
          return prevStateCopy;
        });
      }
    });
  };

  const handleAddtionalScorePropertyChange = (property, identity, value) => {
    setEditableAdditionalScoringOptions((prevState) => {
      const prevStateCopy = copyObject(prevState);

      if (additionalScoresEdited === false) {
        setAdditionalScoresEdited(true);
      }
      prevStateCopy.forEach((item) => {
        const { pk_AdditionalScore, tempIdentity } = item;

        if (identity === pk_AdditionalScore || identity === tempIdentity) {
          if (property === 'eval') {
            item.EnableEvaluationForm = !item.EnableEvaluationForm;
          } else if (property === 'prescreen') {
            item.EnablePrescreenForm = !item.EnablePrescreenForm;
          } else if (property === 'min') {
            item.MinOrNoValue = Number(value);
          } else if (property === 'max') {
            item.MaxOrYesValue = Number(value);
          } else if (property === 'field') {
            item.ScoreType = value;
          } else if (property === 'weight') {
            item.fk_EvaluationQuestionWeight = Number(value);
          }
        }
      });

      return prevStateCopy;
    });
  };

  const handleNewAdditonalScore = (e) => {
    e.preventDefault();

    setEditableAdditionalScoringOptions((prevState) => {
      const prevStateCopy = copyObject(prevState);
      const newAdditionalScoreCopy = copyObject(newAdditionalScore);

      // need to give it a temporary unique ID until it's posted in the database
      newAdditionalScoreCopy.tempIdentity = uuidv4();
      newAdditionalScoreCopy.fk_EvaluationQuestionWeight = '';
      const fieldOptionActual = Object.values(additionalFieldOptions)[0];
      newAdditionalScoreCopy.ScoreType = '';
      newAdditionalScoreCopy.Type = fieldOptionActual ? fieldOptionActual.Type : 1;
      newAdditionalScoreCopy.MinOrNoValue = 1;
      newAdditionalScoreCopy.MaxOrYesValue = 2;

      prevStateCopy.push(newAdditionalScoreCopy);

      return prevStateCopy;
    });
  };

  const renderPopup = () => {
    return (
      <EvaluationQuestionOptions
        isReadOnly={!selectedQuestion.EnableCustomScale}
        options={processOptions()}
        questionScale={questionScale}
        updateQuestion={updateQuestionOptions}
      />
    );
  };

  const validateAdditionalScores = () => {
    const valueArr = editableAdditionalScoringOptions.map((item) => item.ScoreType);
    const isDuplicate = valueArr.some(function(item, idx) {
      return valueArr.indexOf(item) != idx;
    });

    if (isDuplicate) {
      return { isValid: false, message: 'Cannot save scores with duplicate score types' };
    }

    let maxScoreIsLowerOrEqualToMin = false;
    let hasError = false;
    let errorToReturn = null;

    editableAdditionalScoringOptions.forEach((item, i) => {
      const { MinOrNoValue, MaxOrYesValue, Type, ScoreType, fk_EvaluationQuestionWeight } = item;

      if (Type == 2 && MinOrNoValue >= MaxOrYesValue) {
        maxScoreIsLowerOrEqualToMin = true;
      }

      if (!hasError && Type == 3 && MinOrNoValue == MaxOrYesValue) {
        hasError = true;
        errorToReturn = { isValid: false, message: 'One or more Yes/No items have the same Yes and No values.' };
      }

      if (!hasError && ScoreType == '') {
        hasError = true;
        errorToReturn = { isValid: false, message: 'One or more items have no selected Types.' };
      }

      if (!hasError && fk_EvaluationQuestionWeight == '') {
        hasError = true;
        errorToReturn = { isValid: false, message: 'One or more items have no selected Category.' };
      }
    });

    if (hasError) {
      return errorToReturn;
    }
    if (maxScoreIsLowerOrEqualToMin) {
      return { isValid: false, message: 'Please make sure all max values are higher than min values ' };
    }

    return { isValid: true };
  };

  const saveAdditionalScores = () => {
    const validityObject = validateAdditionalScores();
    if (!validityObject.isValid) {
      Swal.fire('Error', validityObject.message, 'error');
      return;
    }

    const additionalScoringOptionsCopy = copyObject(editableAdditionalScoringOptions);

    // need to add field types
    additionalScoringOptionsCopy.forEach((item, i) => {
      const { ScoreType } = item;
      const type = additionalFieldOptions[ScoreType].Type;
      additionalScoringOptionsCopy[i].ScoreNumberType = type;
    });
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/additionalScores',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          { payload: additionalScoringOptionsCopy },
          formatBearerToken(token),
        )
          .then((result) => {
            getAdditionalScoring();
            alert.success('Saved Changes!');
          })
          .catch((err) => {
            alert.error('Error saving changes!');
          })
          .finally(() => {
            setIsUpdating(false);
            setAdditionalScoresEdited(false);
          });
      })
      .catch((err) => {
        setIsUpdating(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const saveQuestions = () => {
    const questionsList = [];
    setIsUpdating(true);
    editableQuestions.forEach((qGroup, i) => {
      if (qGroup.questions) {
        qGroup.questions
          .filter((q, ii) => {
            return (
              !q.pk_EvaluationQuestion ||
              (!q.isToDelete && JSON.stringify(q) !== JSON.stringify(questions[i].questions[ii] || {}))
            );
          })
          .forEach((q) => {
            const newQuestion = { ...q };
            newQuestion.fk_EvaluationQuestionWeight = q.pk_EvaluationQuestionWeight;
            newQuestion.fk_QuestionGroup = qGroup.pk_QuestionGroup;
            newQuestion.EnablePrescreenForm = newQuestion.EnablePrescreenForm ? 1 : 0;
            newQuestion.EnableEvaluationForm = newQuestion.EnableEvaluationForm ? 1 : 0;
            newQuestion.EnableRequired = newQuestion.EnableRequired ? 1 : 0;
            newQuestion.SortOrder = newQuestion.EvaluationQuestionSortOrder;

            if (newQuestion.Focuses) {
              newQuestion.FocusPrimaryKeys = newQuestion.Focuses.map((f) => {
                return f.pk_Focus;
              });
              delete newQuestion.Focuses;
            }

            delete newQuestion.EvaluationQuestionSortOrder;
            delete newQuestion.GroupName;
            delete newQuestion.WeightQuestionType;
            delete newQuestion.pk_EvaluationQuestionWeight;
            delete newQuestion.pk_QuestionGroup;

            questionsList.push(newQuestion);
          });
      }
    });

    if (questionsList.length == 0) {
      return;
    }

    getTokenSilently()
      .then((token) => {
        putData(
          'department/questions',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          { payload: questionsList },
          formatBearerToken(token),
        )
          .then((result) => {
            getQuestions();
            setPageChanges({ ...pageChanges, questions: false });
            alert.success('Saved Changes!');
          })
          .catch((err) => {
            alert.error('Error saving changes!');
          })
          .finally(() => {
            setIsUpdating(false);
          });
      })
      .catch((err) => {
        setIsUpdating(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
        if (questionsList.length == 0) {
          return;
        }
      });
  };

  const deleteQuestions = () => {
    const questionsToDelete = editableQuestions.reduce((acc, qGroup) => {
      if (qGroup.questions) {
        qGroup.questions.forEach((q) => {
          if (q.isToDelete) {
            acc.push(q.pk_EvaluationQuestion);
          }
        });
      }
      return acc;
    }, []);

    // TO DO: Questions marked for deletion but removed, still gets deleted. wtf.
    // Add Green color to Questions that don't have pk_EvaluationQuestion. They are new questions.

    if (questionsToDelete.length == 0) {
      return;
    }
    getTokenSilently().then((token) => {
      deleteData(
        'department/questions',
        {
          pk_Department: dContext.department.pk_Department,
          pk_Season: dContext.season.pk_Season,
        },
        { questionPrimaryKeys: questionsToDelete },
        formatBearerToken(token),
      )
        .then((res) => {
          getQuestions();
          alert.success(`Question${questionsToDelete.length > 1 ? 's' : ''} deleted!`);
        })
        .catch((err) => {
          alert.error(`Error deleting question${questionsToDelete.length > 1 ? 's' : ''}!`);
        });
    });
  };

  useEffect(() => {
    // need to create sort order choices based on the length of questions available
    // For insance, 3 questions wuld allow the user to choose position 1, 2, and 3
    const sortOrderChoices = questions.map((item, index) => {
      return index + 1;
    });

    setSortOrderChoices(sortOrderChoices);
  }, [questions]);

  const getSeason = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setSeason(res);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const submitGroup = () => {
    getTokenSilently()
      .then((token) => {
        const body = { groupName: newGroupName, sortOrder: questions.length + 1 };
        postDataAgnostic(
          'department/group',
          { pk_Department: dContext.department.pk_Department },
          body,
          formatBearerToken(token),
        )
          .then((result) => {
            if (result.error) {
              //error handling
            } else {
              getQuestions();
              // setQuestions((prevState) => {
              //   const questionsCopy = JSON.parse(JSON.stringify(prevState));
              //   questionsCopy.push(result.data);
              //   return questionsCopy;
              // });
            }
          })
          .catch((err) => {})
          .finally(() => {
            setNewGroupName('');
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const renderNewGroupPopup = () => {
    return (
      <div style={{ padding: '15px' }}>
        <Form>
          <FormGroup row>
            <Label for="exampleEmail" sm={2}>
              Group Name
            </Label>
            <Input
              type="text"
              name="email"
              id="exampleEmail"
              placeholder="Group A"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
            />
          </FormGroup>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                submitGroup();
              }}
              color="primary"
            >
              Create New Group
            </Button>
          </div>
        </Form>
      </div>
    );
  };

  const addNewQuestion = (fk_QuestionGroup) => {
    const defaultQuestionWeight = questionWeights[0];

    if (!defaultQuestionWeight) {
      return Swal.fire(
        'Error',
        'Please make sure you have created at least one weight category before creating a question',
        'error',
      );
    }
    setEditableQuestions((prevState) => {
      const prevStateCopy = clone(prevState);
      const itemToPush = {
        uuid: uuidv4(),
        QuestionText: '',
        EnableRequired: false,
        QuestionType: null,
        pk_EvaluationQuestionWeight: defaultQuestionWeight.pk_EvaluationQuestionWeight,
        EnablePrescreenForm: true,
        fk_QuestionGroup,
        EnableEvaluationForm: true,
      };

      prevStateCopy.forEach((item, i) => {
        if (item.pk_QuestionGroup === fk_QuestionGroup) {
          itemToPush.EvaluationQuestionSortOrder = prevStateCopy[i].questions.length + 1;
          prevStateCopy[i].questions.push(itemToPush);
        }
      });
      return prevStateCopy;
    });
  };

  const changeValue = (groupIndex, questionIndex, typeToChange, value, pk_QuestionGroup) => {
    const newEditableQuestions = clone(editableQuestions);
    const questionGroup = newEditableQuestions[groupIndex];
    const question = questionGroup.questions[questionIndex];

    switch (typeToChange) {
      case 'category':
        question.pk_EvaluationQuestionWeight = Number(value);
        break;
      case 'prescreen':
        question.EnablePrescreenForm = !question.EnablePrescreenForm;
        break;
      case 'evaluation':
        question.EnableEvaluationForm = !question.EnableEvaluationForm;
        break;
      case 'QuestionText':
        question.QuestionText = value;
        break;
      case 'Description':
        question.Description = value;
        break;

      case 'EnableCustomScale':
        question.EnableCustomScale = value;
        setSelectedQuestion({ ...selectedQuestion, EnableCustomScale: value });
        break;

      case 'Focuses':
        question.Focuses = value;
        break;
      default:
        break;
    }

    questionGroup.questions[questionIndex] = question;
    newEditableQuestions[groupIndex] = questionGroup;
    setEditableQuestions(newEditableQuestions);
  };

  const changeSortOrder = (groupIndex, questionIndex, newSortValue) => {
    const newEditableQuestions = clone(editableQuestions);
    const newQuestionsList = arrayMove(newEditableQuestions[groupIndex].questions, questionIndex, newSortValue - 1);

    newQuestionsList.forEach((q, i) => {
      q.EvaluationQuestionSortOrder = i + 1;
    });

    newEditableQuestions[groupIndex].questions = newQuestionsList;
    setEditableQuestions(newEditableQuestions);
  };

  const saveNewOptions = (data) => {
    let payload = [];
    if (selectedQuestion.EnableCustomScale) {
      data.forEach((d) => {
        const { NumericValue, SortOrder, Label, Description, DisplayValue, pk_QuestionOption } = d;
        const newD = { NumericValue, SortOrder, Label, Description, DisplayValue, pk_QuestionOption };
        if (pk_QuestionOption == null) {
          delete newD.pk_QuestionOption;
        }
        payload.push(newD);
      });
    } else {
      payload = null;
    }

    setPopupCustomLabel(false);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/season/scale/custom',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_EvaluationQuestion: selectedQuestion.pk_EvaluationQuestion,
          },
          {
            enableCustomScale: selectedQuestion.EnableCustomScale,
            payload,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            getQuestions();

            alert.success('Custom Options saved!');
          })
          .catch((err) => {
            alert.error('Error saving Options!');
            setPopupCustomLabel(true);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const hasChanges = JSON.stringify(editableQuestions) !== JSON.stringify(questions);

  return (
    <div className="col-12">
      <Modal isOpen={popupCustomLabel} centered={true} style={{ maxWidth: '80vw' }}>
        <ModalHeader
          closeButton
          style={{ padding: 10 }}
          toggle={() => {
            if (hasChanges) {
              Swal.fire({
                text: 'Unsaved changes detected! Do you want to discard changes?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: 'secondary',
                confirmButtonText: 'Discard',
                cancelButtonText: 'Cancel',
              }).then((result) => {
                if (result.value) {
                  const { questionIndex, groupIndex } = selectedQuestion;
                  const newSelectedQuestion = {
                    ...questions[groupIndex].questions[questionIndex],
                    groupIndex,
                    questionIndex,
                  };
                  // const oldQuestion = questions[groupIndex].questions[questionIndex];
                  // const newQuestions = clone(editableQuestions);
                  // newSelectedQuestion.CustomScale = oldQuestion.CustomScale;
                  // newSelectedQuestion.EnableCustomScale = oldQuestion.EnableCustomScale;
                  // newQuestions[groupIndex].questions[questionIndex] = clone(newSelectedQuestion);
                  setEditableQuestions(questions);
                  setSelectedQuestion(newSelectedQuestion);
                  setPopupCustomLabel(false);
                }
              });
            } else {
              setPopupCustomLabel(false);
            }
          }}
        >
          <div style={{ ...style.simpleColumn, width: '100%' }}>
            <div style={style.spacedBetweenRow}>
              <div>
                <span style={{ fontWeight: 'bold' }}>{selectedQuestion.QuestionText}</span> - Customize response labels
                and descriptions
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div style={{ ...style.simpleRow, justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
            <div style={{ marginRight: 20 }}>
              <CustomInput
                id={'useCustomLabels'}
                type="switch"
                label="Use Custom Labels"
                checked={selectedQuestion.EnableCustomScale}
                onChange={(e) => {
                  const newSelectedQuestion = clone(selectedQuestion);
                  const { groupIndex, questionIndex } = newSelectedQuestion;

                  setLabelUseDefault(e.target.checked);
                  changeValue(groupIndex, questionIndex, 'EnableCustomScale', e.target.checked);
                }}
              />
            </div>

            {/* <Button
              color="success"
              onClick={() => {
                history.push('/administrator/evaluation_template/evaluation_settings');
              }}
            >
              Edit Defaults
            </Button> */}
          </div>
          {renderPopup()}
          <div>
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginTop: 20 }}
            >
              <Button
                color="success"
                onClick={() => {
                  saveNewOptions(selectedQuestion.CustomScale || defaultLabels);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <PopupWithCloseButton open={newGroupPopup} closeOnDocumentClick={true} closeFunction={setNewGroupPopup}>
        {renderNewGroupPopup()}
      </PopupWithCloseButton>
      <div>
        <div className="col-12 text-center">
          <div className="row">
            <div className="card shadow bg-light" style={{ width: '100%' }}>
              <div className="card-header">
                <h4>Questionaire Configuration</h4>
              </div>
              <div className="card-body" style={{ padding: '0px', height: '45vh', overflowY: 'scroll' }}>
                <Table>
                  <thead style={{ position: 'sticky', top: -1, background: 'white' }}>
                    <tr>
                      <th style={{ width: 100, fontWeight: 'bold' }}>Delete</th>
                      <th style={{ width: '10%', fontWeight: 'bold' }}>Order</th>
                      <th style={{ width: '2%', fontWeight: 'bold' }}>Required</th>
                      <th style={{ width: '35%', fontWeight: 'bold' }}>Question Text</th>
                      <th style={{ width: 80, fontWeight: 'bold' }}>Labels</th>
                      <th style={{ width: '15%', fontWeight: 'bold' }}>Weight Category</th>
                      <th style={{ width: '30%', fontWeight: 'bold' }}>Focus Limit</th>
                      <th style={{ width: 100, fontWeight: 'bold' }}>Evaluation Type</th>
                      <th style={{ width: 50, fontWeight: 'bold' }}>Effective Weight</th>
                    </tr>
                  </thead>
                  <EvaluationQuestionsSort
                    changeSortOrder={changeSortOrder}
                    changeValue={changeValue}
                    defaultQuestionWeight={questionWeights[0]}
                    getQuestions={getQuestions}
                    isUpdating={isUpdating}
                    labelClick={labelClick}
                    numOfQuestionsInWeight={numOfQuestionsInWeight}
                    questions={editableQuestions}
                    questionWeights={questionWeights}
                    season={season}
                    selectedQuestionId={selectedQuestionId}
                    setEditableQuestions={setEditableQuestions}
                    setQuestions={setQuestions}
                    setSelectedQuestionId={setSelectedQuestionId}
                    sortOrderChoices={sortOrderChoices}
                  />
                </Table>
              </div>
              <div className="card-footer text-muted">
                <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
                  *Required
                  {/* <div className="col-3" style={{ textAlign: 'right' }}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setNewGroupPopup(true);
                        // addNewQuestion();
                      }}
                      className="btn btn-success btn-sm"
                    >
                      <FontAwesomeIcon /> Add Group
                    </Button>
                  </div> */}
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
            <Button
              disabled={isUpdating || JSON.stringify(questions) === JSON.stringify(editableQuestions)}
              color="danger"
              style={{ marginTop: 20, marginRight: 5 }}
              onClick={(e) => {
                e.preventDefault();
                Swal.fire({
                  text: 'Are you sure you want to discard changes?',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: 'secondary',
                  confirmButtonText: 'Discard',
                  cancelButtonText: 'Cancel',
                }).then((result) => {
                  if (result.value) {
                    setEditableQuestions([]);
                    setIsUpdating(true);
                    setTimeout(() => {
                      setEditableQuestions(questions);
                      setIsUpdating(false);
                    }, 500);
                  }
                });
              }}
            >
              Discard Changes
            </Button>
            <Button
              disabled={isUpdating || JSON.stringify(questions) === JSON.stringify(editableQuestions)}
              color="success"
              style={{ marginTop: 20 }}
              onClick={(e) => {
                e.preventDefault();
                setIsUpdating(true);
                saveQuestions();
                deleteQuestions();
              }}
            >
              Save Questions
            </Button>
          </div>
          <div className="row" style={{ height: '50px' }}></div>
          <div className="row">
            <div className="card shadow bg-light" style={{ width: '100%' }}>
              <div className="card-header">
                <h4>Additional Scoring</h4>
                <p>
                  These optional calculations are factored into each candidate's score, but are not part of the
                  questionaire.
                </p>
              </div>
              <div className="card-body" style={{ padding: '0px' }}>
                <Table
                  className="table table-striped"
                  style={{ border: '#DDDDDD', borderWidth: 'thin', borderStyle: 'solid', margin: '0px' }}
                >
                  <thead>
                    <tr>
                      <th>Delete </th>
                      <th>Score Type</th>
                      <th>Weight Category</th>
                      <th>Score Rules</th>
                      <th>Info</th>
                      <th style={{ width: 100, fontWeight: 'bold' }}>Evaluation Type</th>
                      <th style={{ width: 50 }}>Effective Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {editableAdditionalScoringOptions && editableAdditionalScoringOptions.length > 0 ? (
                      editableAdditionalScoringOptions.map((score) => {
                        return (
                          <AdditionalScores
                            AllowPrescreen={season && season.data && season.data.AllowPrescreen}
                            additionalFieldOptions={additionalFieldOptions}
                            editableAdditionalScoringOptions={editableAdditionalScoringOptions}
                            handleAdditionalScoreDelete={handleAdditionalScoreDelete}
                            handleAddtionalScorePropertyChange={handleAddtionalScorePropertyChange}
                            isUpdating={isUpdating}
                            numOfQuestionsInWeight={numOfQuestionsInWeight}
                            questionScale={questionScale}
                            questionWeights={questionWeights}
                            scale={questionScale.max}
                            score={score}
                          />
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={season && season.data && season.data.AllowPrescreen ? 7 : 6}>
                          <div style={{ width: '100%' }}>
                            Additional scores must be linked to an enabled Numeric or Yes/No type candidate field.{' '}
                            <a href={'/administrator/settings/candidateSettings'}>
                              Click here to manage candidate fields.
                            </a>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="card-footer text-muted">
                <div className="row">
                  <div className="col" style={{ textAlign: 'right' }}>
                    <Button
                      disabled={!additionalFieldOptions || Object.values(additionalFieldOptions).length <= 0}
                      className="btn btn-success btn-sm"
                      onClick={handleNewAdditonalScore}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add Scoring Calculation
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <Button
                disabled={
                  !additionalScoresEdited ||
                  // JSON.stringify(editableAdditionalScoringOptions) === JSON.stringify(additionalScoringOptions) ||
                  isUpdating
                }
                color="success"
                style={{ marginTop: 20 }}
                onClick={(e) => {
                  e.preventDefault();
                  saveAdditionalScores();
                }}
              >
                Save Additional Scores
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluationQuestions;

// filter by focus limits
// show all and show none

// ranking from 1 - 10
// can rank individually
// can rank during the day
