import { DepartmentContext } from 'DepartmentWrapper';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';

const SelectByFieldPopover = (props) => {
  const { selectByStringProperty } = props;
  const [stringSelectProperty, setStringSelectProperty] = useState('AAMCID');
  const [selectByString, setSelectBystring] = useState('');

  const dContext = useContext(DepartmentContext);

  const fieldsToSelect = [
    {
      label: 'AAMC ID',
      value: 'AAMCID',
    },
    { label: 'SF Match ID', value: 'SFMatchID', deptSettingsKey: 'sf_match' },
    { label: 'NRMP ID', value: 'NRMPID' },
    { label: 'Medical School', value: 'MedicalSchoolOfGraduation' },
    { label: 'Email', value: 'Email' },
  ];

  useEffect(() => {
    if (dContext && dContext.department && dContext.department.ApplicationSystem === 'sf_match') {
      setStringSelectProperty('SFMatchID');
    }
    return () => {
      setStringSelectProperty('AAMCID');
      setSelectBystring('');
    };
  }, [dContext]);

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <Input
          type="select"
          value={stringSelectProperty}
          rows={6}
          onChange={(e) => {
            setStringSelectProperty(e.target.value);
          }}
        >
          {fieldsToSelect.map((o) => {
            return <option value={o.value}>{o.label}</option>;
          })}
        </Input>
      </div>

      <div>
        <Input
          type="textarea"
          defaultValue={selectByString || ''}
          rows={6}
          placeholder={`${stringSelectProperty || 'ID'} here. . . \nex: ID1,ID2,ID3. . .`}
          onChange={(e) => {
            const newValue = e.target.value;
            setSelectBystring(newValue);
          }}
        />
      </div>

      <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 10 }}>
        <Button
          color="success"
          disabled={!selectByString}
          onClick={() => {
            selectByStringProperty();
          }}
        >
          {' '}
          Select Items!
        </Button>
      </div>
    </div>
  );
};

export default SelectByFieldPopover;
