import React, { useEffect, useState } from 'react';
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import style from './ApplyPage.style';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import ApplicationForm from './ApplicationForm/ApplicationForm';
import { postDataAgnostic } from 'Services/dataApi';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { formatBearerToken } from 'Common.functions';
import RezRateImg from '../../../../../../assets/images/header/rezRate.gif';
import { useAlert } from 'react-alert';

const ApplyPage = (props) => {
  const { applicantDetails } = props;
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const [hasValidCode, setHasValidCode] = useState(null);
  const [codeType, setCodeType] = useState(null); // 'department' or 'opening'

  const [applicationDetails, setApplicationDetails] = useState(null);
  const [selectedOpening, setSelectedOpening] = useState(null);

  const query = new URLSearchParams(useLocation().search);

  const { isAuthenticated, login, getToken } = useKindeAuth();

  const history = useHistory();

  const { location } = history;
  const { pathname } = location;
  const codeFromURL = query.get('applicationCode');

  const alert = useAlert();

  useEffect(() => {
    // Add your side effects here
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (codeFromURL) {
        setCode((prevData) => {
          verifyCode(codeFromURL);
          return codeFromURL;
        });
        // setHasValidCode(true);
      } else {
        setHasValidCode(false);
        setCode('');
      }
    }
  }, [codeFromURL, isAuthenticated]);

  const verifyCode = async (forcedCode) => {
    setIsValidatingCode(true);
    const token = await getToken();

    //  For testing, creating app code for Matt's dept.
    postDataAgnostic('applicant/code/verify', null, { code: forcedCode || code }, formatBearerToken(token))
      .then((result) => {
        if (result.data && result.data.message && result.data.message === 'Invalid Code') {
          alert.error('Invalid Code');
        } else {
          setIsValidatingCode(false);
          setHasValidCode(true);
          setApplicationDetails(result.data);
          // history.push(`/applicant/applicant_profile/new_application?applicationCode=${code}`);
        }
      })
      .catch((err) => {
        setIsValidatingCode(false);
        alert.error('Invalid Code');
        console.log('err: ', err);
      });
  };

  const renderCodeField = () => {
    return (
      <div style={{ ...style.simpleRow, width: '100%', marginTop: '10vh' }}>
        <div style={{ ...style.simpleColumn, width: '100%', maxWidth: 550 }}>
          <div style={{ ...style.simpleRow, width: '100%', marginBottom: 10, fontWeight: 'bold' }}>
            Enter Application Code
          </div>
          <div style={{ ...style.simpleRow, width: '100%', marginBottom: 0 }}>
            <InputGroup>
              <Input
                type="text"
                value={code}
                disabled={isLoading || isValidatingCode}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
              <InputGroupAddon addonType="append">
                <Button
                  color="success"
                  disabled={isLoading || !code || isValidatingCode}
                  onClick={() => {
                    setIsValidatingCode(true);
                    setTimeout(() => {
                      verifyCode();
                    }, 2000);
                  }}
                >
                  Apply
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>
          <div style={{ ...style.simpleRow, width: '100%', marginBottom: 10, fontSize: 10 }}>
            {isValidatingCode ? 'Validating code...' : isLoading ? 'Loading...' : ''}
          </div>
        </div>
      </div>
    );
  };

  const renderApplicationForm = () => {
    return (
      <ApplicationForm
        applicationDetails={applicationDetails}
        selectedOpening={selectedOpening}
        applicantDetails={applicantDetails}
      />
    );
  };

  const renderOpeningSelection = () => {
    const { Department, ApplicationOpenings } = applicationDetails;
    const { DepartmentLogo, DepartmentName } = Department;
    const hasOpenings = ApplicationOpenings && ApplicationOpenings.length > 0;

    return (
      <div style={{ ...style.simpleRow, width: '80%', minWidth: 700 }}>
        <div style={{ ...style.simpleColumn, width: 'calc(50px + (100% - 320px))', minWidth: 700 }}>
          <div style={{ ...style.simpleRow, width: '100%', marginBottom: 20 }}>
            <div style={{ ...style.simpleColumn, width: 80 }}>
              <img src={DepartmentLogo || RezRateImg} alt="Department Logo" style={{ width: 70, height: 70 }} />
            </div>
            <div style={{ ...style.simpleColumn, width: 'calc(100% - 80px)' }}>
              <div style={{ ...style.simpleRow, width: '100%', justifyContent: 'spaced-between' }}>
                <div style={{ ...style.simpleColumn, width: 'calc(100% - 80px', alignItems: 'flex-start' }}>
                  <b>{DepartmentName}</b>
                </div>
                <div
                  style={{
                    ...style.simpleColumn,
                    width: 'calc(100% - 80px',
                    fontSize: 20,
                    color: hasOpenings ? 'green' : 'red',
                  }}
                >
                  <b>{hasOpenings ? 'Openings Available' : 'No Openings'}</b>
                </div>
              </div>
              {/* <div style={{ ...style.simpleRow, width: '100%', justifyContent: 'left' }}>Openings Available</div> */}
            </div>
          </div>
          <div
            style={{
              ...style.simpleRow,
              width: '100%',
              height: 'calc(100vh - 320px)',
              overflowY: 'auto',
              alignItems: 'flex-start',
            }}
          >
            <div style={{ ...style.simpleColumn }}>
              {applicationDetails.ApplicationOpenings.map((opening) => {
                return (
                  <div
                    style={{
                      ...style.simpleRow,
                      width: '100%',
                      border: '1px solid gray',
                      padding: 10,
                      marginBottom: 2,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setSelectedOpening(opening);
                    }}
                  >
                    <div style={{ ...style.simpleColumn, width: '100%' }}>{opening.Title}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (hasValidCode && applicationDetails) {
      return renderApplicationForm();
      if (selectedOpening) {
        return renderApplicationForm();
      } else if (applicationDetails) {
        return renderOpeningSelection();
      }
    } else {
      return renderCodeField();
    }
  };

  return (
    <div
      style={{
        ...style.simpleRow,
        width: '100%',
        padding: '10px 10px',
        height: 'calc(100vh - 180px)',
        alignItems: 'flex-start',
      }}
    >
      <div style={style.simpleColumn}>{renderContent()}</div>
    </div>
  );
};

export default ApplyPage;
