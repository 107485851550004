import {
  faCheckSquare,
  faChevronDown,
  faEllipsisH,
  faRedoAlt,
  faSquare,
  faTimesCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMove from 'array-move';
import React, { useState, useEffect, useContext } from 'react';
import { useAlert } from 'react-alert';
import { Popover, PopoverHeader, PopoverBody, Button, UncontrolledTooltip, Input } from 'reactstrap';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken, calculatePercentage, calculateWeight } from '../../../../../Common.functions';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { deleteData } from '../../../../../Services/dataApi';
import FocusPalette from '../../../Coordinator/Schedule/FocusPalette';
import './EvaluationQuestion.style.css';
import style from './EvaluationQuestions.style';
import { clone } from 'lodash';
const array = [1, 2, 3, 4];

const EvaluationQuestion = ({
  changeValue,
  changeSortOrder,
  DragHandle,
  getQuestions,
  groupIndex,
  isUpdating,
  labelClick,
  numOfQuestionsInWeight,
  pk_QuestionGroup,
  question,
  questionIndex,
  questions,
  questionWeights,
  season,
  selectedQuestion,
  setSelectedQuestionId,
  toggleDeleteQuestion,
  deleteNewQuestion,
}) => {
  const [sortValue, setSortValue] = useState(1);
  const [evalPercentage, setEvalPercentage] = useState('-');
  const [prePercentage, setPrePercentage] = useState('-');
  const [evalChecked, setEvalChecked] = useState(false);
  const [preChecked, setPreChecked] = useState(false);
  const [selectedFocuses, setSelectedFocuses] = useState([]);
  const [isRequired, setIsRequired] = useState(false);

  const {
    QuestionText,
    WeightQuestionType,
    uuid,
    EnablePrescreenForm,
    EnableEvaluationForm,
    EnableRequired,
    EvaluationQuestionSortOrder,
    isToDelete,
    pk_EvaluationQuestionWeight,
    pk_EvaluationQuestion,
  } = question;

  const alert = useAlert();

  useEffect(() => {
    // TODO: REMOVE AFTE JEFF UPDATED GET for Questions. BASE ON QUESTION ITSELF, NOT ON Weight
    if (pk_EvaluationQuestionWeight && questionWeights) {
      const weightUsed = questionWeights.find(
        (item) => item.pk_EvaluationQuestionWeight === pk_EvaluationQuestionWeight,
      );

      if (weightUsed) {
        setIsRequired(weightUsed.EnableRequired);
      }
    }
  }, [pk_EvaluationQuestionWeight, questionWeights]);

  const [showFocusPopover, setShowFocusPopover] = useState(false);
  let typeTimeout = null;
  let lastTypeField = null;

  const AllowPrescreen = season && season.data ? season.data.AllowPrescreen : false;
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);

  useEffect(() => {
    if (question && numOfQuestionsInWeight && questionWeights) {
      const prescreenResult = calculateWeight(
        'prescreen',
        question,
        numOfQuestionsInWeight,
        questionWeights,
        question.pk_EvaluationQuestionWeight,
        2,
      );
      const evaluationResult = calculateWeight(
        'evaluation',
        question,
        numOfQuestionsInWeight,
        questionWeights,
        question.pk_EvaluationQuestionWeight,
        2,
      );
      setEvalPercentage(evaluationResult);
      setPrePercentage(prescreenResult);
    }
  }, [JSON.stringify(question), numOfQuestionsInWeight, questionWeights]);

  useEffect(() => {
    if (question) {
      // Remove Redundant=====//
      const newFocuses = [];

      if (question.Focuses) {
        question.Focuses.forEach((f) => {
          if (
            !newFocuses.find((nF) => {
              return nF.pk_Focus === f.pk_Focus;
            })
          ) {
            newFocuses.push(f);
          }
        });
      }
      //==================//
      setSelectedFocuses(newFocuses);
    }
  }, [question]);

  const onWeightChange = (e) => {
    const { value } = e.target;
    changeValue(groupIndex, questionIndex, 'category', value);
  };

  const renderFocusPopover = () => {
    return (
      <Popover
        flip={false}
        popperClassName="evaluation_focus"
        isOpen={showFocusPopover} // Change with room id later
        target={`button_${pk_EvaluationQuestion}_focusButton`} // Change with room id later
        trigger="legacy"
        toggle={(e) => {
          setShowFocusPopover(!showFocusPopover);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            Focus Categories
            <div>
              <div style={style.simpleRow}>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={style.clickableIcon}
                  onClick={(e) => {
                    setShowFocusPopover(false);
                  }}
                />
              </div>
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <FocusPalette
            itemParentObject={question}
            allowEdit={false}
            showIgnoreSwitch={false}
            reference={selectedFocuses}
            updateSelectedFocuses={(newFocuses) => {
              setSelectedFocuses(newFocuses);
              changeValue(groupIndex, questionIndex, 'Focuses', newFocuses, pk_QuestionGroup);
            }}
            returnEntireFocusObject={true}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const getFocusNames = () => {
    let focusNames = '';

    if (selectedFocuses.length > 0) {
      selectedFocuses.forEach((focus, i) => {
        if (i > 0) {
          focusNames += `, ${focus.Name || '(No Name)'}`;
        } else {
          focusNames += focus.Name || '(No Name)';
        }
      });
    } else {
      focusNames = 'No Limit';
    }

    return focusNames;
  };

  const deleteQuestion = () => {
    const { pk_EvaluationQuestion } = question;
    if (pk_EvaluationQuestion) {
      toggleDeleteQuestion(groupIndex, questionIndex);
    } else {
      deleteNewQuestion(groupIndex, questionIndex);
    }
  };

  let backgroundColor = 'inherit';

  if ((!AllowPrescreen && !EnableEvaluationForm) || (AllowPrescreen && !EnableEvaluationForm && !EnablePrescreenForm)) {
    backgroundColor = '#babab8';
  } else if (selectedQuestion) {
    backgroundColor = '#c1e1ec';
  }

  if (isToDelete) {
    backgroundColor = '#FF6666';
  }

  if (!pk_EvaluationQuestion) {
    backgroundColor = '#99FF99';
  }

  return (
    <tr
      id={`id_${pk_EvaluationQuestion || uuid}`}
      key={`id_${pk_EvaluationQuestion || uuid}`}
      name={`id_${pk_EvaluationQuestion || uuid}`}
      style={{ backgroundColor: backgroundColor }}
      onClick={() => {
        setSelectedQuestionId(pk_EvaluationQuestion || uuid);
      }}
    >
      <td>
        <Button
          disabled={isUpdating}
          color={(pk_EvaluationQuestion && !isToDelete) || !pk_EvaluationQuestion ? 'danger' : 'secondary'}
          onClick={() => {
            if (!pk_EvaluationQuestion) {
              Swal.fire({
                title: 'Warning!',
                text: `Are you sure you want to delete this question? This cannot be undone.`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'red',
                confirmButtonText: `Delete`,
              }).then((result) => {
                if (result.value) {
                  deleteQuestion();
                }
              });
            } else {
              deleteQuestion();
            }
          }}
        >
          <FontAwesomeIcon icon={pk_EvaluationQuestion && isToDelete ? faRedoAlt : faTrash} />
        </Button>
      </td>
      <td>
        {isToDelete ? (
          <div style={{ fontWeight: 'bold', color: 'white' }}>DELETE</div>
        ) : (
          <Input
            disabled={isUpdating}
            type="select"
            id={`sort_${pk_EvaluationQuestion}`}
            key={`sort_${pk_EvaluationQuestion}`}
            name={`sort_${pk_EvaluationQuestion}`}
            value={EvaluationQuestionSortOrder}
            onChange={(e) => {
              changeSortOrder(groupIndex, questionIndex, parseInt(e.target.value));
              // changeValue(groupIndex, questionIndex, 'EvaluationQuestionSortOrder', e.target.value, pk_QuestionGroup);
            }}
          >
            {questions && questions[groupIndex].questions ? (
              questions[groupIndex].questions.map((item, count) => (
                <option key={item.pk_EvaluationQuestion} value={count + 1}>
                  {count + 1}
                </option>
              ))
            ) : (
              <option value={null} disabled selected>
                No Sort Order
              </option>
            )}
          </Input>
        )}
      </td>
      <td>
        <div>{isRequired || EnableRequired ? <h6 style={{ color: '#FF0000', fontWeight: 700 }}>*</h6> : null}</div>
      </td>
      <td>
        <div style={style.simpleRow}>
          <div style={{ ...style.simpleColumn, width: 20, fontWeight: 'bold', color: 'red' }}>
            {EnableRequired ? '*' : <br />}
          </div>
          <div style={style.simpleColumn}>
            <Input
              disabled={isUpdating || isToDelete}
              type="textarea"
              style={{ width: '100%' }}
              rows="2"
              id={`name_${pk_EvaluationQuestion}_${JSON.stringify(question)}`}
              key={`name_${pk_EvaluationQuestion}`}
              defaultValue={`${QuestionText}`}
              onChange={(e) => {
                const newValue = e.target.value;
                if (typeTimeout) {
                  if (lastTypeField !== 'QuestionText') {
                    lastTypeField = 'QuestionText';
                    if (typeTimeout.trigger) {
                      typeTimeout.trigger();
                    }
                  }
                  clearTimeout(typeTimeout);
                }
                typeTimeout = setTimeout(() => {
                  changeValue(groupIndex, questionIndex, 'QuestionText', newValue, pk_QuestionGroup);
                  lastTypeField = null;
                }, 300);

                // changeValue(groupIndex, questionIndex, 'QuestionText', e.target.value, pk_QuestionGroup);
              }}
            />
          </div>
        </div>
      </td>
      <td>
        {WeightQuestionType === 'scale' /*&& Math.random() * 100 > 50*/ ? (
          <Button
            disabled={isUpdating || isToDelete}
            color={question.EnableCustomScale ? 'success' : 'warning'}
            onClick={(e) => {
              e.preventDefault();
              labelClick({ ...question, questionIndex, groupIndex });
            }}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </Button>
        ) : null}
      </td>
      <td>
        <Input
          disabled={isUpdating || isToDelete}
          type="select"
          name="weightCategorySelect"
          style={{ width: '100%' }}
          onChange={onWeightChange}
          value={pk_EvaluationQuestionWeight}
        >
          {questionWeights.map((item) => {
            return (
              <option
                selected={item.pk_EvaluationQuestionWeight === pk_EvaluationQuestionWeight}
                value={item.pk_EvaluationQuestionWeight}
              >
                {item.CategoryName}
              </option>
            );
          })}
        </Input>
      </td>
      <td>
        <Button
          disabled={isUpdating || isToDelete}
          type="button"
          id={`button_${pk_EvaluationQuestion}_focusButton`}
          color="secondary"
          size="sm"
          style={{ width: 220 }}
          onClick={() => {}}
        >
          <div style={style.spacedBetweenRow}>
            <div style={style.ellipsis}> {getFocusNames()}</div>
            <div style={{ marginRight: 10 }}>({selectedFocuses.length})</div>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </Button>
        <UncontrolledTooltip target={`button_${pk_EvaluationQuestion}_focusButton`}>
          {getFocusNames()}
        </UncontrolledTooltip>
        {renderFocusPopover()}
      </td>

      <td>
        {AllowPrescreen ? (
          <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'space-between' }}>
            <FontAwesomeIcon
              disabled={isUpdating || isToDelete}
              icon={EnablePrescreenForm ? faCheckSquare : faSquare}
              style={{ marginRight: 10, cursor: 'pointer' }}
              onClick={() => {
                changeValue(groupIndex, questionIndex, 'prescreen');
              }}
            />
            Prescreen
          </div>
        ) : null}
        <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'space-between' }}>
          <FontAwesomeIcon
            disabled={isUpdating || isToDelete}
            icon={EnableEvaluationForm ? faCheckSquare : faSquare}
            style={{ marginRight: 10, cursor: 'pointer' }}
            onClick={() => {
              changeValue(groupIndex, questionIndex, 'evaluation');
            }}
          />
          Evaluation
        </div>
      </td>

      <td>
        {WeightQuestionType === 'scale' ? (
          <>
            {AllowPrescreen ? (
              <div style={{ ...style.simpleRow }}>
                <div style={style.simpleColumn}>{prePercentage}</div>
              </div>
            ) : null}

            <div style={style.simpleRow}>
              <div style={style.simpleColumn}>{evalPercentage}</div>
            </div>
          </>
        ) : (
          <>
            {AllowPrescreen ? (
              <div style={style.simpleRow}>
                <div style={style.simpleColumn}>-</div>
              </div>
            ) : null}

            <div style={style.simpleRow}>
              <div style={style.simpleColumn}>-</div>
            </div>
          </>
        )}
      </td>
    </tr>
  );
};

export default EvaluationQuestion;

// no templates for questions except for the default!

// no value - 1 through however many likert or dreyfus questions

// yes no toggle in elipses

// custom or default button

// yes/no, custom, default
