import React, { useEffect, useState } from 'react';
import style from './VerticalStepper.style';

const VerticalStepper = (props) => {
  const { sections, currentSection, setCurrentSection } = props;
  const [mySections, setMySections] = useState(sections || []);
  const [myCurrentSection, setMyCurrentSection] = useState(currentSection || {});

  useEffect(() => {
    setMySections(sections);
  }, [sections]);

  useEffect(() => {
    setMyCurrentSection(currentSection);
  }, [currentSection]);

  //   useEffect(() => {
  //     setCurrentSection(myCurrentSection);
  //   }, [myCurrentSection]);

  return (
    <div style={{ ...style.simpleColumn, width: 300 }}>
      {mySections.map((section, i) => {
        const { label, icon } = section;
        const isCurrentSection = myCurrentSection ? myCurrentSection.label === label : false;
        return (
          <>
            {i > 0 ? (
              <div
                style={{
                  ...style.simpleRow,
                  marginLeft: 44,
                  borderLeft: '3px solid #007bff',
                  height: 15,
                  width: '100%',
                }}
              ></div>
            ) : (
              ''
            )}
            <div
              style={{ ...style.simpleRow, cursor: 'pointer', width: 300 }}
              onClick={() => {
                setCurrentSection(section);
                // setMyCurrentSection(section);
              }}
            >
              <div
                style={{
                  ...style.simpleColumn,
                  width: isCurrentSection ? 58 : 32,
                  height: isCurrentSection ? 48 : 28,
                  borderRadius: 50,
                  border: isCurrentSection ? '3px solid #007bff' : '2px solid gray',
                  marginTop: 2,
                  marginBottom: 2,
                  marginLeft: isCurrentSection ? 0 : 10,
                }}
              >
                {icon ? icon : <div style={{ fontSize: isCurrentSection ? 28 : 18 }}>{i + 1}</div>}
                {/* <FontAwesomeIcon
                  icon={statusIcons[status]}
                  style={{ color: statusColors[status], fontSize: isCurrentSection ? 39 : 24 }}
                /> */}
              </div>
              <div
                style={{
                  ...style.simpleColumn,
                  marginLeft: 10,
                  alignItems: 'flex-start',
                  fontSize: isCurrentSection ? 18 : 15,
                  fontWeight: isCurrentSection ? 'bold' : null,
                  color: isCurrentSection ? '#007bff' : 'gray',
                }}
              >
                {label}
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default VerticalStepper;
