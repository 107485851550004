import RezRateTable from 'Body/RezRATE/Common/RezRateTable/RezRateTable';
import React, { useState, useEffect } from 'react';
import style from './ApplicationsPage.style';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { fetchDataAgnostic } from 'Services/dataApi';
import { formatBearerToken } from 'Common.functions';
import moment from 'moment-timezone';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const ApplicationsPage = (props) => {
  const { applicantDetails } = props;

  // State variables
  const [applications, setApplications] = useState([]);

  const [showApplicationDetailModal, setShowApplicationDetailModal] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const kindeAuth = useKindeAuth();
  const { isAuthenticated, login, getToken } = kindeAuth || {};

  useEffect(() => {
    // setIsLoading(true);
    // setTimeout(() => {
    //   setIsLoading(false);
    //   setApplications([
    //     {
    //       pk_Application: 1,
    //       organizationName: 'Earth Alliance',
    //       programName: 'Omni Enforcer',
    //       submitted: 'June 14, 2024',
    //       status: 'Not Submitted',
    //     },
    //     {
    //       pk_Application: 2,
    //       organizationName: 'Zodiac Alliance of Freedom Treaty',
    //       programName: 'Minerva Corps',
    //       submitted: 'June 14, 2024',
    //       status: 'In Review',
    //     },
    //     {
    //       pk_Application: 3,
    //       organizationName: 'Earth Alliance',
    //       programName: 'Phantom Pain',
    //       submitted: 'June 14, 2024',
    //       status: 'Declined',
    //     },
    //     {
    //       pk_Application: 4,
    //       organizationName: 'Zodiac Alliance of Freedom Treaty',
    //       programName: 'FAITH',
    //       submitted: 'June 14, 2024',
    //       status: 'Pending',
    //     },
    //     {
    //       pk_Application: 5,
    //       organizationName: 'Earth Alliance',
    //       programName: 'Logos',
    //       submitted: 'June 14, 2024',
    //       status: 'Pending',
    //     },
    //     {
    //       pk_Application: 6,
    //       organizationName: 'Zodiac Alliance of Freedom Treaty',
    //       programName: 'Le Creuset Team',
    //       submitted: 'June 14, 2024',
    //       status: 'Accepted',
    //     },
    //   ]);
    // }, 2000);
  }, []);

  const headers = [
    {
      dataKey: 'organizationName',
      label: 'Organization',
      width: 250,
    },
    { dataKey: 'programName', label: 'Program', width: 250 },
    { dataKey: 'submitted', label: 'Submitted', width: 250 },
    { dataKey: 'status', label: 'Status', width: 250 },
  ];

  // Effects
  useEffect(() => {
    if (isAuthenticated && kindeAuth && applicantDetails && applicantDetails.pk_Applicant) {
      getApplications();
    }
  }, [isAuthenticated, applicantDetails]);

  const getApplications = async () => {
    const { pk_Applicant } = applicantDetails || {};
    setIsLoading(true);
    const token = await getToken();
    fetchDataAgnostic('applicant/application/list', { pk_Applicant }, formatBearerToken(token))
      .then((res) => {
        setIsLoading(false);
        console.log('res.data: ', res.data);
        const newData = res.data.map((item) => {
          const {
            Department,
            IsRejected,
            IsRegistered,
            IsUnderReview,
            IsWithdrawn,
            IsSubmitted,
            TimestampOfApplicationSubmission,
          } = item;
          const { DepartmentName } = Department;

          let status = 'Not Submitted';

          if (IsSubmitted) {
            status = 'Submitted';
          } else if (IsRejected) {
            status = 'Rejected';
          } else if (IsRegistered) {
            status = 'Registered';
          } else if (IsUnderReview) {
            status = 'In Review';
          }

          return {
            programName: DepartmentName || '',
            ...item,
            status,
            submitted: moment.tz(TimestampOfApplicationSubmission, null).format('MMMM DD, YYYY'),
          };
        });
        console.log('newData: ', newData);

        setApplications(newData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log('err: ', err);
      });
  };

  const renderApplicationDetailModal = () => {
    const { programName = '', submitted = '', status = '' } = selectedApplication || {};
    return (
      <Modal isOpen={showApplicationDetailModal} centered style={{ width: '90vw' }}>
        <ModalHeader
          toggle={() => {
            setShowApplicationDetailModal(false);
            setSelectedApplication(null);
          }}
        >
          Application for {programName ? programName : 'Unnamed Program'}
        </ModalHeader>
        <ModalBody>
          <div style={style.simpleRow}>
            <div style={style.simpleColumn}>
              <div style={style.simpleRow}>Program: {programName}</div>
              <div style={style.simpleRow}>Submitted: {submitted}</div>
              <div style={style.simpleRow}>Status: {status}</div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div style={{ ...style.simpleRow, marginTop: 50, justifyContent: 'center', width: 'calc(100vw - 30px)' }}>
      <div
        style={{
          ...style.simpleColumn,
          overflow: 'auto',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',

          width: 'auto',
        }}
      >
        <RezRateTable
          columns={headers}
          data={applications}
          onRowClick={(row) => {
            console.log('row: ', row);
            setSelectedApplication(row);
            setShowApplicationDetailModal(true);
          }}
          noDataMessage={isLoading ? <div>Loading...</div> : <div>No Data</div>}
        />
      </div>
      {renderApplicationDetailModal()}
    </div>
  );
};

export default ApplicationsPage;
