import {
  faChartBar,
  faCheckSquare,
  faComments,
  faEdit,
  faFileInvoice,
  faUsers,
  faUsersCog,
  faCog,
  faBars,
  faUserTie,
  faTimesCircle,
  faSignOutAlt,
  faCalendar,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState, useContext } from 'react';
import NavigationButton from './NavigationButton';
import { DepartmentContext } from '../../../DepartmentWrapper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverHeader, PopoverBody, Button } from 'reactstrap';
import { useAuth0 } from '../../../auth0/reactAuth0Spa';

import './Navigation.style.css';
import Swal from 'sweetalert2';
import { fetchDataAuth0, postDataAgnosticWithoutCredentials } from '../../../Services/dataApi';
import { formatBearerToken } from '../../../Common.functions';
import { useHistory } from 'react-router-dom';

// this link is used further down the tree, so to keep the names in sync define it in a variable and use the variable
// for both sources
const evaluateLink = '/administrator/evaluate';

// navigation buttons for signed in users
const Navigation = ({ match, departments }) => {
  const [selected, setSelected] = useState(null);
  const [showSideBar, setShowSideBar] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [displayNavigation, setDisplayNavigation] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);
  // const [departments, setDepartments] = useState([]);

  const { getTokenSilently, loginWithRedirect, logout } = useAuth0();
  const dContext = useContext(DepartmentContext);
  const { department } = dContext;
  const { ProgramType = '', ApplicationSystem = '' } = department || {};

  const minWidthForNav = 1000;
  const wideWidth = 1200;
  const mediumScreen = 1000;
  const narrowScreen = 850;

  const history = useHistory();

  useEffect(() => {
    const { pathname } = window.location;

    if (pathname.includes('/user/evaluate') || pathname === '/') {
      setDisplayNavigation(false);
    } else {
      setDisplayNavigation(true);
    }

    // switch (true) {
    //   case pathname.includes('administrator/applicants'):
    //     setSelected(1);
    //     break;
    //   case pathname.includes('administrator/evaluate'):
    //     setSelected(2);
    //     break;
    //   case pathname.includes('administrator/team'):
    //     setSelected(3);
    //     break;
    //   case pathname.includes('administrator/candidates'):
    //     setSelected(4);
    //     break;
    //   case pathname.includes('administrator/communications'):
    //     setSelected(5);
    //     break;
    //   case pathname.includes('administrator/evaluation_template'):
    //     setSelected(6);
    //     break;
    //   case pathname.includes('administrator/evaluation_status'):
    //     setSelected(7);
    //     break;
    //   case pathname.includes('administrator/rank'):
    //     setSelected(8);
    //     break;
    //   case pathname.includes('administrator/settings'):
    //     setSelected(9);
    //     break;
    //   case pathname.includes('administrator'):
    //     setSelected(0);
    //     break;
    //   default:
    //     return;
    // }
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }, [window.location.pathname]);

  window.addEventListener('resize', () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  });

  const clearCurrentDepartment = () => {
    getTokenSilently()
      .then((token) => {
        postDataAgnosticWithoutCredentials(
          'user/setDefaultDepartment',
          null,
          { departmentId: null },
          formatBearerToken(token),
        )
          .then((result) => {
            history.push('/');
            // window.location.reload();
            dContext.setDepartment(null);
            sessionStorage.removeItem('department');
            sessionStorage.removeItem('roleChoice');
            // dContext.setSeason(null);
          })
          .catch((err) => {
            console.log('err??', err);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const mapButtons = () => {
    const { pathname } = window.location;

    if (windowWidth > 500) {
      return (
        <div style={{ flexDirection: 'column', display: 'flex', width: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              // width: '100vw',
              // overflowX: 'scroll',
            }}
          >
            {buttons.map((item, i) => {
              const { name, name2, name3, linkTo, icon } = item;
              const last = buttons.length - 1 === i;
              let minWidth = null;
              let nameToUse = name;

              if (ApplicationSystem !== 'direct_application' && name === 'Applicants') {
                return;
              }

              if (windowWidth > wideWidth) {
                nameToUse = name;
              } else if (windowWidth > mediumScreen) {
                nameToUse = name2 || name;
              } else if (windowWidth > narrowScreen) {
                nameToUse = name3 || name2 || name;
              } else {
                nameToUse = '';
              }

              if (name === 'Rank' && ProgramType === 'Mock Oral Board') {
                return;
              }
              return (
                <NavigationButton
                  key={name}
                  fullName={name}
                  name={nameToUse}
                  i={i}
                  evaluateLink={evaluateLink}
                  linkTo={linkTo}
                  icon={icon}
                  last={last}
                  selected={pathname === linkTo}
                  setSelected={setSelected}
                  minWidth={minWidth}
                />
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div style={{ flexDirection: 'column', display: 'flex', width: '100%' }}>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
              }}
            >
              <Button
                outline
                id={'open_nav_pop'}
                onClick={() => {
                  setShowSideBar(!showSideBar);
                }}
                style={{ color: 'white', borderColor: 'white' }}
              >
                <FontAwesomeIcon icon={faBars} />
              </Button>
              <Popover
                animation={false}
                target="open_nav_pop"
                trigger="legacy"
                isOpen={showSideBar}
                toggle={() => {
                  setShowSideBar(!setShowSideBar);
                }}
                style={{ zIndex: 9000, width: 250 }}
                flip={false}
                modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
              >
                <PopoverHeader style={{ backgroundColor: 'rgb(51, 51, 51)' }}>
                  {' '}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      color: 'white',
                      alignItems: 'center',
                    }}
                  >
                    Navigation
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowSideBar(false);
                      }}
                    />
                  </div>
                </PopoverHeader>
                <PopoverBody style={{ backgroundColor: 'rgb(51, 51, 51)' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      {buttons.map((item, i) => {
                        const { name, linkTo, icon } = item;
                        const last = buttons.length - 1 === i;

                        if (name === 'Rank' && ProgramType === 'Mock Oral Board') {
                          return;
                        }

                        return (
                          <NavigationButton
                            key={name}
                            name={name}
                            i={i}
                            linkTo={linkTo}
                            icon={icon}
                            evaluateLink={evaluateLink}
                            last={last}
                            selected={pathname === linkTo}
                            setSelected={setSelected}
                            setShowSideBar={setShowSideBar}
                          />
                        );
                      })}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          padding: '2px 10px',
                          color: 'white',
                          width: '100%',
                          backgroundColor: 'rgb(51, 51, 51)',
                          cursor: 'pointer',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                        onClick={() => {
                          const { EnableAdmin = false, EnableEvaluator = false, EnableSuperUser = false } =
                            dContext.department || {};

                          const hasMultipleRoles = (EnableSuperUser || EnableAdmin) && EnableEvaluator;
                          if (
                            departments &&
                            (departments.length > 1 || (departments.length == 1 && hasMultipleRoles))
                          ) {
                            Swal.fire({
                              text: `Multiple ${
                                departments.length > 1 ? 'departments' : 'roles'
                              } found. Do you want to switch ${
                                departments.length > 1 ? 'departments' : 'roles'
                              } or logout?`,
                              icon: 'warning',
                              showCancelButton: true,
                              showDenyButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: 'grey',
                              confirmButtonText: 'Switch',
                              denyButtonText: 'Logout',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                clearCurrentDepartment();
                              } else if (result.isDenied) {
                                sessionStorage.removeItem('roleChoice');
                                logout();
                              } else {
                              }
                            });
                          } else {
                            Swal.fire({
                              text: 'Are you sure you want to logout?',
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes',
                            }).then((result) => {
                              if (result.value) {
                                sessionStorage.removeItem('roleChoice');
                                logout();
                                setShowSideBar(false);
                              } else {
                              }
                            });
                          }
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 700,
                            marginBottom: 0,
                            fontSize: '1em',
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          <div
                            style={{
                              flexDirection: 'row',
                              color: 'red',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <FontAwesomeIcon
                              style={{
                                paddingRight: '5px',
                                fontSize: 25,
                              }}
                              icon={faSignOutAlt}
                              size="lg"
                            />
                            Logout
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          </div>
        </>
      );
    }
  };

  if (!displayNavigation) {
    return <div></div>;
  }

  return <div style={{ display: 'flex', paddingRight: 10, paddingLeft: 10, marginBottom: 10 }}>{mapButtons()}</div>;
};

const buttons = [
  { name: 'Applicants', name2: 'Appl...', linkTo: '/administrator/applicants', icon: faUserTie },
  { name: 'Sessions', name2: 'Sessions', linkTo: '/administrator', icon: faCalendarAlt },
  { name: 'Evaluate', name3: 'Eval', linkTo: evaluateLink, icon: faEdit },
  { name: 'Team', name2: 'Team', linkTo: '/administrator/team', icon: faUsersCog },
  { name: 'Candidates', name2: 'Candidates', linkTo: '/administrator/candidates', icon: faUsers },
  { name: 'Communications', name3: 'Comms', linkTo: '/administrator/communications', icon: faComments },
  {
    name: 'Evaluation Template',
    name2: 'Eval Template',
    name3: 'Templates',
    linkTo: '/administrator/evaluation_template',
    icon: faFileInvoice,
  },
  { name: 'Evaluation Status', name2: 'Status', linkTo: '/administrator/evaluation_status', icon: faCheckSquare },
  { name: 'Rank', name2: 'Rank', linkTo: '/administrator/rank', icon: faChartBar },
  { name: 'Settings', name2: 'Settings', name3: '', linkTo: '/administrator/settings', icon: faCog },
];

export default Navigation;

// toggle between local and school time
