import { faBellSlash, faHandPaper, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCalendarAlt, faDownload, faExclamationCircle, faSortAlphaDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, ButtonGroup, Tooltip, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';

import style from './style.js';
import { CandidateStatusIcons } from 'enums.js';

const FilterPopover = ({ setShowFilterPopover, setFilterBy, filterBy, interviewDates }) => {
  const {
    pendingInvite,
    invited,
    waitlisted,
    confirmed,
    unconfirmed,
    rejected,
    declined,
    scheduled,
    unscheduled,
    markedForInvite,
    unresponsive,
    notSentSchedule,
  } = CandidateStatusIcons;

  const buttons = [
    {
      iconData: {
        icon: faCalendarAlt,
        color: null,
      },
      label: 'Show All (Interview Date)',
      filterBy: 'All by Interview Date',
      tooltip:
        'Shows all candidates sorted by interview session and then alphabetically. Candidates with no interview session are listed last',
    },
    {
      iconData: {
        icon: faSortAlphaDown,
        color: null,
      },
      label: 'Show All (Alphabetical)',
      filterBy: 'All by Name',
      tooltip: 'Shows all candidates sorted alphabetically.',
    },
    {
      iconData: scheduled,
      label: 'Show Registered Candidates',
      filterBy: 'Scheduled',
      tooltip: 'Filter to candidates that are registered for an interview session (Sorted Alphabetically)',
    },
    {
      iconData: unscheduled,
      label: 'Show Unregistered Candidates',
      filterBy: 'Unscheduled',
      tooltip: 'Filter to candidates that are not yet registered for an interview session.',
      lineAfter: true,
    },
    // ======================== SPACE HERE IDLE ============================
    {
      iconData: markedForInvite,
      label: 'Show All (Marked for Invite)',
      filterBy: 'All Marked for Invite',
      tooltip: 'Filter to candidates marked for invitation regardless of if they’ve been sent an invitation or not.',
    },

    {
      iconData: pendingInvite,
      label: 'Show All (Pending Invitation)',
      filterBy: 'All Pending Invite',
      tooltip: 'Filter to candidates that are marked for invitation and have not yet received an invitation.',
    },
    {
      iconData: invited,
      label: 'Show All (Invitation Sent)',
      filterBy: 'All Invited',
      tooltip: 'Filter to candidates that have received an invitation.',
    },

    {
      iconData: unresponsive,
      label: 'Show Unresponsive Candidates',
      filterBy: 'Unresponsive',
      tooltip: 'Filter to candidates that have received an invitation but haven’t submitted any response.',
    },

    {
      iconData: waitlisted,
      label: 'Show Waitlisted',
      filterBy: 'Waitlisted',
      tooltip: 'Filter to candidates that are presently waitlisted.',
      lineAfter: true,
    },

    {
      iconData: notSentSchedule,
      label: 'Show All (Not Sent Schedule)',
      filterBy: 'All Not Sent Schedule',
      tooltip: 'Filter to registered candidates that have yet to receive their interview day schedule.',
    },

    {
      iconData: declined,
      label: 'Show Declined Candidates',
      filterBy: 'Declined',
      tooltip: 'Filter to candidates that have declined their invitation to interview.',
    },
    {
      iconData: rejected,
      label: 'Show All (Rejected)',
      filterBy: 'Show All (Rejected)',
      tooltip: 'Filter to candidates that have been marked as rejected by your program.',
    },
  ];

  return (
    <div>
      <div style={style.scheduleDateContainer}>
        {interviewDates && interviewDates.length > 0 ? (
          interviewDates.map((interviewDate, i) => {
            const dateToDisplay = moment(interviewDate.DateOfInterview).format('dddd, MMM. DD, YYYY');

            const backgroundColor =
              filterBy.pk_InterviewDate == interviewDate.pk_InterviewDate ? '#d9f5ff' : i % 2 == 0 ? null : '#c7cbd1';
            return (
              <div
                style={{ ...style.dateListItem, backgroundColor }}
                onClick={() => {
                  setFilterBy(interviewDate);
                }}
              >
                {`${dateToDisplay}   ${interviewDate.Label ? `(${interviewDate.Label})` : ''}`}
              </div>
            );
          })
        ) : (
          <div style={{ textAlign: 'center' }}>'No Schedule Dates'</div>
        )}
      </div>

      {buttons.map((button, i) => {
        const { customStyle = {}, lineAfter } = button;
        return (
          <div style={{ ...style.simpleRow, ...customStyle }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {' '}
              <Button
                id={`button_${i}`}
                color="primary"
                outline
                style={{
                  ...style.filterButton,
                  backgroundColor: filterBy == button.filterBy ? '#d9f5ff' : null,
                  color: null,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setFilterBy(button.filterBy);
                  setShowFilterPopover(false);
                }}
              >
                <div style={style.spacedBetweenRow}>
                  <div style={{ marginRight: 15 }}>
                    <FontAwesomeIcon icon={button.iconData.icon} color={button.iconData.color} />
                  </div>
                  <div style={style.buttonLabel}>{button.label}</div>
                </div>
              </Button>
              <UncontrolledTooltip
                modifiers={{
                  preventOverflow: { boundariesElement: 'viewport' },
                  flip: {
                    behavior: ['top', 'right', 'bottom', 'left'],
                    fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                  },
                }}
                placement="right"
                target={`button_${i}`}
              >
                {button.tooltip}
              </UncontrolledTooltip>
              {lineAfter ? (
                <div
                  style={{
                    height: 2,
                    width: '100%',
                    marginTop: 15,
                    marginBottom: 10,
                    borderTop: '1px lightgray solid',
                  }}
                >
                  {' '}
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FilterPopover;
