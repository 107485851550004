import { AAMCIDCSVData } from 'enums';
import { clone, colors } from './schedule';

import { PDFDocument, PDFName } from 'pdf-lib';
import imageToBase64 from 'image-to-base64';

export const assignColorToCandidates = (candidates) => {
  const newCandidates = clone(candidates);
  let colorKey = 0; // Math.floor(Math.random() * colors.length);
  Object.keys(newCandidates).forEach((key, i) => {
    if (colorKey >= colors.length) {
      colorKey = 0;
    }
    newCandidates[key].color = colors[colorKey];
    colorKey++;
  });

  return newCandidates;
};

export const separateDataTypesByAvailability = (dataTypes = {}) => {
  const enabled = [],
    disabled = [];

  Object.values(dataTypes).forEach((dataType) => {
    if (dataType.Enabled) {
      enabled.push(dataType);
    } else {
      disabled.push(dataType);
    }
  });

  enabled.sort((a, b) => a.SortOrder - b.SortOrder);
  disabled.sort((a, b) => {
    const textA = a.Alias ? a.Alias.toUpperCase() : '';
    const textB = b.Alias ? b.Alias.toUpperCase() : '';
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  return { enabled, disabled };
};

/***
 * arranges Sort orders.
 * @param {string} listOrigin - 'enabled'/'disabled'
 */
export const prepareDataTypesForSortUpdate = (dataTypes, dataTypeToUpdate) => {
  const newDataTypes = clone(dataTypes);
  const { enabled, disabled } = newDataTypes;
  const { listOrigin } = Object.values(dataTypeToUpdate)[0];

  let oldDataTypesArray = listOrigin == 'disabled' ? disabled.slice() : enabled.slice();
  let newDataTypesArray = listOrigin == 'enabled' ? disabled.slice() : enabled.slice();
  newDataTypesArray.sort((a, b) => a.SortOrder - b.SortOrder);

  const oldDataType = oldDataTypesArray.find((dataType) => {
    return dataType.Name == Object.keys(dataTypeToUpdate)[0];
  });
  if (oldDataType == null) {
    const temp = newDataTypesArray.find((dataType) => {
      return dataType.Name == Object.keys(dataTypeToUpdate)[0];
    });
    return;
  }

  const oldIndex = oldDataTypesArray.findIndex((dataType) => {
    return dataType.Name == oldDataType.Name;
  });
  if (listOrigin === 'disabled' || listOrigin === 'enabled') {
    oldDataTypesArray.splice(oldIndex, 1);
    // newDataTypesArray.push(oldDataType);
  }

  const newDataType = {
    ...oldDataType,
    ...dataTypeToUpdate[oldDataType.Name],
  };

  if (!listOrigin) {
    newDataTypesArray.splice(newDataType.SortOrder - 1, 0, newDataType);
    let duplicateIndex = newDataTypesArray.findIndex((item) => {
      return item.Name === newDataType.Name && item.SortOrder === oldDataType.SortOrder;
    });
    newDataTypesArray.splice(duplicateIndex, 1);
  } else {
    newDataTypesArray.splice(dataTypeToUpdate[oldDataType.Name].SortOrder - 1, 0, newDataType);
  }

  newDataTypesArray = newDataTypesArray.map((dataType, index) => {
    return { ...dataType, SortOrder: index + 1 };
  });

  const dataTypesObject = {};
  newDataTypesArray.forEach((dataType) => {
    if (dataType.pk_DepartmentDataType != null || dataType.Enabled) {
      dataTypesObject[dataType.Name] = {
        SortOrder: dataType.Enabled ? dataType.SortOrder : null,
        Enabled: dataType.Enabled,
        pk_DepartmentDataType: dataType.pk_DepartmentDataType,
      };
    }
  });

  if (listOrigin === 'enabled') {
    oldDataTypesArray.forEach((dataType, i) => {
      if (dataType.pk_DepartmentDataType != null) {
        dataType.SortOrder = i + 1;
      }
    });
    oldDataTypesArray.sort((a, b) => a.SortOrder - b.SortOrder);
    oldDataTypesArray.forEach((dataType) => {
      if (dataTypesObject[dataType.Name] == null) {
        if (dataType.pk_DepartmentDataType != null || dataType.Enabled) {
          dataTypesObject[dataType.Name] = {
            SortOrder: dataType.Enabled ? dataType.SortOrder : null,
            Enabled: dataType.Enabled || false,
            pk_DepartmentDataType: dataType.pk_DepartmentDataType,
          };
        }
      }
    });
  }

  return dataTypesObject;
};

export const pdf_concatenate = (options) => {
  const { string = '', maxLimit = 0, forcedCut = false } = options;
  let newString = string;

  if (newString.length > maxLimit) {
    let concat = newString.slice(0, maxLimit - 5);

    while (concat[concat.length - 1] === ' ') {
      concat = concat.slice(0, -1);
    }

    newString = `${concat}${forcedCut ? '' : '. . .'}`;
  }

  return newString;
};

// Give this a field/dataType. It will return aliases for that field, like for 'Email' it could have aliases 'E-mail' and 'Email'
export const getFieldAliases = (field) => {
  const aliasFields = [];

  Object.keys(AAMCIDCSVData).forEach((key) => {
    const item = AAMCIDCSVData[key];
    if (item.matchingObject && item.matchingObject.databaseColumn === field) {
      aliasFields.push(key);
    }
  });
  return aliasFields;
};

export const truncate = (wordToTruncate, limit = 0, forcedCut) => {
  if (wordToTruncate == null) {
    return '';
  }

  if (limit == 0 || wordToTruncate.length <= limit + 1) {
    return wordToTruncate;
  } else if (forcedCut) {
    return `${wordToTruncate.slice(0, limit)}`;
  } else if (wordToTruncate.length > limit + 1) {
    return `${wordToTruncate.slice(0, limit - 5)}. . .`;
  }
};

export const removeTrailingNewLines = (string) => {
  if (string == null) {
    return '';
  }

  let newString = string;

  while (newString[newString.length - 1] === '\n') {
    newString = newString.slice(0, -1);
  }

  return newString;
};

export const calculateFontSize = (options) => {
  const { text, maxWidth, maxHeight, startingFontSize = 12 } = options;
  // Start with a reasonable font size
  let fontSize = startingFontSize;

  // Measure the text size to determine if it fits within the specified dimensions
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  do {
    context.font = `${fontSize}px Arial`; // Use your preferred font
    const textWidth = context.measureText(text).width;
    const textHeight = fontSize;

    // Check if the text fits within the specified dimensions
    if (textWidth <= maxWidth && textHeight <= maxHeight) {
      break;
    }

    // Decrease the font size if the text doesn't fit
    fontSize--;
  } while (fontSize > 0);

  return fontSize;
};

export const isValidIDType = (id = '') => {
  return /\d/.test(id) && id.length < 15;
};

// Helper to resize image on a canvas
export const resizeImage = (options, callback) => {
  const { imageBlob, maxSize = { width: 1000, height: 1000 } } = options;

  const canvas = document.createElement('canvas');
  document.body.appendChild(canvas);
  let ctx = canvas.getContext('2d');

  // Create an image element to resize
  const img = new Image();
  const url = URL.createObjectURL(imageBlob);

  img.src = url;
  // img.style.display = 'none';
  img
    .decode()
    .then((res) => {
      URL.revokeObjectURL(url); // Clean up memory
      const imgWidth = img.width;
      const imgHeight = img.height;
      let width = img.width;
      let height = img.height;

      // Maintain aspect ratio
      const aspectRatio = width / height;

      if (width > maxSize.width || height > maxSize.height) {
        if (aspectRatio > 1) {
          width = maxSize.width;
          height = Math.round(maxSize.width / aspectRatio);
        } else {
          height = maxSize.height;
          width = Math.round(maxSize.height * aspectRatio);
        }
      }

      // Resize the canvas to the new dimensions
      canvas.width = width;
      canvas.height = height;
      canvas.style.display = 'none';

      // Draw the resized image onto the canvas
      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob(
        (blob) => {
          callback(blob);
        },
        'image/jpeg',
        0.9,
      );

      // Clean up: remove the canvas and clear references
      canvas.remove(); // If the canvas is appended to the DOM
      ctx = null; // Clear the context reference
    })
    .catch((error) => {
      callback(null);
      console.log('decode error: ', error);
    });
};

// Function to handle PDF files using pdf-lib
export const getImagesFromPDFFile = async (file, callback) => {
  const reader = new FileReader();

  reader.onload = async (event) => {
    const pdfDoc = await PDFDocument.load(event.target.result);
    const totalPages = pdfDoc.getPageCount();
    const imagBlobs = [];

    // Loop through pages to find the first image
    for (let i = 0; i < totalPages; i++) {
      const page = pdfDoc.getPage(i);
      // console.log('page ', i + 1, ' //=====================================================================');

      // console.log('page.node.Resources(): ', page.node.Resources());

      const resources = page.node.Resources().dict;
      console.log([...resources.keys()]);
      const xObjectKey = PDFName.of('XObject');

      // Access the XObject dictionary
      const xObjects = resources.get(xObjectKey);

      if (xObjects) {
        // xObjects.forEach((xObjectRef) => {
        //   const xObject = pdfDoc.context.lookup(xObjectRef);
        //   console.log('xObject: ', xObject);
        //   if (xObject.constructor.name === 'PDFRawStream') {
        //     const imgBytes = xObject.contents;
        //     console.log('found rawStream');

        //     const imgBlob = new Blob([imgBytes], { type: 'application/octet-stream' });

        //     if (imgBlob.size > 0) {
        //       imagBlobs.push(imgBlob);
        //     }
        //   } else {
        //     console.log('xObject.constructor.name: ', xObject.constructor.name);
        //   }
        // });

        for (const [key, value] of xObjects.entries()) {
          // Lookup the object in the PDF context using the value (which is a reference)
          const xObject = pdfDoc.context.lookup(value);

          // Check if the object is a PDFRawStream (which represents an image)
          if (xObject.contents instanceof Uint8Array) {
            // Get the raw image data
            const imageBytes = xObject.contents;

            // console.log('getMimeTypeFromUint8Array(imageBytes): ', getMimeTypeFromUint8Array(imageBytes));
            // Convert the image data to a blob (you can adjust the MIME type if needed)
            const imgBlob = new Blob([imageBytes], { type: 'image/jpeg' });
            // imagBlob might be wrong, base64 failing.

            if (imgBlob.size > 0) {
              imagBlobs.push(imgBlob);
            }

            // You can now use the blob, for example, to display or download the image
          } else {
            // console.log('Not an image (PDFRawStream) for key:', key);
            // console.log('xObject.constructor: ', xObject.constructor);
            // console.log('xObject.constructor.name: ', xObject.constructor.name);
            // console.log('xObject.contents type:', Object.prototype.toString.call(xObject.contents));
          }
        }
      } else {
        console.log('No objects found in page');
      }
    }

    if (callback) {
      callback(imagBlobs);
    } else {
      callback(null);
    }

    // if (callback) {
    //   callback(null); // No images found

    //   alert('No images found in the PDF');
    // }
  };

  reader.readAsArrayBuffer(file);
};

// Function to handle PDF files using pdf-lib
// const getImagesFromPDFFile = async (file, callback) => {
//   const reader = new FileReader();

//   reader.onload = async (event) => {
//     const pdfDoc = await PDFDocument.load(event.target.result);
//     const totalPages = pdfDoc.getPageCount();

//     // Loop through pages to find the first image
//     for (let i = 0; i < totalPages; i++) {
//       const page = pdfDoc.getPage(i);
//       const { objects } = page.node.Resources();
//       console.log('page.node.Resources(): ', page.node.Resources());
//       console.log('objects: ', objects);

//       if (objects) {
//         const imageKeys = Object.keys(objects);
//         for (const key of imageKeys) {
//           const xObject = objects[key];
//           console.log('xObject.constructor.name : ', xObject.constructor.name);

//           if (xObject.constructor.name === 'PDFRawStream') {
//             const imgBytes = xObject.contents;
//             const imgBlob = new Blob([imgBytes], { type: 'image/jpeg' });

//             // Resize the image
//             resizeImage(imgBlob, callback);
//             return; // Stop after the first image
//           }
//         }
//       }
//     }

//     if (callback) {
//       callback(null); // No images found
//     }

//     alert('No images found in the PDF');
//   };

//   reader.readAsArrayBuffer(file);
// };

const getMimeTypeFromUint8Array = (uint8Array) => {
  const signatures = {
    'image/jpeg': [0xff, 0xd8, 0xff],
    'image/png': [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a],
    'image/gif': [0x47, 0x49, 0x46, 0x38],
    'image/bmp': [0x42, 0x4d],
    'image/tiff': [
      [0x49, 0x49, 0x2a, 0x00], // Little-endian
      [0x4d, 0x4d, 0x00, 0x2a], // Big-endian
    ],
    'image/webp': [[0x52, 0x49, 0x46, 0x46]], // RIFF
  };

  for (const [mimeType, signature] of Object.entries(signatures)) {
    let match = true;
    console.log('checking for mime type: ', mimeType);

    for (let i = 0; i < signature.length; i++) {
      if (uint8Array[i] !== signature[i]) {
        match = false;
        break;
      }
    }
    if (match) {
      return mimeType;
    }
  }

  return 'unknown';
};

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      // const resultToReturn = reader.result.replace('data:image/jpeg;base64,', '');
      const resultToReturn = reader.result;

      resolve(resultToReturn);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const getImagesFromPDFFileAndResize = async (file, callback) => {
  getImagesFromPDFFile(file, async (imageBlobs) => {
    if (imageBlobs) {
      const resizedImages = null;
      let biggestBlob = null;

      for (let i = 0; i < imageBlobs.length; i++) {
        if (biggestBlob == null || imageBlobs[i].size > biggestBlob.size) {
          biggestBlob = imageBlobs[i];
        }
      }

      if (biggestBlob) {
        // callback(biggestBlob);
        const base64Image = await blobToBase64(biggestBlob);
        // callback(base64Image);
        resizeImage({ imageBlob: biggestBlob }, (resizedImage) => {
          callback(resizedImage);
        });
      }
    } else {
      callback(null);
    }
  });
};
