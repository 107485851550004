import React, { useState, useEffect } from 'react';
import style from './ApplicantHeader.style';
import RezRateImg from '../../../../../assets/images/header/rezRate.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';

const ApplicantHeader = ({ showMode }) => {
  // Your code here
  const headerTitles = {
    login: 'Applicant Login',
    profile: 'Applicant Profile',
    create_account: 'Create Applicant Account',
  };

  const { logout } = useKindeAuth();
  return (
    <div style={{ ...style.simpleRow, backgroundColor: '#333333', color: 'white', padding: '10px 20px' }}>
      <div style={{ ...style.simpleColumn, width: 60, height: 60, padding: 5 }}>
        <img src={RezRateImg} alt="RezRate Logo" style={{ width: 50, height: 50 }} />
      </div>
      <div
        style={{
          ...style.simpleColumn,
          width: '100%',
          color: 'white',
          marginLeft: 10,
        }}
      >
        <div
          style={{
            ...style.simpleRow,
            justifyContent: 'flex-start',
            alignContent: 'flex-start',
            fontSize: 19,
            fontWeight: 'bold',
          }}
        >
          {headerTitles[showMode]}
        </div>
      </div>
      <div style={{ ...style.simpleColumn, width: 60, height: 60, padding: 5 }}>
        <Button
          id={'signout_btn'}
          color="danger"
          onClick={() => {
            Swal.fire({
              title: 'Are you sure you want to log out?',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
            }).then((result) => {
              if (result.isConfirmed) {
                logout();
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faSignOutAlt} />
        </Button>
      </div>
    </div>
  );
};

export default ApplicantHeader;
