import { faCalendarCheck, faClipboard, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap';
import { useAuth0 } from '../../../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../../../Common.functions';
import { DepartmentContext } from '../../../../../../DepartmentWrapper';
import { postDataAgnostic, putData } from '../../../../../../Services/dataApi';
import ConfirmModal from '../../../../Coordinator/Schedule/ConfirmModal/index';
import style from './ ManagePopover.style';
import Swal from 'sweetalert2';
import { useAlert } from 'react-alert';

const ManagePopover = ({ interviewDate, candidate, getCandidates, hidePopover, setActiveTab, setHasChanges }) => {
  const [scheduleNotes, setScheduleNotes] = useState('');
  const [showOverBookedAlert, setShowOverBookedAlert] = useState(false);
  const [showAlreadyScheduledAlert, setShowAlreadyScheduledAlert] = useState(false);
  const [isScheduling, setIsScheduling] = useState(false);
  const [isSavingNotes, setIsSavingNotes] = useState(false);

  const {
    CountOfCandidates,
    tags,
    EnableTagLimit,
    CandidateRegistrationLimit,

    isScheduled,
    DateOfInterview,
    Label,
  } = interviewDate;

  const { SchedulingNotes = '' } = candidate;
  const alert = useAlert();
  const history = useHistory();
  const dContext = useContext(DepartmentContext);
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  let tagLimitCount = 0;
  let color = 'success';
  let isOverBooked = false;

  //   if (EnableTagLimit) {
  //     tags.forEach((tag) => {
  //       tagLimitCount += tag.InterviewDateLimit;
  //     });
  //     if (tagLimitCount > CountOfCandidates) {
  //       isOverBooked = true;
  //       color = 'danger';
  //     }
  //   } else

  if (CountOfCandidates >= CandidateRegistrationLimit) {
    isOverBooked = true;
    color = 'danger';
  }

  if (isScheduled) {
    color = 'danger';
  }

  useEffect(() => {
    if (SchedulingNotes) {
      setScheduleNotes(SchedulingNotes);
    }
  }, [candidate]);

  useEffect(() => {
    if (scheduleNotes !== SchedulingNotes) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [scheduleNotes]);

  const scheduleCandidate = () => {
    const { department, season } = dContext;
    setIsScheduling(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'candidate',
          {
            pk_Department: department.pk_Department,
          },
          {
            CandidatePks: [candidate.pk_Candidate],
            fieldsToUpdate: {
              pk_InterviewDate: interviewDate.pk_InterviewDate,
            },
          },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Candidate scheduled');
            setIsScheduling(false);
            getCandidates();
            hidePopover();
          })
          .catch((err) => {
            alert.error('Error scheduling candidate');
            setIsScheduling(false);
          });
      })
      .catch((err) => {
        alert.error('Error scheduling candidate');
        setIsScheduling(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const saveNotes = () => {
    setIsSavingNotes(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'candidate',
          { pk_Department: dContext.department.pk_Department },
          {
            CandidatePks: [candidate.pk_Candidate],
            fieldsToUpdate: {
              SchedulingNotes: scheduleNotes,
            },
          },
          formatBearerToken(token),
        )
          .then((results) => {
            alert.success('Notes saved');
            setIsSavingNotes(false);
            getCandidates();
            hidePopover();
          })
          .catch((err) => {
            alert.error('Error saving notes');
            setIsSavingNotes(false);
          });
      })
      .catch((err) => {
        alert.error('Error saving notes');
        setIsSavingNotes(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };
  const renderOverBookedAlert = () => {
    const message = ` "${moment(DateOfInterview).format('dddd, MMM. DD, YYYY')} ${
      Label ? `(${Label})` : ''
    }" is already at limit (${CountOfCandidates || 0}/${CandidateRegistrationLimit || 0}). Would you like to overbook?`;

    return (
      <ConfirmModal
        title={'Overbook?'}
        message={message}
        isOpen={showOverBookedAlert}
        buttonStyle={{ cancelBtnColor: 'secondary', acceptBtnColor: 'warning', acceptText: 'Overbook' }}
        onAccept={() => {
          if (isScheduled) {
            showOverBookedAlert(false);
          } else {
            fireConfirmModal();
          }
          setShowOverBookedAlert(false);
        }}
        onCancel={() => {
          setShowOverBookedAlert(false);
        }}
      />
    );
  };

  const renderAlreadyScheduledAlert = () => {
    const message = candidate
      ? `${candidate.LastName} is already assigned to an interview date - ${moment(candidate.DateOfInterview).format(
          'dddd, MMM. DD, YYYY',
        )}. Do you want to reassign anyway?`
      : '';
    return (
      <ConfirmModal
        title={'Already Scheduled!'}
        message={message}
        isOpen={showAlreadyScheduledAlert}
        onAccept={() => {
          fireConfirmModal();
          setShowAlreadyScheduledAlert(false);
        }}
        onCancel={() => {
          setShowAlreadyScheduledAlert(false);
        }}
      />
    );
  };

  const fireConfirmModal = () => {
    const { FirstName, LastName } = candidate;
    const formattedDate = moment(DateOfInterview).format('dddd, MMM. DD, YYYY');

    Swal.fire({
      title: 'Register candidate?',
      text: `Register ${FirstName || ''} ${LastName || ''} for ${formattedDate} ${Label ? `(${Label})` : ''}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Cancel',
      cancelButtonText: 'Register',
      confirmButtonColor: 'gray',
      cancelButtonColor: 'red',
    }).then((result) => {
      if (result.isDismissed) {
        scheduleCandidate();
      }
    });
  };

  return (
    <>
      <div style={style.simpleColumn}>
        <Button
          color="primary"
          style={style.button}
          onClick={() => {
            history.push(`/administrator/candidates?pk_Candidate=${candidate.pk_Candidate}`);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <FontAwesomeIcon icon={faClipboard} />
            <div style={style.centeredText}>View Candidate profile</div>
          </div>
        </Button>
        <Button
          color="primary"
          style={style.button}
          onClick={() => {
            const pk_Candidate = candidate.pk_Candidate;
            setActiveTab(1);
            // setTimeout(() => {
            history.push(`/administrator/communications/sendmessages?pk_Candidate=${pk_Candidate}`);
            // }, 1000);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <FontAwesomeIcon icon={faEnvelope} />
            <div style={style.centeredText}>Email Candidate</div>
          </div>
        </Button>
        <Button
          color={color}
          style={style.button}
          disabled={isScheduling}
          onClick={() => {
            if (isOverBooked) {
              setShowOverBookedAlert(true);
            } else if (isScheduled) {
              setShowAlreadyScheduledAlert(true);
            } else {
              fireConfirmModal();
            }
          }}
        >
          <div style={style.spacedBetweenRow}>
            <FontAwesomeIcon icon={faCalendarCheck} />
            <div style={style.centeredText}>
              Register Candidate for{' '}
              {interviewDate && interviewDate.DateOfInterview
                ? moment(interviewDate.DateOfInterview).format('ddd, MMM DD, YYYY')
                : ''}
            </div>
          </div>
        </Button>
        <div style={style.simpleRow}>
          <div style={{ ...style.simpleColumn, width: '100%' }}>
            <div style={{ ...style.simpleRow, marginBottom: 5 }}>Schedule Notes</div>
            <Input
              id="scheduleNotes"
              type="textarea"
              rows={5}
              value={scheduleNotes}
              placeholder="Schedule Notes. . ."
              onChange={(e) => {
                setScheduleNotes(e.target.value);
              }}
            />
            <div style={{ ...style.simpleRow, justifyContent: 'center', marginTop: 5 }}>
              <Button
                className="manage_save_notes"
                disabled={scheduleNotes === SchedulingNotes}
                color={'success'}
                onClick={() => {
                  saveNotes();
                  // Swal.fire({
                  //   title: 'Save Notes',
                  //   text: 'Are you sure',
                  //   showCancelButton: true,
                  //   confirmButtonText: 'Cancel',
                  //   cancelButtonText: 'Save',
                  //   confirmButtonColor: 'gray',
                  //   cancelButtonColor: 'red',
                  // }).then((result) => {
                  //   if (result.isDismissed) {
                  //     // saveNotes();
                  //   }
                  // });
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>

        {renderOverBookedAlert()}
        {renderAlreadyScheduledAlert()}
      </div>
    </>
  );
};

export default ManagePopover;
