const style = {
  scheduleDateContainer: {
    height: 150,
    width: '100%',
    overflowY: 'scroll',
    marginBottom: 10,
  },
  filterButton: {
    width: '100%',
    backgroundColor: '#0a91ff',
  },
  buttonLabel: {
    width: '100%',
    textAlign: 'left',
  },
  spacedBetweenRow: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  simpleRow: {
    flexDirection: 'row',
    display: 'flex',
    marginBottom: 4,
  },
  dateListItem: { width: '100%', textAlign: 'center', cursor: 'pointer', padding: 10 },
};

export default style;
