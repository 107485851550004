// these types are used to determined what endpoints and value should be passed to the backend

const UserPhotoTypes = {
  AdminCandidate: 'AdminCandidate',
  AdminEvaluator: 'AdminEvaluator',
  Candidate: 'Candidate',
  DepartmentLogo: 'DepartmentLogo',
  OrganizationLogo: 'OrganizationLogo',
  Applicant: 'Applicant',
};

export default UserPhotoTypes;
