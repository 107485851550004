import React from 'react';
import Footer from 'Body/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import Loading from 'Body/Statuses/Loading';

const MaintenanceScreen = (props) => {
  const { showFooter, isCheckingMaintenance } = props;
  return (
    <>
      {' '}
      <div
        style={{
          height: 'calc(100vh - 40px)',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '80%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '25vh',
            }}
          >
            <Loading
              type="lottie"
              options={{
                height: 350,
                labelStyle: {
                  fontSize: 0,
                },
              }}
            />
            {/* <FontAwesomeIcon icon={faWrench} size="10x" style={{ color: '#f0ad4e' }} /> */}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 30,
              textAlign: 'center',
            }}
          >
            We are performing system maintenance.
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 18,
              textAlign: 'center',
            }}
          >
            <span>
              For more information, please visit our{' '}
              <a href="https://status.rezrate.com/ " target="_blank">
                {' '}
                Status Page
              </a>
              .
            </span>
          </div>
          {isCheckingMaintenance ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 18,
                textAlign: 'center',
              }}
            >
              {' '}
              Rechecking status. . .
            </div>
          ) : null}
        </div>
      </div>
      {showFooter && <Footer />}
    </>
  );
};

export default MaintenanceScreen;
