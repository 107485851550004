import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

// single button for navigation
const NavigationButton = ({
  name,
  fullName,
  linkTo,
  icon,
  last,
  selected,
  setSelected,
  i,
  maxWidth,
  setShowSideBar,
  minWidth,
}) => {
  const first = i === 0;
  const isSelected = selected;
  const id = `tooltip_${fullName ? fullName.replaceAll(' ', '_') : ''}`;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: maxWidth ? '100%' : null }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: maxWidth ? '100%' : null,
        }}
      >
        {!first && !maxWidth && <div style={{ borderLeft: '1px solid white', height: '100%' }} />}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '7px 10px 7px 10px',
            color: 'white',
            width: maxWidth ? '100%' : null,
            backgroundColor: isSelected ? 'white' : 'rgb(51, 51, 51)',
            fontSize: 12,
            alignItems: 'center',
          }}
        >
          <p
            style={{
              fontWeight: 700,
              marginBottom: 0,

              fontSize: '1em',
              width: '100%',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Link
              to={linkTo}
              style={{ color: isSelected ? 'rgb(51, 51, 51)' : 'white' }}
              onClick={() => {
                setSelected(i);

                if (setShowSideBar) {
                  setShowSideBar(false);
                }
              }}
            >
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                <FontAwesomeIcon
                  id={id}
                  style={{
                    color: isSelected ? 'rgb(51, 51, 51)' : 'white',
                    paddingRight: name ? '5px' : 0,
                    fontSize: maxWidth ? 25 : null,
                  }}
                  icon={icon}
                  size="lg"
                ></FontAwesomeIcon>
                {!name ? <UncontrolledTooltip target={id}> {fullName}</UncontrolledTooltip> : null}
                <div style={{ textAlign: 'right' }}>{name.toUpperCase()}</div>
              </div>
            </Link>
          </p>
        </div>
        {!maxWidth && !last ? <div style={{ borderLeft: '1px solid white', height: '100%' }} /> : null}
      </div>
    </div>
  );
};

export default NavigationButton;
