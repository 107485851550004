import { faSquare, faSquareCheck, faTrashAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import RezRateTable from 'Body/RezRATE/Common/RezRateTable/RezRateTable';
import React, { useContext, useEffect, useState } from 'react';

import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import style from './PrescreenAssignments.style';
import { fetchDataAgnostic } from 'Services/dataApi';
import { DepartmentContext } from 'DepartmentWrapper';
import { formatBearerToken } from 'Common.functions';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PrescreenAssignments = (props) => {
  const { pk_User, candidates } = props;
  const [candidatesToDisplay, setCandidatesToDisplay] = useState([]);
  const [isFetchingPrescreenData, setIsFetchingPrescreenData] = useState(true);
  const [prescreenData, setPrescreenData] = useState([]);
  const [selectAllCandidatesForAssignment, setSelectAllCandidatesForAssignment] = useState(false);
  const [selectedCandidatesForAssignment, setSelectedCandidatesForAssignment] = useState([]);

  const [showCandidatesForAssignment, setShowCandidatesForAssignment] = useState(false);

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);
  const columnData = [
    {
      label: 'Last Name',
      dataKey: 'LastName',
      width: 200,
    },
    {
      label: 'First Name',
      dataKey: 'FirstName',
      width: 200,
    },
    {
      label: 'Status',
      dataKey: 'Status',
      width: 200,
    },
    {
      label: 'Action',
      dataKey: 'Action',
      width: 200,
      cellFormatter: (cellData, rowData) => {
        const { Status } = rowData;
        return (
          <div style={style.simpleRow}>
            {Status === 'Pending' ? (
              <Button
                size="sm"
                color="danger"
                onClick={() => {
                  console.log('Delete cellData: ', cellData);
                  console.log('Delete rowData: ', rowData);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            ) : null}
          </div>
        );
      },
    },
  ];
  const candidatesForAssignmentColumns = [
    {
      label: 'select',
      width: 120,
      headerFormatter: () => {
        return (
          <div
            style={style.simpleRow}
            onClick={() => {
              setSelectAllCandidatesForAssignment(!selectAllCandidatesForAssignment);
            }}
          >
            Select{' '}
            <div>
              <FontAwesomeIcon
                icon={selectAllCandidatesForAssignment ? faSquareCheck : faSquare}
                style={{ cursor: 'pointer', marginLeft: 5 }}
              />
            </div>
          </div>
        );
      },
      cellFormatter: (cellData, rowData) => {
        const { isSelected, pk_Candidate } = rowData;

        return (
          <div
            style={style.simpleRow}
            onClick={() => {
              toggleSelectCandidateForAssignment(pk_Candidate);
            }}
          >
            <FontAwesomeIcon icon={isSelected ? faSquareCheck : faSquare} style={{ cursor: 'pointer' }} />
          </div>
        );
      },
    },
    {
      label: 'Last Name',
      dataKey: 'LastName',
      width: 200,
    },
    {
      label: 'First Name',
      dataKey: 'FirstName',
      width: 200,
    },
    {
      label: 'AAMC ID',
      dataKey: 'AAMCID',
      width: 100,
    },
    {
      label: 'SFMatch ID',
      dataKey: 'SFMatchID',
      width: 100,
    },
    {
      label: 'Email',
      dataKey: 'Email',
      width: 800,
    },
  ];

  useEffect(() => {
    if (pk_User) {
      getPrescreenData();
    }
  }, [pk_User]);

  useEffect(() => {
    console.log('candidates: ', candidates);
    if (candidates) {
      updateCandidateToDisplay();
    }
  }, [candidates, prescreenData]);

  useEffect(() => {
    if (selectAllCandidatesForAssignment) {
      const newCandidatesToDisplay = candidatesToDisplay.map((c) => {
        return { ...c, isSelected: true };
      });
      setCandidatesToDisplay(newCandidatesToDisplay);
    } else {
      const newCandidatesToDisplay = candidatesToDisplay.map((c) => {
        return { ...c, isSelected: false };
      });
      setCandidatesToDisplay(newCandidatesToDisplay);
    }
  }, [selectAllCandidatesForAssignment]);

  const toggleSelectCandidateForAssignment = (pk_Candidate) => {
    const newCandidatesToDisplay = candidatesToDisplay.map((c) => {
      if (c.pk_Candidate === pk_Candidate) {
        return { ...c, isSelected: !c.isSelected };
      } else {
        return c;
      }
    });
    setCandidatesToDisplay(newCandidatesToDisplay);
  };

  const getPrescreenData = () => {
    setIsFetchingPrescreenData(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/user/scheduledate/candidates',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_User: pk_User,
            mode: 'Prescreen',
            isAdmin: 1,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (res.status === 200) {
              setPrescreenData(res.data.sort((a, b) => (a.LastName > b.LastName ? 1 : -1)));
              const groupedData = res.data.reduce((acc, item) => {
                (acc[item.Status] = acc[item.Status] || []).push(item);
                return acc;
              }, {});

              const sortedData = Object.keys(groupedData)
                .sort((a, b) => (a === 'Pending' ? -1 : b === 'Pending' ? 1 : 0))
                .reduce((acc, key) => {
                  const sortedGroup = groupedData[key].sort((a, b) => (a.LastName > b.LastName ? 1 : -1));
                  return acc.concat(sortedGroup);
                }, []);

              setPrescreenData(sortedData);
            }
          })
          .catch((err) => {
            console.log('Error: ', err);
          })
          .finally(() => {
            setIsFetchingPrescreenData(false);
          });
      })
      .catch((err) => {
        console.log('Error: ', err);
        setIsFetchingPrescreenData(false);
        if (err.error === 'login_required') {
          loginWithRedirect();
        }
      });
  };

  const updateCandidateToDisplay = () => {
    const newCandidatesToDisplay = [];
    console.log('prescreenData: ', prescreenData);

    candidates.forEach((candidate) => {
      const newCandidate = { ...candidate };
      prescreenData.forEach((prescreen) => {
        if (prescreen.pk_Candidate === candidate.pk_Candidate) {
          newCandidate.isAssignedAlready = true;
        }
      });
      newCandidatesToDisplay.push(newCandidate);
    });

    console.log('newCandidatesToDisplay: ', newCandidatesToDisplay);

    setCandidatesToDisplay(newCandidatesToDisplay);
  };

  const renderCandidatesForAssignment = () => {
    return (
      <Modal isOpen={showCandidatesForAssignment} style={{ maxWidth: '80vw', minWidth: 500 }} centered>
        <ModalHeader
          toggle={() => {
            setShowCandidatesForAssignment(!showCandidatesForAssignment);
          }}
        >
          {' '}
          Candidates to Assign
        </ModalHeader>
        <ModalBody>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: '100%', height: '50vh' }}>
              <RezRateTable
                data={candidatesToDisplay || []}
                tableBodyHeight={`45vh`}
                columns={candidatesForAssignmentColumns}
                noDataMessage={'No Data'}
              />
              {/* {candidatesToDisplay ? (
                candidatesToDisplay.map((c) => {
                  return (
                    <div style={{ ...style.simpleRow, width: '100%' }}>
                      <div style={{ ...style.simpleColumn, width: 200 }}>
                        <div style={{ ...style.simpleRow, justifyContent: 'space-between', width: '100%' }}>
                          <b style={{ marginRight: 10 }}>
                            {c.LastName || ''}
                            {c.FirstName && c.LastName ? ', ' : ''}
                            {c.FirstName || ''}
                          </b>
                        </div>
                      </div>
                      <div style={{ ...style.simpleColumn, width: 100 }}>
                        <Button
                          color="success"
                          onClick={() => {
                            console.log('Assigning candidate: ', c);
                          }}
                        >
                          Assign
                        </Button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={style.simpleRow}>No candidates to assign</div>
              )} */}
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', width: 'auto', justifyContent: 'center', alignItems: 'center' }}
    >
      <div style={style.simpleColumn}>
        <div style={{ ...style.simpleRow, marginBottom: 20, justifyContent: 'space-between', width: '100%' }}>
          <div style={style.simpleColumn}>
            <div style={style.simpleRow}>
              {' '}
              <b style={{ marginRight: 10 }}>Total Assigned:</b> {prescreenData.length}
            </div>
          </div>
          <Button
            color="success"
            onClick={() => {
              setShowCandidatesForAssignment(!showCandidatesForAssignment);
            }}
          >
            <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: 10 }} />
            Assign Candidate
          </Button>
        </div>
        <div style={style.simpleRow}>
          {' '}
          <div style={{ display: 'flex', flexDirection: 'column', width: 'auto' }}>
            <RezRateTable
              data={prescreenData || []}
              tableBodyHeight={`calc(100vh - 540px)`}
              columns={columnData || []}
              noDataMessage={isFetchingPrescreenData ? 'Loading. . .' : 'No Data'}
              onRowClick={(row) => {
                console.log('row: ', row);
              }}
              // rowStyle={(rowData) => {
              //   const { isSelected } = rowData;
              //   if (isSelected) {
              //     return {
              //       backgroundColor: '#ADD8E6',
              //     };
              //   } else {
              //     return {};
              //   }
              // }}
            />
          </div>
          {renderCandidatesForAssignment()}
        </div>
      </div>
    </div>
  );
};

export default PrescreenAssignments;

// // for Candidate page - array of candidates with their evaluators
// [
//   {
//     pk_Candidate: 1,
//     FirstName: 'Trowa',
//     LastName: 'Barton',
//     email: 'x@xmail.com',
//     AAMCID: '123456',
//     SFMatchID: '123456',
//     assignments: [
//       {
//         pk_Evaluator: 1,
//         UserFirst: 'Heero',
//         UserLast: 'Yuy',
//         status: 'Pending',
//       },
//     ],
//   },
// ];

// // for Team page - array of users with their candidates
// [
//   {
//     pk_User: 1,
//     UserFirst: 'Heero',
//     UserLast: 'Yuy',
//     email: '123456',
//     assignments: [
//       {
//         pk_Candidate: 1,
//         FirstName: 'Trowa',
//         LastName: 'Barton',
//         email: 'x@xmail.com',
//         AAMCID: '123456',
//         SFMatchID: '123456',
//         status: 'Pending',
//       },
//     ],
//   },
// ];
