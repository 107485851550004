import axios from 'axios';
import { fetchMaintenanceStatus } from 'Common.functions';
import { clone } from 'lodash';

const constructUrl = (url) => {
  const { REACT_APP_BUILD_TYPE } = process.env;

  if (REACT_APP_BUILD_TYPE && REACT_APP_BUILD_TYPE === 'liveproduction') {
    return 'https://prodapi.rezrate.com/' + url;
  }

  if (REACT_APP_BUILD_TYPE && REACT_APP_BUILD_TYPE === 'livedevelopment') {
    return 'https://devapi.rezrate.com/' + url;
  }

  return `https://devapi.rezrate.com/` + url;
};

async function fetchData(url, params) {
  try {
    const result = await axios({
      url,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params,
    })
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
    return result;
  } catch (err) {
    console.log('fetchData error: ', err);
    fetchMaintenanceStatus({
      updateLocalStorage: true,
    });
    throw err;
  }
}

async function fetchDataWithoutCredentials(url, params) {
  try {
    const result = await axios({
      url,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params,
    })
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
    return result;
  } catch (err) {
    console.log('fetchDataWithoutCredentials error: ', err);
    fetchMaintenanceStatus({
      updateLocalStorage: true,
    });
    throw err;
  }
}

async function fetchDataAgnostic(url, params, headers, options = {}) {
  const completeHeaders = { 'Content-Type': 'application/json', ...headers };
  const { abortController, callback } = options;
  const { signal } = abortController || {};
  const axiosOptions = {
    url: constructUrl(url),
    method: 'GET',
    params,
    headers: completeHeaders,
  };

  if (options && abortController) {
    axiosOptions.signal = signal;
  }

  try {
    if (callback) {
      axios
        .get(constructUrl(url), axiosOptions)
        .then((result) => {
          callback(result);
        })
        .catch((err) => {
          callback(err);
        });
      return;
    } else {
      const result = await axios(axiosOptions)
        .then((result) => result)
        .catch((error) => {
          throw error;
        });
      return result;
    }
  } catch (err) {
    console.log('fetchDataAgnostic error: ', err);
    fetchMaintenanceStatus({
      updateLocalStorage: true,
    });
    throw err;
  }
}

async function fetchDataAgnosticWithoutCredentials(url, params, headers) {
  const completeHeaders = { 'Content-Type': 'application/json', ...headers };

  try {
    const result = await axios({
      url: constructUrl(url),
      method: 'GET',
      params,
      headers: completeHeaders,
    })
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
    return result;
  } catch (err) {
    console.log('fetchDataAgnosticWithoutCredentials error: ', err);
    fetchMaintenanceStatus({
      updateLocalStorage: true,
    });
    throw err;
  }
}

async function postDataFM(url, params, dates) {
  try {
    const result = await axios({
      url,
      method: 'POST',
      params,
      header: { 'Access-Control-Allow-Origin': '*' },
    })
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
    return result;
  } catch (err) {
    console.log('postDataFM error: ', err);
    fetchMaintenanceStatus({
      updateLocalStorage: true,
    });
    throw err;
  }
}

async function postData(url, params, dates) {
  try {
    const result = await axios({
      url,
      method: 'POST',
      params,
      header: { 'Access-Control-Allow-Origin': '*' },
      data: { dates },
    })
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
    return result;
  } catch (err) {
    console.log('postData error: ', err);
    fetchMaintenanceStatus({
      updateLocalStorage: true,
    });
    throw err;
  }
}

async function postDataWithoutCredentials(url, params, data, headers) {
  try {
    const result = await axios({
      url: url,
      method: 'POST',
      headers: { ...headers },
      params,
      data,
    })
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
    return result;
  } catch (err) {
    console.log('postDataWithoutCredentials error: ', err);
    fetchMaintenanceStatus({
      updateLocalStorage: true,
    });
    throw err;
  }
}

async function postDataAgnostic(url, params, data, headers) {
  try {
    const result = await axios({
      url: constructUrl(url),
      method: 'POST',
      headers: { ...headers },
      params,
      data,
    })
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
    return result;
  } catch (err) {
    console.error('postDataAgnostic error: ', err);
    fetchMaintenanceStatus({
      updateLocalStorage: true,
    });
    throw err;
  }
}

async function postDataAgnosticWithoutCredentials(url, params, data, headers) {
  try {
    const result = await axios({
      url: constructUrl(url),
      method: 'POST',
      headers: { ...headers },
      params,
      data,
    })
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
    return result;
  } catch (err) {
    console.log('postDataAgnosticWithoutCredentials error: ', err);
    fetchMaintenanceStatus({
      updateLocalStorage: true,
    });
    throw err;
  }
}

async function fetchDataAuth0(url, params, headers) {
  const completeHeaders = { 'Content-Type': 'application/json', ...headers };

  try {
    const result = await axios({
      url: constructUrl(url),
      method: 'GET',
      params,
      headers: completeHeaders,
    })
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
    return result;
  } catch (err) {
    console.log('fetchDataAuth0 error: ', err);
    fetchMaintenanceStatus({
      updateLocalStorage: true,
    });
    throw err;
  }
}

async function deleteData(url, params, data, headers) {
  const completeHeaders = { 'Content-Type': 'application/json', ...headers };

  try {
    const result = await axios({
      url: constructUrl(url),
      method: 'DELETE',
      params,
      data,
      headers: completeHeaders,
    })
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
    return result;
  } catch (err) {
    console.log('deleteData error: ', err);
    fetchMaintenanceStatus({
      updateLocalStorage: true,
    });
    throw err;
  }
}

async function putData(url, params, data, headers) {
  const completeHeaders = { 'Content-Type': 'application/json', ...headers };
  try {
    const result = await axios({
      url: constructUrl(url),
      method: 'PUT',
      params,
      data,
      headers: completeHeaders,
    })
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
    return result;
  } catch (err) {
    console.error('putData error: ', err);
    fetchMaintenanceStatus({
      updateLocalStorage: true,
    });
    throw err;
  }
}

export {
  fetchData,
  putData,
  deleteData,
  postDataAgnosticWithoutCredentials,
  postData,
  fetchDataAuth0,
  fetchDataAgnostic,
  postDataFM,
  fetchDataWithoutCredentials,
  postDataWithoutCredentials,
  constructUrl,
  postDataAgnostic,
  fetchDataAgnosticWithoutCredentials,
};
