import React, { useState } from 'react';
import { Col, Button, Table, Form, FormGroup, Label, Input, FormText, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Popup from 'reactjs-popup';
import { NotificationManager } from 'react-notifications';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken, swalErrorMessage } from '../../../../../Common.functions';
import Swal from 'sweetalert2';
import { postDataAgnostic } from '../../../../../Services/dataApi';
import SeasonListItem from './SeasonListItem';

const SeasonList = ({
  isFetchingDepartment,
  seasons,
  fetchDepartment,
  seasonPopoverOpen,
  handleSeasonToggle,
  updateSeasonArray,
  setSeasons,
  selectedDepartment,
  selectedOrganization,
  saveSeason,
  updatingActiveSeason,
}) => {
  const [newSeasonPopup, setNewSeasonPopup] = useState(false);
  const [seasonName, setSeasonName] = useState('');
  const [seasonMode, setSeasonMode] = useState('Prescreen');
  const [seasonArchetype, setSeasonArchetype] = useState(null);

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const handleSeasonChange = (event) => {
    const { value } = event.target;
    setSeasonArchetype(value);
  };

  const addNewSeason = (e) => {
    e.preventDefault();

    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'organization/department/season',
          {
            pk_Organization: selectedOrganization,
            pk_Department: selectedDepartment,
          },
          {
            SeasonName: seasonName,
            SeasonMode: seasonMode,
            fk_Season: seasonArchetype === 'new' ? null : seasonArchetype,
          },
          formatBearerToken(token),
        )
          .then((result) => {
            if (fetchDepartment) {
              fetchDepartment();
              setSeasons((prevState) => {
                // eslint-disable-next-line
                const clone = structuredClone(prevState);
                clone.unshift(result.data);
                return clone;
              });
            }

            setNewSeasonPopup(false);

            Swal.fire('Success!', 'Season Created', 'success');
          })
          .catch((err) => {
            Swal.fire('Error', 'Something went wrong...', 'error');
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  return (
    <div>
      <Modal
        isOpen={newSeasonPopup}
        toggle={() => {
          setNewSeasonPopup(false);
          setSeasonName('');
        }}
        centered
        style={{ maxWidth: '80vw' }}
      >
        <ModalHeader
          toggle={() => {
            setNewSeasonPopup(false);
            setSeasonName('');
          }}
        >
          Add New Season
        </ModalHeader>
        <ModalBody>
          <Form className="form_no_word_wrap">
            <FormGroup row>
              <Label for="seasonName" sm={2}>
                Name:
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="seasonName"
                  value={seasonName}
                  onChange={(e) => setSeasonName(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="seasonMode" sm={2}>
                Season Mode:
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="seasonMode"
                  value={seasonMode}
                  onChange={(e) => setSeasonMode(e.target.value)}
                >
                  <option value="Prescreen">Prescreen</option>
                  <option value="Evaluation">Evaluation</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleSelect" sm={2}>
                Archetype:
              </Label>
              <Col sm={10}>
                <Input type="select" name="select" id="exampleSelect" onChange={handleSeasonChange}>
                  <option value="new">None</option>
                  {seasons.map((item) => {
                    const { SeasonName, pk_Season } = item;
                    return (
                      <option key={pk_Season} value={pk_Season}>
                        {SeasonName || ''}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>
          </Form>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Button color="success" onClick={addNewSeason}>
              Save
            </Button>
          </div>
        </ModalBody>
      </Modal>

      {/* <Popup
        style={{ maxWidth: '400px' }}
        closeOnDocumentClick={true}
        open={newSeasonPopup}
        onClose={() => {
          setNewSeasonPopup(false);
          setSeasonName('');
          setSeasonMode('');
        }}
      >
        <div>
          <Form className="form_no_word_wrap">
            <FormGroup row>
              <Label for="seasonName" sm={2}>
                Name:
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="seasonName"
                  value={seasonName}
                  onChange={(e) => setSeasonName(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="seasonMode" sm={2}>
                Season Mode:
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="seasonMode"
                  value={seasonMode}
                  onChange={(e) => setSeasonMode(e.target.value)}
                >
                  <option value="Prescreen">Prescreen</option>
                  <option value="Evaluation">Evaluation</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleSelect" sm={2}>
                Archetype:
              </Label>
              <Col sm={10}>
                <Input type="select" name="select" id="exampleSelect" onChange={handleSeasonChange}>
                  <option value="new">None</option>
                  {seasons.map((item) => {
                    const { SeasonName, pk_Season } = item;
                    return (
                      <option key={pk_Season} value={pk_Season}>
                        {SeasonName || ''}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>
          </Form>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Button color="success" onClick={addNewSeason}>
              Save
            </Button>
          </div>
        </div>
      </Popup> */}
      <div style={{ display: 'flex', flexDirection: 'row', overflow: 'auto', maxHeight: 400 }}>
        <Table>
          <thead>
            <tr>
              <th>Active</th>
              <th>Season Name</th>
              <th>Candidates (Registered/Total)</th>
              <th>Interview Sessions</th>
              <th>Mode</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {seasons.map((season) => {
              return (
                <SeasonListItem
                  isFetchingDepartment={isFetchingDepartment}
                  updatingActiveSeason={updatingActiveSeason}
                  saveSeason={saveSeason}
                  key={season.pk_Season}
                  updateSeasonArray={updateSeasonArray}
                  {...season}
                  seasonPopoverOpen={seasonPopoverOpen}
                  handleSeasonToggle={handleSeasonToggle}
                />
              );
            })}
          </tbody>
        </Table>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          color="success"
          disabled={!selectedDepartment}
          onClick={(e) => {
            e.preventDefault();
            setNewSeasonPopup(true);
          }}
        >
          Add New Season
        </Button>
      </div>
    </div>
  );
};

export default SeasonList;
