const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacedBetweenRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  titleIcon: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 25,
    marginRight: 10,
  },
  titleLabel: {
    fontSize: 20,
    fontWeight: 600,
    width: 'calc(100% - 600px)',
  },
  centeredText: {
    textAlign: 'center',
    width: '100%',
  },
  imgPlaceHolder: {
    objectFit: 'contain',
  },
  inputField: {
    marginBottom: 8,
    // fontSize: 10,
  },
  topButton: {
    width: 300,
  },
  signedAgreementText: {
    fontSize: 10,
    textAlign: 'center',
    marginTop: 10,
  },
};
export default style;
