import moment from 'moment';
import React, { Fragment } from 'react';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { createActionButton, sanitizeWithOptions } from '../../../../../../Common.functions';
import style from './AdminEmailPreview.style';
import './AdminEmailPreview.style.css';

const AdminEmailPreview = ({ itemToPreview = {}, mode = 'sent' }) => {
  const { isOpened, Body, Subject, DateSent, FirstName, LastName, index, EmailSentTo } = itemToPreview || {};

  const uploadButton = createActionButton({ buttonText: 'Upload Photo' });
  const scheduleInterviewButton = createActionButton({ buttonText: 'Register for Interview' });
  const viewScheduleButton = createActionButton({ buttonText: 'View Schedule' });

  const createMarkUp = () => {
    return { __html: sanitizeWithOptions(Body) };
  };

  const renderSentTo = () => {
    if (!LastName && !FirstName && !EmailSentTo) {
      return '';
    }
    return `${LastName || ''} ${FirstName ? `, ${FirstName}` : ''} (${EmailSentTo || ''})`;
  };

  // used for updating button hrefs to have isPreview=true
  const processButtonsInBody = (bodyToUse) => {
    const doc = document.createElement('div');
    doc.innerHTML = bodyToUse || '';
    const buttonStrings = ['photo-upload', 'register-for-interview', 'view-schedule'];
    const buttons = [];
    buttonStrings.forEach((buttonString) => {
      const button = doc.getElementsByClassName(buttonString);
      if (button.length > 0) {
        buttons.push(button[0]);
      }
    });

    for (let i = 0; i < buttons.length; i++) {
      buttons[i].href = `${buttons[i].href}?preview=true`;
    }
    return doc.innerHTML;
  };

  return (
    <Fragment>
      {mode === 'sent' ? (
        <div>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: 100 }}>
              <strong>Subject:</strong>
            </div>
            <div style={style.simpleColumn}>{Subject || ''}</div>
          </div>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: 100 }}>
              <strong>Date Sent:</strong>
            </div>
            <div style={style.simpleColumn}>
              {DateSent ? moment(DateSent).format('dddd, MMMM DD, YYYY hh:mm A') : ' '}
            </div>
          </div>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: 100 }}>
              <strong>Sent To:</strong>
            </div>
            <div style={style.simpleColumn}>{renderSentTo()}</div>
          </div>
        </div>
      ) : null}
      <div class="row">
        <div class="col-12">
          <div
            style={{
              width: '100%',
              minHeight: 'calc(100vh - 450px)',
              backgroundColor: 'aliceblue',
              borderRadius: '10px',
              paddingTop: 20,
              paddingBottom: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'top',
              // justifyContent: 'center',
              display: 'flex',
              //alignItems: 'center',
            }}
          >
            {mode === 'sent' || !Body ? (
              parse(processButtonsInBody(sanitizeWithOptions(Body || '')))
            ) : (
              <div dangerouslySetInnerHTML={createMarkUp()}></div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminEmailPreview;
