import React, { useEffect, useState } from 'react';
import { faM, faShieldHalved, faS } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth0 } from '../../auth0/reactAuth0Spa';
import { fetchDataAgnostic } from '../../Services/dataApi';
import { useHistory } from 'react-router-dom';
import { Col, Row, Button, Input, Form, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import { DatePicker } from 'react-widgets/cjs';
import BootstrapTable from 'react-bootstrap-table-next';
import { formatBearerToken } from '../../Common.functions';
import useScrollToBottomDetection from '../../ScrolledToBottomDetection';
import { PopupWithCloseButton } from '../../Common.jsx';

const noneCandidate = {
  pk_Candidate: 0,
  FullName: 'None',
};

const noneUser = {
  pk_User: 0,
  FullName: 'None',
};

const divFAWrapper = {
  width: '20px',
};

const eventRender = (value) => {
  const { MinorEvent, SecurityEvent, StandardEvent } = value;
  return (
    <div style={{ display: 'flex', minWidth: '60px' }}>
      <div style={divFAWrapper}>
        {MinorEvent && (
          <FontAwesomeIcon
            icon={faM}
            style={{ visibility: MinorEvent ? 'visible' : 'hidden' }}
            title="Minor Event"
            color="#A480CF"
          />
        )}
      </div>
      <div style={divFAWrapper}>
        {SecurityEvent && (
          <FontAwesomeIcon
            icon={faShieldHalved}
            style={{ visibility: SecurityEvent ? 'visible' : 'hidden' }}
            title="Security Event"
            color="#E8998D"
          />
        )}
      </div>
      <div style={divFAWrapper}>
        {StandardEvent && (
          <FontAwesomeIcon
            icon={faS}
            style={{ visibility: StandardEvent ? 'visible' : 'hidden' }}
            title="Standard Event"
            color="#6C9A8B"
          />
        )}
      </div>
    </div>
  );
};

const columns = [
  {
    dataField: 'pk_ActivityLog',
    text: 'ID',
    sort: false,
  },
  {
    dataField: 'DepartmentName',
    text: 'Department Name',
    sort: false,
  },
  {
    dataField: 'Timestamp',
    text: 'Timestamp',
    sort: true,
    formatter: (cell) => {
      return <div>{cell.format('MMM DD, YYYY hh:mm:ss A')}</div>;
    },
  },
  {
    dataField: 'UserName',
    text: 'User',
    sort: false,
  },
  {
    dataField: 'CandidateName',
    text: 'Candidate Name',
    sort: false,
  },
  {
    dataField: 'Category',
    text: 'Category',
    sort: false,
  },
  {
    dataField: 'EventTypes',
    text: 'Event Types',
    sort: false,
    formatter: (cell) => {
      return eventRender(cell);
    },
  },
  {
    dataField: 'Details',
    text: 'Details',
    sort: false,
  },
];

const sortAlphabetically = (arrayToSort) => {
  return arrayToSort.sort((a, b) => {
    if (a.FullName === 'None' || b.FullName === 'None') {
      return 0;
    }

    if (a.FullName.toUpperCase() < b.FullName.toUpperCase()) {
      return -1;
    }

    if (a.FullName.toUpperCase() > b.FullName.toUpperCase()) {
      return 1;
    }

    return 0;
  });
};

const DepartmentHistory = ({ activeDepartment, activeSeason, adminPanel }) => {
  const [pagination, setPagination] = useState(0);
  const [history, setHistory] = useState([]);
  const [candidateData, setCandidateData] = useState([]);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [additionalDetails, setAdditionalDetails] = useState({ pk_ActivityLog: 0, AdditionalDetails: {} });
  const [selectedLog, setSelectedLog] = useState(null);
  const [startingDate, setStartingDate] = useState(new Date());
  const [selectedUser, setSelectedUser] = useState(noneUser);
  const [selectedCandidate, setSelectedCandidate] = useState(noneCandidate);
  const [userData, setUserData] = useState([]);
  const [endReached, setEndReached] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { getTokenSilently } = useAuth0();

  const isBottom = useScrollToBottomDetection();
  const browserHistory = useHistory();

  useEffect(() => {
    const getFirstOrNext = isBottom || pagination === 0;
    const hasDepartmentInfoOrIsAdmin = (activeDepartment && activeSeason) || adminPanel;
    const hasAllParameters = hasDepartmentInfoOrIsAdmin && getTokenSilently;

    if (hasAllParameters && getFirstOrNext && !endReached) {
      getTokenSilently()
        .then((token) => {
          // admins won't search by candidates or users
          if (!adminPanel) {
            getCandidates(activeDepartment, activeSeason, token);
            getUsers(activeDepartment, token);
          }
          getLogs(activeDepartment, activeSeason, token);
        })
        .catch((err) => {});
    }
  }, [activeDepartment, activeSeason, getTokenSilently, isBottom]);

  useEffect(() => {
    columns.forEach((item) => {
      // need to add details after the componant has mounted since it uses state and I didn't
      // wnat to define the entire columns array inside the component
      if (item.dataField === 'Details') {
        item.formatter = (cell, row) => {
          return (
            <div style={{ cursor: 'pointer' }} onClick={(e) => handleDetailClick(e, row)}>
              {cell}
            </div>
          );
        };
      }
    });
  }, []);

  useEffect(() => {
    if (additionalDetails.pk_ActivityLog !== 0) {
      setShowAdditionalDetails(true);
    }
  }, [additionalDetails]);

  useEffect(() => {
    if (!selectedLog) return;

    getTokenSilently().then((token) => {
      fetchDataAgnostic(`department/history/log/${selectedLog}`, {}, formatBearerToken(token))
        .then((res) => {
          const { pk_ActivityLog, AdditionalDetails } = res.data;
          if (res.data.AdditionalDetails) {
            setAdditionalDetails({ pk_ActivityLog, AdditionalDetails: JSON.parse(AdditionalDetails) });
          }
        })
        .catch((err) => {});
    });
  }, [selectedLog]);

  const handleCandidateSelection = (e) => {
    const { value } = e.target;
    const element = candidateData.find((item) => {
      return item.FullName === value;
    });
    setSelectedCandidate(element);
  };

  const handleUserSelection = (e) => {
    const { value } = e.target;
    const element = userData.find((item) => {
      return item.FullName === value;
    });
    setSelectedUser(element);
  };

  const handleDetailClick = (e, row) => {
    setSelectedLog(row.pk_ActivityLog);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    getTokenSilently().then((token) => {
      getLogs(activeDepartment, activeSeason, token, true);
    });
  };

  const getUsers = (activeDepartment, token) => {
    fetchDataAgnostic(
      'department/users',
      {
        pk_Department: activeDepartment.pk_Department,
        IncludeEvaluators: 1,
      },
      formatBearerToken(token),
    )
      .then((res) => {
        const payload = Object.keys(res.data).map((key, index) => {
          const { UserLast, UserFirst, pk_User } = res.data[key];
          return { pk_User, FullName: `${UserLast}, ${UserFirst}` };
        });

        const payloadSorted = sortAlphabetically(payload);

        payloadSorted.unshift(noneUser);

        setUserData(payloadSorted);
      })
      .catch((err) => {});
  };

  const getCandidates = (activeDepartment, activeSeason, token) => {
    fetchDataAgnostic(
      'department/candidates',
      {
        pk_Department: activeDepartment.pk_Department,
        pk_Season: activeSeason.pk_Season,
      },
      formatBearerToken(token),
    )
      .then((res) => {
        const payload = Object.keys(res.data).map((key, index) => {
          const { LastName, FirstName, pk_Candidate } = res.data[key];
          return { pk_Candidate, FullName: `${LastName}, ${FirstName}` };
        });

        const payloadSorted = sortAlphabetically(payload);

        payloadSorted.unshift(noneCandidate);
        setCandidateData(payloadSorted);
      })
      .catch((err) => {});
  };

  const getLogs = (activeDepartment, activeSeason, token, newSet = false) => {
    let endOfDayLocal = moment(startingDate);
    endOfDayLocal.set({ hour: 23, minute: 59, second: 59 });
    let endOfDayUTC = endOfDayLocal.utc();

    let payload = { startingDate: endOfDayUTC.toISOString() };

    // if this is being done from the admin pane we're not restricting
    if (!adminPanel) {
      payload.pk_Department = activeDepartment.pk_Department;
      payload.pk_Season = activeSeason.pk_Season;
      payload.pk_Candidate = selectedCandidate.pk_Candidate;
      payload.pk_User = selectedUser.pk_User;
    }

    let paginationToUse = pagination;

    // if it's a new search set pagination back to 0
    if (newSet) {
      paginationToUse = 0;
      setPagination(0);
    }

    fetchDataAgnostic(`department/history/${paginationToUse}`, payload, formatBearerToken(token))
      .then((res) => {
        const formatted = res.data.map((item) => {
          const { MinorEvent, SecurityEvent, StandardEvent } = item;
          return {
            ...item,
            EventTypes: {
              MinorEvent,
              SecurityEvent,
              StandardEvent,
            },
            Timestamp: moment(item.Timestamp),
          };
        });
        if (formatted.length === 0) {
          setEndReached(true);
        }
        setPagination((prevState) => prevState + 1);

        // if it's a new search don't concatinate
        if (newSet) {
          setHistory(formatted);
        } else {
          setHistory((prevState) => {
            return [...prevState, ...formatted];
          });
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status && err.response.status === 401) {
          browserHistory.push('/');
        }
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <div />;
  }

  if (error) {
    return (
      <div>
        <p>Something went wrong...</p>
      </div>
    );
  }

  return (
    <div>
      <PopupWithCloseButton
        closeOnDocumentClick={true}
        open={showAdditionalDetails}
        closeFunction={() => setShowAdditionalDetails(false)}
      >
        <div style={{ overflow: 'scroll', maxHeight: '70vh' }}>
          <pre>{JSON.stringify(additionalDetails.AdditionalDetails, null, 2)}</pre>
        </div>
      </PopupWithCloseButton>
      <Form>
        <Row>
          <Col xs={4}>
            <FormGroup>
              <Label for="exampleDate">User</Label>
              <Input
                id="exampleSelect"
                name="select"
                type="select"
                value={selectedUser.FullName}
                disabled={userData.length === 0}
                onChange={handleUserSelection}
              >
                {userData.map((user) => {
                  const { FullName, pk_User } = user;
                  return (
                    <option key={pk_User} id={pk_User} value={FullName}>
                      {FullName}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label for="exampleDate">Candidate</Label>
              <Input
                id="exampleSelect"
                name="select"
                type="select"
                value={selectedCandidate.FullName}
                disabled={candidateData.length === 0}
                onSelect={handleCandidateSelection}
              >
                {candidateData.map((candidate) => {
                  const { FullName, pk_Candidate } = candidate;
                  return (
                    <option key={pk_Candidate} id={pk_Candidate} value={FullName}>
                      {FullName}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="exampleDate">Date</Label>
              <DatePicker
                value={startingDate}
                open={showDatePicker}
                placeholder="Select a date"
                valueFormat={{ month: 'short', year: 'numeric' }}
                valueEditFormat={{ dateStyle: 'short' }}
                valueDisplayFormat={{ dateStyle: 'medium' }}
                onToggle={() => {
                  setShowDatePicker((prevState) => !prevState);
                }}
                onClick={(e) => {
                  setShowDatePicker((prevState) => !prevState);
                }}
                onChange={(e) => {
                  setStartingDate(e);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Button color="primary" onClick={handleSearchClick}>
                Search
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
      <BootstrapTable
        bordered={true}
        columns={columns}
        data={history}
        rowStyle={(row, rowIndex) => {
          return { padding: 0 };
        }}
        keyField="field_key"
        noDataIndication={<div style={{ textAlign: 'center' }}>No Data</div>}
      />
    </div>
  );
};

export default DepartmentHistory;
