import { useState, useEffect } from 'react';

const useScrollToBottomDetection = (threshold = 10) => {
  const [isBottom, setIsBottom] = useState(false);
  const [scrollWrapper, setScrollWrapper] = useState(document.getElementById('scroll_wrapper'));

  useEffect(() => {}, []);

  const checkIfBottom = () => {
    const scrollHeight = scrollWrapper.scrollHeight;
    const clientHeight = scrollWrapper.clientHeight;
    const scrollTop = scrollWrapper.scrollTop;

    if (scrollTop + clientHeight >= scrollHeight - threshold) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  useEffect(() => {
    scrollWrapper.addEventListener('scroll', checkIfBottom);

    return () => {
      scrollWrapper.removeEventListener('scroll', checkIfBottom);
    };
  }, []);

  return isBottom;
};

export default useScrollToBottomDetection;
