import {
  faCalendarAlt,
  faCog,
  faEye,
  faEyeSlash,
  faL,
  faLock,
  faLockOpen,
  faPencilAlt,
  faPrint,
  faTag,
  faTags,
  faTimesCircle,
  faTrashAlt,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useCookies, withCookies } from 'react-cookie';
import {
  Button,
  Card,
  CardBody,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledTooltip,
  UncontrolledPopover,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { formatBearerToken, swalErrorMessage } from '../../../../Common.functions';
import {
  deleteData,
  fetchDataAgnostic,
  fetchDataAuth0,
  postDataAgnostic,
  postDataAgnosticWithoutCredentials,
  putData,
} from '../../../../Services/dataApi';
import { clone } from '../../../../Services/schedule';
import Schedule from '../../Coordinator/Schedule';
import PrintDropDown from '../../Coordinator/Schedule/TopBar/PrintDropDown/index';
import SettingsDropDown from '../../Coordinator/Schedule/TopBar/SettingsDropDown';
import '../AdminLanding.style.css';
import InterviewDatePopover from './InterviewDatePopover/InterviewDatePopover';
// import RegistrationLimitPopover from './RegistrationLimitPopover/RegistrationLimitPopover';
import RegistrationLimitPopover from './RegistrationLimitPopover2/RegistrationLimitPopover';

import style from './style';

import { useAlert } from 'react-alert';

import './AdminInterviewDates.style.css';
import Label from 'reactstrap/lib/Label';
import { setUserDepartment } from '../../Header/UserInformation/UserInformation.action';
import Loading from 'Body/Statuses/Loading';
import LinkSettingsButton from 'Body/RezRATE/Common/LinkSettingsButton/LinkSettingsButton';
import LimitInput from './LimitInput/LimitInput';

const WidgetStyle = {
  maxHeight: '600px',
  maxWidth: '350px',
  padding: '15px',
  border: '1px solid black',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const inputWithUnitWrapper = {
  display: 'flex',
  alignItems: 'center',
  p: {
    paddingLeft: '10px',
  },
};

const getTodayAtEight = () => {
  const rightNow = new Date(Date.now());
  rightNow.setHours(8, 0, 0, 0);
  return rightNow;
};

let typingTimeout = null;
let lastEditedField = null;
const AdminInterviewDates = ({
  dContext,
  token,
  activeDepartment,
  activeSeason,
  currentInterviewDates,
  formatThenSetInterviewDates,
  setSelectedScheduleItem,
  history,
}) => {
  const [tagChoices, setTagChoices] = useState([]);
  const [error, setError] = useState(false);
  const [activeSchedule, setActiveSchedule] = useState(null);
  const [selectableUsers, setSelectableUsers] = useState([]);
  const [inputRef, setInputRef] = useState(null);
  const [openPicker, setOpenPicker] = useState(false);
  const [showSettings, setShowSettings] = useState({});
  const [cookies, setCookies] = useCookies([]);
  const [showPastInterviewDates, setShowPastInterviewDates] = useState(
    cookies.showPastInterviewDates === 'false' ? 'false' : 'true',
  );
  const [filteredInterviewDates, setFilteredInterviewDates] = useState([]);
  const [departmentSettings, setDepartmentSettings] = useState({});
  const [tagFilterHasChanges, setTagFilterHasChanges] = useState(false);
  const [lockBeforeDays, setLockBeforeDays] = useState(0);
  const [isUpdatingLockBeforeDays, setIsUpdatingLockBeforeDays] = useState(false);
  const [lastField, setLastField] = useState();

  const showPrinter = useRef();
  const typeChangeRef = useRef();
  const alert = useAlert();

  showPrinter.current = {};

  const createTimeslotEntries = (numberOfRooms, startTime, activeSchedule) => {
    const timeSlotEntries = [];

    const numberOfTimeslots = Number(numberOfRooms) + 2;

    for (let i = 0; i < numberOfTimeslots; i++) {
      timeSlotEntries.push({ fk_InterviewDate: activeSchedule, SortOrder: i });
    }

    return timeSlotEntries;
  };

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const [today, setToday] = useState(moment(new Date(Date.now())));
  const [isUpdating, setIsUpdating] = useState();

  let totalInterviewDates = currentInterviewDates.length;
  let totalRegisteredCandidates = 0;
  let totalCandidateLimit = 0;

  currentInterviewDates.forEach((interviewDate) => {
    if (interviewDate.CountOfCandidates) {
      totalRegisteredCandidates += interviewDate.CountOfCandidates;
    }
    if (interviewDate.CandidateRegistrationLimit) {
      totalCandidateLimit += interviewDate.CandidateRegistrationLimit;
    }
  });

  const handleScheduleClick = (pk_InterviewDate) => {
    setActiveSchedule(pk_InterviewDate);
    getTokenSilently()
      .then(async (token) => {
        try {
          const entry = await fetchDataAuth0(
            'department/InterviewDateById',
            { pk_InterviewDate, pk_Department: activeDepartment.pk_Department },
            formatBearerToken(token),
          );
          if (entry.data && entry.data.Timeslots && entry.data.Timeslots.length > 0) {
            setSelectedScheduleItem(entry.data);
            history.push(`administrator/schedule/${entry.data.pk_InterviewDate}`);
          } else {
          }
        } catch (err) {
          alert.error('Something went wrong, please try again later or contact support.');
        }
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
    // setOpenOptions(true);
  };

  useEffect(() => {
    getActiveInterviewDates();
    // filterInterviewDates();
    if (cookies) {
      setShowPastInterviewDates(cookies.showPastInterviewDates === 'false' ? 'false' : 'true');
    }
    getDepartment();
    getTags();
  }, []);

  useEffect(() => {
    if (openPicker) {
    }
  }, [openPicker]);

  useEffect(() => {
    if (token && dContext) {
      getTags();
    }
  }, [token, dContext]);

  useEffect(() => {}, [showPrinter]);

  useEffect(() => {
    if (!activeDepartment || !activeSeason) return;

    const { pk_Department } = activeDepartment;
    const { pk_Season } = activeSeason;

    getTokenSilently()
      .then(async (token) => {
        try {
          const departmentUsers = await fetchDataAuth0('user/all', { pk_Department }, formatBearerToken(token));
          setSelectableUsers(departmentUsers.data);
        } catch (err) {
          setError(true);
        }

        try {
          const existingEntries = await fetchDataAuth0(
            'department/entries',
            { pk_Department, pk_Season },
            formatBearerToken(token),
          );
          formatThenSetInterviewDates(existingEntries.data);
        } catch (err) {
          setError(true);
        }
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  }, [activeDepartment, activeSeason]);

  useEffect(() => {
    if (currentInterviewDates) {
      filterInterviewDates();
    }
  }, [JSON.stringify(currentInterviewDates), showPastInterviewDates, departmentSettings]);

  useEffect(() => {
    if (currentInterviewDates && lastEditedField) {
    }
  }, [currentInterviewDates]);

  useEffect(() => {
    if (filteredInterviewDates && lastEditedField) {
      if (lastEditedField) {
        const id = lastEditedField;
        const lastField = document.getElementById(id);
        if (lastField) {
          lastField.focus();
          lastField.click();
        }

        lastEditedField = null;
        setLastField(null);
      }
    }
  }, [filteredInterviewDates, JSON.stringify(departmentSettings)]);

  const getActiveInterviewDates = (callback) => {
    getTokenSilently()
      .then((t) => {
        // For testing, creating app code for Matt's dept.
        // postDataAgnostic('department/application/code', { pk_Department: 50 }, null, formatBearerToken(t))
        //   .then((result) => {
        //     console.log('app code result: ', result);
        //   })
        //   .catch((err) => {
        //     console.log('err: ', err);
        //   });
        // department/application/opening
        // console.log('dContext: ', dContext);

        // for openings
        // postDataAgnostic(
        //   'department/application/opening',

        //   null,
        //   {
        //     pk_Department: dContext.department.pk_Department,
        //     fk_Season: dContext.season.pk_Season,
        //     DateAllowApplicantsStarts: '2024-07-20',
        //     DateAllowApplicantsEnds: '2025-12-30',
        //     LimitApplicantsCount: 50,
        //     Title: ' Opening Title 10-11 - 12-30',
        //     Description: 'Test Opening Description',
        //   },
        //   formatBearerToken(t),
        // )
        //   .then((result) => {
        //     console.log('create opening result: ', result);
        //   })
        //   .catch((err) => {
        //     console.log('create opening err: ', err);
        //   });
        fetchDataAuth0(
          'department/interviewDates',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(t),
        )
          .then((res) => {
            formatThenSetInterviewDates(res.data);
            if (callback) {
              callback(true);
            }
          })
          .catch((err) => {
            if (callback) {
              callback(false);
            }
          });
      })
      .catch((err) => {
        if (callback) {
          callback(false);
        }
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const saveDaysToLock = (newLockBeforeDays) => {
    setIsUpdatingLockBeforeDays(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/settings',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          {
            payload: {
              DaysWithinInterviewDateToLockDate: parseInt(newLockBeforeDays),
            },
          },
          formatBearerToken(token),
        )
          .then((res) => {
            getActiveInterviewDates();
            getDepartment();
            setTimeout(() => {
              setIsUpdatingLockBeforeDays(false);
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              setIsUpdatingLockBeforeDays(false);
            }, 1000);
          });
      })
      .catch((err) => {
        setIsUpdatingLockBeforeDays(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getDepartment = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/settings',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setDepartmentSettings(res.data[0]);
            if (res.data && res.data[0]) {
              setLockBeforeDays(res.data[0].DaysWithinInterviewDateToLockDate);
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const filterInterviewDates = () => {
    const todayDate = moment.tz().format('MMM DD, YYYY');
    const todayDateToCompare = moment.tz(`${todayDate} 23:59`, null);

    // console.log('todayDateToCompare: ', todayDateToCompare.format('MMM DD, YYYY'));
    const newFilteredInterviewDates = currentInterviewDates.slice().filter((interviewDate) => {
      const { DateOfInterview, StartTime, Label } = interviewDate;

      // turn intervew date to moment with department's timezone, then convert to wherever user is.
      // const newInterviewDate = moment
      //   .tz(`${interviewDate.DateOfInterview} 23:59`, departmentSettings.TimeZone)
      //   .tz(moment.tz.guess());

      // const dateInThisTimeZone = moment
      //   .tz(`${DateOfInterview} ${StartTime}`, departmentSettings.TimeZone)
      //   .tz(moment.tz.guess());

      const dateInThisTimeZone = moment
        .tz(`${DateOfInterview} 23:59`, departmentSettings.TimeZone)
        .tz(moment.tz.guess());

      const dateToCompare = moment.tz(`${dateInThisTimeZone.format('MMM DD,  YYYY')} 23:59`, null);

      // console.log('dateToCompare: ', dateToCompare.format('MMM DD, YYYY'), '(', Label, ')');

      const isNotPastDate = dateToCompare.isSameOrAfter(todayDateToCompare, 'd');

      return (
        showPastInterviewDates == 'true' ||
        ((showPastInterviewDates == 'false' || !showPastInterviewDates) &&
          // interviewDate.MomentDateOfInterview.isSameOrAfter(today, 'd'))
          dateToCompare.isSameOrAfter(todayDateToCompare, 'd'))
        // dateToCompare.isSameOrAfter(today, 'd'))
      );
    });

    newFilteredInterviewDates.push({
      pk_InterviewDate: 'summary_row',
      MomentDateOfInterview: totalInterviewDates,
      CountOfCandidates: totalRegisteredCandidates,
      CandidateRegistrationLimit: totalCandidateLimit,
    });

    setFilteredInterviewDates(newFilteredInterviewDates);
  };

  const updateEntry = (entry, entryId, callback) => {
    // Need to put Tag Filter here. If date has Tag Filter, Tag Filter should be passed ALWAYS, otherwise,
    // back end would strip it.
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/interviewDate/putEntry',
          {
            pk_Department: activeDepartment.pk_Department,
            pk_InterviewDate: entryId,
            pk_Season: dContext.season.pk_Season,
          },
          { entry },
          formatBearerToken(token),
        )
          .then((result) => {
            setIsUpdating(false);
            if (result.data.error) {
              swalErrorMessage();
              return;
            }
            getActiveInterviewDates(callback);
            typeChangeRef.current = null;
            lastEditedField = null;
            setLastField(null);
            // formatThenSetInterviewDates(result.data);
          })
          .catch((err) => {
            callback(false);
            lastEditedField = null;
            setLastField(null);
            setIsUpdating(false);
            swalErrorMessage();
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const deleteEntry = (entryId) => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/deleteEntry',
          null,
          { entryId, departmentId: activeDepartment.pk_Department },
          formatBearerToken(token),
        )
          .then((result) => {
            setIsUpdating(false);
            if (result.data.error) {
              swalErrorMessage();
              return;
            }

            alert.success('Interview Date deleted!');

            getActiveInterviewDates();
            //formatThenSetInterviewDates(result.data);
          })
          .catch((err) => {
            setIsUpdating(false);
            swalErrorMessage();
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const addDate = (data) => {
    const { selectedDate, Label = '' } = data;

    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/createEntry',
          null,
          {
            departmentId: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            entry: { selectedDate, Label: Label },
          },
          formatBearerToken(token),
        )
          .then((result) => {
            setOpenPicker(false);
            setIsUpdating(false);
            if (result.data.error) {
              swalErrorMessage();
              return;
            }
            alert.success('Session Added!');
            getActiveInterviewDates();
            // formatThenSetInterviewDates(result.data);
          })
          .catch((err) => {
            setIsUpdating(false);
            swalErrorMessage();
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getTags = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/tags',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            setTagChoices(res.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const headerFormatter = (column, colIndex) => {
    if (colIndex == 0) {
      return <div style={style.headerStyle}>{column.text}</div>;
    }

    return (
      <div style={style.headerStyle} id={`${column.text}`}>
        {`${column.text} `} {renderHeaderTooltip(column)}
        {colIndex == 1 ? (
          <Button
            color="success"
            size="sm"
            style={{ marginLeft: 10 }}
            id={'addInterviewDateBtn'}
            onClick={() => {
              setOpenPicker(!openPicker);
              const newSettings = clone(showSettings);
              Object.keys(newSettings).forEach((key) => {
                newSettings[key] = false;
              });
              setShowSettings(clone(newSettings));
            }}
          >
            Add
          </Button>
        ) : null}
        {colIndex == 1 ? renderHeaderDatePopover() : null}
      </div>
    );
  };

  const renderHeaderTooltip = (column) => {
    switch (column.text) {
      case 'Registered':
        return (
          <UncontrolledTooltip target={`${column.text}`} placement="top">
            Indicates the number of candidates currently registered to interview on a session.
          </UncontrolledTooltip>
        );

      case 'Limit':
        return (
          <UncontrolledTooltip target={`${column.text}`} placement="top">
            Indicates the number of candidates allowed to register for a session.
          </UncontrolledTooltip>
        );

      case 'Waitlisted':
        return (
          <UncontrolledTooltip target={`${column.text}`} placement="top">
            Indicates the number of candidates currently waitlisted for a session
          </UncontrolledTooltip>
        );
      default:
        return null;
    }
  };

  const toggleModal = (key) => {
    // const key = `date_btn_${rowIndex}`;
    const newSettings = clone(showSettings);
    Object.keys(newSettings).forEach((rawKey) => {
      if (rawKey !== key) {
        newSettings[rawKey] = false;
      }
    });
    newSettings[key] = !newSettings[key];
    setShowSettings(newSettings);
  };

  const renderLimitInput = (options) => {
    const { cell, row, rowIndex, formatExtraData, renderType = 1 } = options;

    const {
      EnableHiddenStatus,
      EnableLockedStatus,
      MomentDateOfInterview,
      pk_InterviewDate,
      CannotBeUnlocked,
      CandidateRegistrationLimit,
      CountOfCandidates,
      DateOfInterview,
      StartTime,
      pk_Tag,
      Tag,
    } = row;

    const allowTagFiltering = departmentSettings && departmentSettings.EnableTagFiltering; // get from department settings

    const inputKey = `registration_btn_${pk_InterviewDate}`;
    let countToDisplay = CandidateRegistrationLimit;
    if (row.tags && allowTagFiltering) {
      row.tags.forEach((tag) => {
        countToDisplay += tag.InterviewDateLimit;
      });
    }

    if (renderType == 1) {
      return (
        <div style={style.cellStyle}>
          <InputGroup>
            <Input
              disabled={isUpdating || (lastField != null && lastField !== inputKey)}
              onFocus={(e) => {
                if (allowTagFiltering) {
                  e.target.blur();
                }
              }}
              id={inputKey}
              key={`key_${inputKey}_${allowTagFiltering ? countToDisplay : cell || 0}`}
              type={allowTagFiltering ? 'text' : 'number'}
              // value={allowTagFiltering ? countToDisplay : cell || 0}
              defaultValue={allowTagFiltering ? countToDisplay : cell || 0}
              style={{
                textAlign: 'center',
                marginRight: allowTagFiltering ? -46 : 0,
                fontSize: 12,
                backgroundColor:
                  countToDisplay == CountOfCandidates || (countToDisplay == null && CountOfCandidates == 0)
                    ? '#88ff82'
                    : CountOfCandidates > countToDisplay
                    ? '#ffc180'
                    : null,
              }}
              onClick={(e) => {
                if (allowTagFiltering) {
                  e.target.blur();
                }

                if (isUpdating) {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                if (allowTagFiltering || isUpdating) {
                  return;
                }

                if (typingTimeout) {
                  clearTimeout(typingTimeout);
                  typingTimeout = null;
                }
                const newValue = parseInt(e.target.value);

                if (typeChangeRef && typeChangeRef.current) {
                  typeChangeRef.current.CandidateRegistrationLimit = newValue >= 0 ? newValue : 0;
                } else {
                  typeChangeRef.current = { CandidateRegistrationLimit: newValue >= 0 ? newValue : 0 };
                }

                setLastField(inputKey);
                lastEditedField = inputKey; // `registration_btn_${rowIndex}`;
                typingTimeout = setTimeout(() => {
                  updateEntry(typeChangeRef.current, pk_InterviewDate);
                }, 1000);
              }}
            />
            {allowTagFiltering ? (
              <InputGroupAddon addonType="append">
                <Button
                  size="sm"
                  id={`registration_btn_${rowIndex}`}
                  style={{ zIndex: 0 }}
                  color="secondary"
                  onClick={() => {
                    const key = `registration_btn_${rowIndex}`;
                    const newSettings = clone(showSettings);
                    newSettings[key] = !newSettings[key];
                    setShowSettings(newSettings);
                  }}
                >
                  <FontAwesomeIcon icon={faTags} />
                </Button>
              </InputGroupAddon>
            ) : null}

            {allowTagFiltering ? renderRegistrationLimitPopover({ ...row, rowIndex }) : null}
          </InputGroup>
        </div>
      );
    } else if (renderType == 2) {
      return (
        <LimitInput
          {...options}
          departmentSettings={departmentSettings}
          isUpdating={isUpdating}
          lastField={lastField}
          setLastField={setLastField}
          typingTimeout={typingTimeout}
          updateEntry={updateEntry}
          showSettings={showSettings}
          setShowSettings={setShowSettings}
          renderRegistrationLimitPopover={renderRegistrationLimitPopover}
          typeChangeRef={typeChangeRef}
          clearTypeTimeout={() => {
            clearTimeout(typingTimeout);
          }}
        />
      );
    }
  };

  const cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    const {
      EnableHiddenStatus,
      EnableLockedStatus,
      MomentDateOfInterview,
      pk_InterviewDate,
      CannotBeUnlocked,
      CandidateRegistrationLimit,
      CountOfCandidates,
      DateOfInterview,
      StartTime,
      ScheduleBuildingHasBeenStarted,
    } = row;

    const dateInThisTimeZone = moment
      .tz(`${DateOfInterview} ${StartTime}`, departmentSettings.TimeZone)
      .tz(moment.tz.guess());

    const dateToCompare = moment.tz(`${dateInThisTimeZone.format('MMM DD,  YYYY')} 23:59`, null);

    const isPastInterviewDate = !dateToCompare.isSameOrAfter(today, 'd');

    if (rowIndex >= filteredInterviewDates.length - 1 || filteredInterviewDates.length == 1) {
      if (formatExtraData.label === 'Delete') {
        return (
          <div style={{ ...style.cellStyle, fontSize: 13 }}>
            <b>SEASON TOTAL</b>
          </div>
        );
      }
      if (formatExtraData.label === 'Registered') {
        return (
          <div style={{ ...style.cellStyle, fontWeight: 'bold' }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push(`/administrator/candidates/sendmessages?filterBy=Scheduled`);
              }}
            >
              {cell || 0}
            </div>
          </div>
        );
      }
      if (formatExtraData.label === 'Settings') {
        return (
          <div style={{ ...style.cellStyle, width: '100%' }}>
            <div style={{ ...style.simpleColumn, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <Label
                for="lockDate"
                style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 0, justifyContent: 'center' }}
              >
                Lock{' '}
                <Input
                  disabled={isUpdatingLockBeforeDays}
                  id="lockDate"
                  type={'number'}
                  value={lockBeforeDays}
                  style={{
                    display: 'inline',
                    fontSize: 13,
                    width: 60,
                  }}
                  onChange={(e) => {
                    let newValue = parseInt(e.target.value || 0);

                    if (newValue < -1) {
                      newValue = -1;
                    }

                    if (newValue > 99) {
                      newValue = 99;
                    }

                    setLockBeforeDays(newValue);
                    const valueToSave = newValue;
                    lastEditedField = 'lockDate';
                    if (valueToSave != null) {
                      if (typingTimeout) {
                        clearTimeout(typingTimeout);
                      }

                      typingTimeout = setTimeout(() => {
                        saveDaysToLock(valueToSave);
                      }, 1000);
                    }
                  }}
                />{' '}
                days before
              </Label>
            </div>
          </div>
        );
      }
      return (
        <div style={{ ...style.cellStyle, fontSize: 14 }}>
          <b>{cell}</b>
        </div>
      );
    } else {
      switch (formatExtraData.label) {
        case 'Delete':
          return (
            <div style={style.cellStyle}>
              <div id={`delete_${row.pk_InterviewDate}`}>
                <Button
                  size="sm"
                  disabled={
                    isUpdating || (row.CountOfCandidates && row.CountOfCandidates > 0)
                      ? true
                      : false /*|| row.CannotBeUnlocked*/
                  }
                  color={row.CountOfCandidates && row.CountOfCandidates > 0 ? 'secondary' : 'danger'}
                  onClick={() => {
                    Swal.fire({
                      text: 'Are you sure you want to delete this interview date?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!',
                    }).then((result) => {
                      if (result.value) {
                        deleteEntry(pk_InterviewDate);
                      }
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </div>

              {row.CountOfCandidates && row.CountOfCandidates > 0 ? (
                <UncontrolledTooltip target={`delete_${row.pk_InterviewDate}`} placement={'top'}>
                  Unable to delete Interview dates with registered candidates!
                </UncontrolledTooltip>
              ) : null}
            </div>
          );
        case 'Visibility':
          return (
            <div style={style.cellStyle}>
              <Button
                size="sm"
                id={`visibility_${row.pk_InterviewDate}`}
                disabled={isUpdating || CannotBeUnlocked}
                color={CannotBeUnlocked ? 'secondary' : EnableHiddenStatus ? 'danger' : 'success'}
                onClick={() => {
                  updateEntry({ EnableHiddenStatus: !EnableHiddenStatus }, pk_InterviewDate);
                }}
              >
                <FontAwesomeIcon icon={EnableHiddenStatus || CannotBeUnlocked ? faEyeSlash : faEye} />
              </Button>
              {row.CannotBeUnlocked ? (
                <UncontrolledTooltip target={`visibility_${row.pk_InterviewDate}`} placement={'top'}>
                  Session is in the past. You cannot modify hide status.
                </UncontrolledTooltip>
              ) : null}
            </div>
          );
        case 'Lock':
          return (
            <div style={style.cellStyle}>
              <Button
                id={`lock_${row.pk_InterviewDate}`}
                disabled={isUpdating || CannotBeUnlocked}
                color={CannotBeUnlocked || EnableLockedStatus ? 'secondary' : EnableLockedStatus ? 'danger' : 'success'}
                onClick={() => {
                  updateEntry({ EnableLockedStatus: !EnableLockedStatus }, pk_InterviewDate);
                }}
              >
                <FontAwesomeIcon icon={EnableLockedStatus || CannotBeUnlocked ? faLock : faLockOpen} />
              </Button>
              {row.CannotBeUnlocked ? (
                <UncontrolledTooltip target={`lock_${row.pk_InterviewDate}`} placement={'top'}>
                  Session is in the past. You cannot modify hide/lock status.
                </UncontrolledTooltip>
              ) : null}
            </div>
          );
        case 'Candidates':
          return (
            <div style={style.cellStyle}>
              <Button
                color="secondary"
                disabled={isUpdating}
                onClick={() => {
                  history.push(`/administrator/candidates/sendmessages?pk_InterviewDate=${row.pk_InterviewDate}`);
                }}
              >
                <FontAwesomeIcon icon={faUsers} />
              </Button>
            </div>
          );
        case 'Settings':
          return (
            <div style={style.cellStyle}>
              <div id={`visibility_${row.pk_InterviewDate}`}>
                <Button
                  size="sm"
                  disabled={isUpdating || CannotBeUnlocked || isPastInterviewDate}
                  color={
                    CannotBeUnlocked || isPastInterviewDate ? 'secondary' : EnableHiddenStatus ? 'danger' : 'success'
                  }
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    updateEntry({ EnableHiddenStatus: !EnableHiddenStatus }, pk_InterviewDate);
                  }}
                >
                  <FontAwesomeIcon
                    icon={EnableHiddenStatus || CannotBeUnlocked || isPastInterviewDate ? faEyeSlash : faEye}
                  />
                </Button>
              </div>

              {CannotBeUnlocked || isPastInterviewDate ? (
                <UncontrolledTooltip target={`visibility_${row.pk_InterviewDate}`} placement={'top'}>
                  Session {isPastInterviewDate ? `is in the past` : ' cannot be unlocked'}. You cannot modify its hide
                  status.
                </UncontrolledTooltip>
              ) : null}

              <div id={`lock_${row.pk_InterviewDate}`}>
                <Button
                  size="sm"
                  disabled={isUpdating || CannotBeUnlocked || isPastInterviewDate}
                  color={
                    CannotBeUnlocked || EnableLockedStatus || isPastInterviewDate
                      ? // ? 'secondary'
                        // : EnableLockedStatus
                        'danger'
                      : 'success'
                  }
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    updateEntry({ EnableLockedStatus: !EnableLockedStatus }, pk_InterviewDate);
                  }}
                >
                  <FontAwesomeIcon
                    icon={EnableLockedStatus || CannotBeUnlocked || isPastInterviewDate ? faLock : faLockOpen}
                  />
                </Button>
              </div>

              {CannotBeUnlocked || isPastInterviewDate ? (
                <UncontrolledTooltip target={`lock_${row.pk_InterviewDate}`} placement={'top'}>
                  Session {isPastInterviewDate ? `is in the past` : ' cannot be unlocked'}. You cannot modify its lock
                  status.
                </UncontrolledTooltip>
              ) : null}

              <LinkSettingsButton
                id={`settings_btn_${rowIndex}`}
                // btnSize={'lg'}
                marginRight={0}
                MainRoomURL={row.MainRoomURL}
                MainRoomURLDetails={row.MainRoomURLDetails}
                scheduleDate={row.MomentDateOfInterview}
                scheduleData={row}
                scheduleID={row.pk_InterviewDate}
                dContext={dContext}
                btnWidth={34}
                onUpdate={getActiveInterviewDates}
              />
              {/* <Button color="secondary" id={`settings_btn_${rowIndex}`} disabled={isUpdating}>
                <FontAwesomeIcon icon={faCog} />
              </Button> */}
              {/* {renderSettingsPopOver({ ...row, rowIndex })} */}
            </div>
          );
        case 'Schedule':
          return (
            <div style={style.cellStyle}>
              <Button
                id={`calendar_${row.pk_InterviewDate}`}
                size="sm"
                disabled={isUpdating}
                color={ScheduleBuildingHasBeenStarted ? 'success' : 'secondary'}
                onClick={() => {
                  handleScheduleClick(pk_InterviewDate);
                }}
              >
                <FontAwesomeIcon icon={faCalendarAlt} />
              </Button>
              <UncontrolledTooltip target={`calendar_${row.pk_InterviewDate}`} placement={'top'}>
                {ScheduleBuildingHasBeenStarted ? 'Modify Schedule' : 'Start Building Schedule'}
              </UncontrolledTooltip>
            </div>
          );
        case 'Limit':
          return renderLimitInput({ cell, row, rowIndex, formatExtraData, renderType: 2 });
        case 'Print Materials':
          const key = `printer_btn_${rowIndex}`;
          return (
            <div style={style.cellStyle}>
              <Button
                size="sm"
                disabled={isUpdating}
                id={key}
                onClick={() => {
                  const newSettings = clone(showPrinter.current);
                  newSettings[key] = showPrinter.current[key] != null ? showPrinter.current[key] : true;
                  const newCurrent = showPrinter.current;
                  newCurrent[key] = newSettings[key];
                  showPrinter.current = { ...newCurrent };
                  showPrinter.current.towad = 'MEOW';
                  setShowSettings(newSettings);
                }}
              >
                <FontAwesomeIcon icon={faPrint} />
              </Button>
              {renderPrintPopover({ ...row, rowIndex })}
            </div>
          );
        case 'Waitlisted':
          return (
            <div style={{ ...style.cellStyle, fontWeight: 'bold' }}>
              <div
                style={{ cursor: 'pointer', color: '#007bff' }}
                onClick={() => {
                  history.push(`/administrator/communications/waitlist?pk_InterviewDate=${row.pk_InterviewDate}`);
                }}
              >
                {cell || 0}
              </div>
            </div>
          );
        case 'Interview Sessions':
          const dateInputKey = `date_btn_${pk_InterviewDate}_${moment.tz(cell, null).format('MMM_DD_YYYY')}`;

          return (
            <div style={style.cellStyle}>
              <div style={style.simpleColumn}>
                <div style={style.simpleRow}>
                  <InputGroup>
                    <Input
                      id={dateInputKey}
                      key={`${dateInputKey}_${row.Label ? row.Label.replace(/[^a-zA-Z0-9]/g, '') : ''}`}
                      type={row.Label ? 'textarea' : 'text'}
                      rows={2}
                      onFocus={(e) => {
                        e.target.blur();
                      }}
                      defaultValue={`${moment.tz(cell, null).format('MMM. DD, YYYY (ddd)')} \n${row.Label || ' '}`}
                      style={{
                        textAlign: 'left',
                        height: row.Label ? null : 52,
                        flexWrap: 'wrap',
                        resize: 'none',
                        fontSize: 13,
                      }}
                      onClick={(e) => {
                        e.target.blur();

                        toggleModal(dateInputKey);
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        size="sm"
                        style={{ zIndex: 0 }}
                        color="secondary"
                        onClick={() => {
                          toggleModal(dateInputKey);
                          setOpenPicker(false);
                        }}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  {renderDatePopover({ ...row, rowIndex, cell })}
                </div>
                {/* <div style={style.simpleRow}>
                  <div style={style.centeredText}>{row.Label}</div>
                </div> */}
              </div>
            </div>
          );
        case 'Registered':
          return (
            <div style={{ ...style.cellStyle, fontWeight: 'bold' }}>
              <div
                style={{ cursor: 'pointer', color: '#007bff' }}
                onClick={() => {
                  history.push(`/administrator/candidates/sendmessages?pk_InterviewDate=${row.pk_InterviewDate}`);
                }}
              >
                {cell || 0}
              </div>
            </div>
          );
        default:
          return <div style={style.cellStyle}> {cell || 0}</div>;
      }
    }
  };

  const makeColumns = () => {
    const headers = [
      { text: 'Delete', dataField: 'delete_placeholder', minWidth: 100 },
      { text: 'Interview Sessions', dataField: 'MomentDateOfInterview', minWidth: 250 },
      { text: 'Registered', dataField: 'CountOfCandidates', minWidth: 120 },
      { text: 'Waitlisted', dataField: 'NumberOfWaitlistedCandidates', minWidth: 120 },
      { text: 'Limit', dataField: 'CandidateRegistrationLimit', minWidth: 100 },
      // { text: 'Visibility', dataField: 'EnableHiddenStatus' },
      // { text: 'Lock', dataField: 'EnableLockedStatus' },
      { text: 'Settings', dataField: 'settings_placeholder', minWidth: 170 },
      // { text: 'Candidates', dataField: 'candidates_placeholder' },
      { text: 'Print Materials', dataField: 'print_materials_placeholder', minWidth: 140 },
      { text: 'Schedule', dataField: 'schedule_placeholder', minWidth: 100 },
    ];
    const newColumns = [];

    headers.forEach((header) => {
      const { minWidth } = header;

      newColumns.push({
        dataField: header.dataField,
        text: header.text,
        label: header.text,
        formatter: cellFormatter,
        headerFormatter: headerFormatter,
        // classes: 'timeSlot',
        style: (cell, row, rowIndex, colIndex) => {
          return {
            verticalAlign: 'middle',
            padding: rowIndex < filteredInterviewDates.length - 1 ? 0 : 5,
            cursor: header.dataField === 'CountOfCandidates' ? 'pointer' : 'normal',
          };
        },
        headerStyle: {
          top: -2,
          position: 'sticky',
          verticalAlign: 'middle',
          backgroundColor: 'white',
          zIndex: 3,
          padding: 20,
          minWidth,
          width: minWidth,
        },
        // isTime: true,
        formatExtraData: { label: header.text },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {},
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {},
        },
        headerAttrs: {
          // id: 'timeSlotHeader',
        },
        attrs: (cell, row, rowIndex, colIndex) => {
          // return { id: `timeSlot_${row.pk_Timeslot}` };
        },
      });
    });

    return newColumns;
  };

  const renderDatePopover = (row) => {
    const { CountOfCandidates, pk_InterviewDate } = row;
    const key = `date_btn_${pk_InterviewDate}_${moment.tz(row.cell, null).format('MMM_DD_YYYY')}`;

    // _${
    //   row.Label ? row.Label.replace(/[^a-zA-Z0-9]/g, '') : ''

    return (
      <Popover
        flip={false}
        popperClassName=""
        isOpen={showSettings[key]}
        target={key}
        trigger="click"
        toggle={() => {
          const newSettings = clone(showSettings);
          if (newSettings[key]) {
            newSettings[key] = !newSettings[key];
            setShowSettings(newSettings);
          }
        }}
      >
        <PopoverHeader>
          <div style={style.popOverHeader}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              Interview Date
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  const newSettings = clone(showSettings);
                  newSettings[key] = !newSettings[key];
                  setShowSettings(newSettings);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody style={style.popOverBody}>
          <InterviewDatePopover
            setShowPopover={(newValue) => {
              const newSettings = clone(showSettings);
              newSettings[key] = newValue;
              setShowSettings(newSettings);
            }}
            onUpdate={() => {
              alert.success('Interview Date updated!');
              getActiveInterviewDates();
              const newSettings = clone(showSettings);
              if (newSettings[key]) {
                newSettings[key] = !newSettings[key];
                setShowSettings(newSettings);
              }
            }}
            interviewDate={row}
            token={token}
            dContext={dContext}
            CountOfCandidates={CountOfCandidates}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderHeaderDatePopover = () => {
    return (
      <Popover
        flip={false}
        popperClassName=""
        isOpen={openPicker}
        target={'addInterviewDateBtn'}
        trigger="legacy"
        onOpen={() => {
          setTimeout(() => {
            document.getElementById('date_btn_undefined_actual').click();
          }, 1000);
        }}
        toggle={() => {}}
      >
        <PopoverHeader>
          <div style={style.popOverHeader}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              Add Interview Session
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenPicker(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody style={style.popOverBody}>
          <InterviewDatePopover
            setShowPopover={(newValue) => {
              setOpenPicker(newValue);
            }}
            onAdd={(date) => {
              addDate(date);
            }}
            token={token}
            dContext={dContext}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderRegistrationLimitPopover = (row) => {
    const key = `registration_btn_${row.rowIndex}`;
    return (
      <Popover
        flip={false}
        placementPrefix="registrationLimitsPopover bs-popover"
        isOpen={showSettings[key]}
        target={key}
        trigger="legacy"
        toggle={(e) => {
          if (e.target.className.includes && e.target.className.includes('swal2')) {
            return;
          }
          if (tagFilterHasChanges) {
            e.stopPropagation();
            e.preventDefault();
            Swal.fire({
              closeOnClickOutside: false,
              text:
                'You have unsaved changes in the Tag Filter. Please save or discard your changes before continuing.',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Discard Changes',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (result.value) {
                const newSettings = clone(showSettings);
                newSettings[key] = false;
                setShowSettings(newSettings);

                setTagFilterHasChanges(false);
                // const newSettings = clone(showSettings);
                // newSettings[key] = !newSettings[key];
                // setShowSettings(newSettings);
              } else {
                return;
              }
            });
            return;
          }

          if (e.target.id !== 'infoBody1' && e.target.id !== 'infoBody2' && e.target.id !== 'infoHeader') {
            const newSettings = clone(showSettings);
            newSettings[key] = !newSettings[key];
            setShowSettings(newSettings);
          }
        }}
      >
        <PopoverHeader>
          <div style={style.popOverHeader}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* {row.MomentDateOfInterview.format('MMM DD, YYYY')}{' '} */}
              Limit Session Visibility by Tag
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  const newSettings = clone(showSettings);
                  newSettings[key] = !newSettings[key];
                  setShowSettings(newSettings);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody style={style.popOverBody}>
          <div>
            <RegistrationLimitPopover
              setShowSettings={(value) => {
                const newSettings = clone(showSettings);
                newSettings[key] = value;
                setShowSettings(newSettings);
              }}
              getActiveInterviewDates={getActiveInterviewDates}
              isUpdating={isUpdating}
              updateEntry={updateEntry}
              tags={tagChoices}
              data={row}
              token={token}
              dContext={dContext}
              setTagFilterHasChanges={setTagFilterHasChanges}
            />
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const renderPrintPopover = (row) => {
    const key = `printer_btn_${row.rowIndex}`;

    return (
      <Popover
        flip={false} // popperClassName="adminPrintPopover"
        placementPrefix="adminPrintPopover bs-popover"
        isOpen={showSettings[key]}
        target={key}
        trigger="legacy"
        placement="left"
        toggle={(e) => {
          // if (e.target.id == key) {
          //   const newSettings = clone(showPrinter);
          //   Object.keys((oldKey) => {
          //     if (oldKey === key) {
          //       newSettings[key] = !showPrinter[key];
          //     } else {
          //       newSettings[key] = false;
          //     }
          //   });
          //   setShowSettings(newSettings);
          //   showPrinter[key] = !showPrinter[key];
          // }
        }}
      >
        <PopoverHeader>
          <div style={style.popOverHeader}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              Print Materials for {row.MomentDateOfInterview.format('MMM DD, YYYY')} {row.Label ? `(${row.Label})` : ''}
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  const newSettings = clone(showSettings);
                  newSettings[key] = !newSettings[key];
                  setShowSettings(newSettings);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <PrintDropDown
              row={row}
              setShowSettings={(value) => {
                const newSettings = clone(showSettings);
                newSettings[key] = value;
                setShowSettings(newSettings);
              }}
            />
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const { EnableAutoSlotState } = departmentSettings || {};

  return (
    <div>
      <div style={{ ...style.hideInterviewDatesContainer }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CustomInput
            type="switch"
            label="Show past Interview dates"
            id="hidePast"
            name="hidePast"
            checked={showPastInterviewDates == 'false' ? false : true}
            onChange={() => {
              const newValue = showPastInterviewDates == 'true' ? 'false' : 'true';
              setCookies('showPastInterviewDates', newValue, { path: '/' });
              setShowPastInterviewDates(newValue);
              filterInterviewDates();
            }}
          />
        </div>
        <div
          id="autoRegistration_info"
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontStyle: 'italic' }}
        >
          <span>
            Auto Registration is:{' '}
            <span
              style={{
                fontWeight: 'bold',
                cursor: 'pointer',
                color: EnableAutoSlotState === undefined ? null : EnableAutoSlotState ? 'green' : 'red',
              }}
              onClick={() => {
                history.push('/administrator/settings/communication');
              }}
            >
              {EnableAutoSlotState === undefined ? '----' : EnableAutoSlotState ? 'Enabled' : 'Disabled'}
            </span>{' '}
          </span>
          {EnableAutoSlotState != null ? (
            <UncontrolledTooltip target="autoRegistration_info">
              {EnableAutoSlotState
                ? 'Candidates will automatically register for interview sessions.'
                : 'Candidates will not automatically register for interview sessions.'}
            </UncontrolledTooltip>
          ) : null}
        </div>
      </div>
      <div style={{ height: '70vh', overflowY: 'scroll' }}>
        <BootstrapTable
          bordered={true}
          condensed={true}
          columns={makeColumns()}
          data={filteredInterviewDates}
          rowStyle={(row, rowIndex) => {
            if (rowIndex >= filteredInterviewDates.length - 1 || filteredInterviewDates.length == 1) {
              return { position: 'sticky', bottom: -2, backgroundColor: 'white' };
            }
          }}
          keyField="pk_InterviewDate"
          noDataIndication={<div style={{ textAlign: 'center' }}>No Data</div>}
        />
      </div>

      {/* {renderSummaryFooter()} */}
    </div>
  );
};

// add filter days in the past
// add locked button if dates past
// if it's hidden show it as red locked

export default withCookies(AdminInterviewDates);
