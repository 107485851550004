import {
  faCoffee,
  faHandshake,
  faSpinner,
  faUtensils,
  faUser,
  faUsers,
  faBan,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { useAlert, Provider as AlertProvider } from 'react-alert';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import {
  getPercentageTime,
  urlWithHttpProtocol,
  shouldJoinMeetingButtonBeVisisble,
  determineProgress,
  checkForScheduleDisabledButton,
  isNotificationsSupported,
  checkIfMeetingIsBeforeCurrentJoinedMeeting,
} from '../../../../Common.functions.js';
import { Circle } from '../../../../Common.jsx.js';
import { renderAvatar } from './Common.js';
import { checkForSound } from './ScheduleAlerts.js';
import ScheduleItem from './ScheduleItem.js';
import BellSound from '../../../../assets/sounds/shipbell.wav';
import NotifAlertTemplate from '../../Common/NotifAlertTemplate/NotifAlertTemplate';
import { clone } from '../../../../Services/schedule';
import { DepartmentContext } from 'DepartmentWrapper.js';
let tableRowOpacity = 0;

const diff_minutes = (dt2, dt1) => {
  var diff = (dt2.getTime() - dt1.valueOf()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

const replaceWithNumber = '|###|';

const Schedule = ({
  activeTab,
  alarms,
  allDayRoom,
  changeToCandidateTab,
  candidateInfo = [],
  date,
  differences,
  disableJoinMeetingButton,
  firstEvent,
  firstBlockEarlyJoin,
  getCandidate,
  handleDateChange,
  handleZoomClick,
  indexesWithDifferences,
  informUserMeetingIsStartingLocally,
  interviewIsToday,
  isEmbedded,
  isMobile,
  match,
  postRequest,
  reduxDate,
  requestVirtualMeetingEvaluator,
  requestZoomMeeting,
  schedule,
  Schedules4Eval__pkUUID_Schedule,
  Schedules4EvalJoinManualURL,
  selectedCandidateIdLocal,
  selectedUser,
  setAlarms,
  setDate,
  setDisableJoinMeetingButton,
  setMyDate,
  setSchedules4Eval__pkUUID_Schedule,
  setSchedules4EvalJoinManualURL,
  setSelectedTimeSlot,
  selectedTimeSlot,
  setShowRed,
  setUserIdOnCurrentInterview,
  showRed,
  userIdOnCurrentInterview,
  setSelectedCandidate,
  virtualMeetingMode, // This is VirtualMeetingMode from the schedule metaData, not the dContext. dContext can be outdated.
}) => {
  // React.useEffect(() => {
  //   var timerID = setInterval(() => tick(), 1000);
  //   return function cleanup() {
  //     clearInterval(timerID);
  //   };
  // });

  const [alertedEvents, setAlertedEvents] = useState([]);
  const [chosenCandidate, setChosenCandidate] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentTimeAsMoment, setCurrentTimeAsMoment] = useState(moment(new Date()));
  const [displayLocalNotification, setDisplayLocalNotification] = useState(true);
  const [heights, setHeights] = useState([]);
  const [preventJoinMeetingClick, setPreventJoinMeetingClick] = useState(false);
  const [scheduledTimes, setScheduledTimes] = useState(null);
  const [scheduleWithPassingPeriods, setScheduleWithPassingPeriods] = useState({});

  const scheduleActual = useRef([]);
  const alarmsActual = useRef([]);

  const dContext = useContext(DepartmentContext);
  const alert = useAlert();

  useEffect(() => {
    getAlertedEvents();
    if (isNotificationsSupported()) {
      Notification.requestPermission();
    }

    let audio = new Audio(BellSound);
    audio.volume = 0.0;
    audio
      .play()
      .then(() => {
        console.log('wakeup 0 volume played fine');
      })
      .catch((err) => {
        console.log('wakeup 0 volume err: ', err);
      });

    var timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
    // return function cleanup() {
    //   clearInterval(timerID);
    // };
  }, []);

  useEffect(() => {
    if (alarms) {
      alarmsActual.current = alarms || [];
    }
  }, [alarms]);

  useEffect(() => {
    if (schedule) {
      processSchedulesWithPassingPeriod(schedule);
      scheduleActual.current = schedule || [];
    }
  }, [schedule]);

  useEffect(() => {
    if (alertedEvents && alertedEvents.length > 0) {
      localStorage.setItem('evaluator_alertedEvents', JSON.stringify(alertedEvents));
    }
  }, [alertedEvents]);

  const processSchedulesWithPassingPeriod = (schedule) => {
    const scheduleObject = scheduleWithPassingPeriods;

    schedule.forEach((item, i) => {
      const { pk_ScheduleAssignment, pk_Timeslot, pk_ScheduleBlock, isPassingPeriod, isFiller, isRedirect } = item;

      const key = `${pk_Timeslot}_${pk_ScheduleAssignment}_${pk_ScheduleBlock}`;
      if (isPassingPeriod || isFiller || isRedirect) {
        return;
      }

      let passingPeriodEnd;
      if (schedule[i + 1] && schedule[i + 1].isPassingPeriod) {
        passingPeriodEnd = schedule[i + 1].EndTimeAsMoment;
      } else {
        passingPeriodEnd = schedule[i].EndTimeAsMoment;
      }
      scheduleObject[key] = passingPeriodEnd;
    });

    setScheduleWithPassingPeriods(scheduleObject);
  };

  // useEffect(() => {
  //   if (scheduleWithPassingPeriods) {
  //     console.log('scheduleWithPassingPeriods: ', scheduleWithPassingPeriods);
  //   }
  // }, [scheduleWithPassingPeriods]);

  const handlePartialUnavailability = (item) => {
    const { EnableEvaluatorIsUnavailable, UnavailableOverlaps, isScheduleBlock } = item;

    if (!EnableEvaluatorIsUnavailable || !isScheduleBlock) {
      return null;
    }

    return (
      <Fragment>
        {UnavailableOverlaps.map((item, i) => {
          return (
            <p style={{ margin: 0 }}>
              <FontAwesomeIcon color="#cf142b" icon={faBan} /> You are unavailable from {item.StartTimeFormatted}-
              {item.EndTimeFormatted}
            </p>
          );
        })}
      </Fragment>
    );
  };

  const determineEvent = (item) => {
    if (item.isFlexEvent) {
      return (
        <div>
          <h4 style={{ fontWeight: 700, margin: 0 }}>{item.EventName}</h4>
          {handlePartialUnavailability(item)}
        </div>
      );
    }
    if (item.isPassingPeriod) {
      return <h6 style={{ margin: 0 }}>Passing Period</h6>;
    }

    if (item.isCustomMeeting) {
      const { CustomMeetingTitle } = item;
      return (
        <div>
          <h4 style={{ fontWeight: 700, margin: 0 }}>{CustomMeetingTitle}</h4>
        </div>
      );
    }

    if (item.isFiller) {
      return <h5>Break</h5>;
    }

    if (item.Candidates && item.Candidates.length > 0) {
      return item.Candidates.map((candidate) => {
        const { FirstName, LastName } = candidate;

        const detailedCandidate = candidateInfo
          ? candidateInfo.find((c) => {
              return c.pk_Candidate == candidate.pk_Candidate;
            })
          : {};

        return (
          <div key={candidate.pk_Candidate} id={`mySchedule_scrollID_${candidate.pk_Candidate}`}>
            <h4 style={{ fontWeight: 700, margin: 0 }}>
              {LastName}, {FirstName}
            </h4>
            <p style={{ margin: 0, fontWeight: 700 }}>
              {detailedCandidate && detailedCandidate.CandidateSubHeading ? detailedCandidate.CandidateSubHeading : ''}
            </p>
            {handlePartialUnavailability(item)}
            <div>{detailedCandidate ? determineProgress(detailedCandidate.Status) : ''}</div>
          </div>
        );
      });
    }

    if (item.Schedules4EvalAltText && item.Schedules4EvalAltText.length > 0)
      return <h5>{item.Schedules4EvalAltText}</h5>;

    return <h5>Break</h5>;
  };

  const getAlertedEvents = () => {
    const newAlertedEvents = localStorage.getItem('evaluator_alertedEvents') || [];
    if (newAlertedEvents && newAlertedEvents.length > 0) {
      setAlertedEvents(JSON.parse(newAlertedEvents));
    }
  };

  const openURLInNewTab = (urlFormatted) => {
    try {
      // throw 'TOWADERS';
      window.open(urlFormatted, '_blank').focus();
    } catch (e) {
      alert.error('Popup blocker detected. Please check your browser settings.');
    }
  };

  const showJoinMeetingButton = (url, priorityUrl, Schedules4Portal__pkUUID_Schedule, message = '', item) => {
    const demo = false;

    const isBreakItem = !item.isFlexEvent && !item.isCustomMeeting && !item.isScheduleBlock;

    if (isBreakItem && !allDayRoom) {
      return;
    }
    return (
      <Fragment>
        <br />
        <Button
          color={isBreakItem ? 'warning' : 'success'}
          size="sm"
          disabled={
            disableJoinMeetingButton ||
            (!date && !reduxDate) ||
            checkForScheduleDisabledButton(item, currentTimeAsMoment)
          }
          style={{ minWidth: '95px' }}
          onClick={async (e) => {
            e.preventDefault();

            let urlToUse = allDayRoom;
            const {
              VirtualRoomType,
              isCustomMeeting,
              CustomMeetingUrl,
              CustomUrl,
              isFlexEvent,
              UrlType,
              pk_Timeslot,
              pk_InterviewDate,
              EndTimeAsMoment,
              StartTimeAsMoment,
            } = item;

            const formattedEndTime = EndTimeAsMoment.format('MMM DD, YYYY HH:mm A');
            const formattedStartTime = StartTimeAsMoment.format('MMM DD, YYYY HH:mm A');

            if (item.isScheduleBlock) {
              if (virtualMeetingMode === 'External') {
                localStorage.setItem('evaluator_lastJoinedMeeting', JSON.stringify(item));
                openURLInNewTab(urlToUse);
              } else {
                const response = await requestVirtualMeetingEvaluator(item);
                localStorage.setItem('evaluator_lastJoinedMeeting', JSON.stringify(item));
                const { data } = response;
                const {
                  DialInNumbers,
                  Duration,
                  IsActive,
                  JoinURL,
                  MeetingId,
                  Password,
                  StartTime,
                  StartUrl,
                  Topic,
                } = data;
                // handleZoomClick(JoinURL, DialInNumbers, Password);
                handleZoomClick({
                  url: JoinURL,
                  mobileNumbers: DialInNumbers,
                  password: Password,
                  meetingId: MeetingId,
                });
              }

              return;
            }

            if (isCustomMeeting) {
              if (VirtualRoomType === 'Custom' && CustomMeetingUrl) {
                urlToUse = CustomMeetingUrl;
              }
            }

            if (isFlexEvent && UrlType) {
              if (UrlType === 'AllDay') {
              } else {
                urlToUse = CustomUrl;
              }
            }

            if (urlToUse) {
              localStorage.setItem('evaluator_lastJoinedMeeting', JSON.stringify(item));
              window.open(urlWithHttpProtocol(urlToUse), '_blank').focus();
            } else {
              Swal.fire(
                'Error!',
                'No link to join. No event has been created. Please contact your coordinator.',
                'error',
              );
            }
          }}
        >
          {disableJoinMeetingButton ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : item.isFlexEvent || item.isCustomMeeting || item.isScheduleBlock ? (
            'Join Meeting'
          ) : (
            'Visit Break Room'
          )}
          {message}
        </Button>
      </Fragment>
    );
  };

  const tick = () => {
    try {
      var rightNow = new Date();
      // need to get rid of the user's offset in order to correctly calculate
      if (scheduleActual.current && !isEmbedded) {
        const lastJoinedMeeting = localStorage.getItem('evaluator_lastJoinedMeeting');
        // TODO: check if lastMeeting is different from current lastMeeting.
        scheduleActual.current.forEach((element, i) => {
          const { pk_Timeslot, isRedirect } = element;
          if (isRedirect) {
            return;
          }
          if (
            !isRedirect &&
            diff_minutes(rightNow, element.StartTimeAsMoment) === 0 &&
            displayLocalNotification &&
            element.Candidates4DateNameFull &&
            element.Candidates4DateNameFull !== ''
          ) {
            informUserMeetingIsStartingLocally(setDisplayLocalNotification);
          }
          let itemAfterIsSameRow = false; // If same row, it means it's a split. We don't do passing periods within and after split cells.
          let itemBeforeIsSameRow = false;

          let itemBefore = schedule[i - 1] ? schedule[i - 1] : null;
          let itemAfter = schedule[i + 1] ? schedule[i + 1] : null;

          const itemIsBeforeCurrentMeeting = lastJoinedMeeting
            ? checkIfMeetingIsBeforeCurrentJoinedMeeting({
                lastJoinedMeeting: lastJoinedMeeting ? JSON.parse(lastJoinedMeeting) : null,
                itemToCheck: element,
              })
            : false;

          // Suppres notifs for Meetings before last joined meeting.
          if (itemIsBeforeCurrentMeeting) {
            return;
          }

          if (itemAfter != null && !itemAfter.isPassingPeriod) {
            itemAfterIsSameRow = pk_Timeslot == itemAfter.pk_Timeslot;
          }

          if (itemBefore != null && !itemBefore.isPassingPeriod) {
            itemBeforeIsSameRow = pk_Timeslot == itemBefore.pk_Timeslot;
          }

          checkForAlarm(element, { hasPassingPeriod: !itemAfterIsSameRow && !itemBeforeIsSameRow, lastJoinedMeeting });
        });
      }

      if (scheduleActual.current && document.getElementById('tableRow0')) {
        let heights = [];
        scheduleActual.current.forEach((element, i) => {
          if (!document.getElementById('tableRow' + i)) {
            return;
          }
          const height = document.getElementById('tableRow' + i).clientHeight;
          heights.push(height);
        });
        setHeights(heights);
      }
      setCurrentTime(rightNow);
      setCurrentTimeAsMoment(moment(rightNow));
    } catch (err) {
      console.log('tick err: ', err);
    }
  };

  const checkForAlarm = (item, options) => {
    const { hasPassingPeriod, lastJoinedMeeting } = options;
    const {
      Candidates = [],
      pk_Timeslot,
      pk_ScheduleAssignment,
      pk_ScheduleBlock,
      EndTimeAsMoment,
      StartTimeAsMoment,
      SortOrder,
      isPassingPeriod,
      isFlexEvent,
      isScheduleBlock,
      isCustomMeeting,
      isFiller,
      pk_InterviewDate,
    } = item;
    const trackerKey = `${pk_Timeslot}_${pk_ScheduleAssignment}_${pk_ScheduleBlock}`;
    const itemIsBreak = !isCustomMeeting && !isFlexEvent && !isScheduleBlock && !isPassingPeriod && isFiller;

    if ((Candidates.length > 0 || isFlexEvent || isCustomMeeting || isScheduleBlock) && !isFiller) {
      const eventEndTime = EndTimeAsMoment;
      const eventEndTimeWithPassing = scheduleWithPassingPeriods[trackerKey];

      const formattedEndTime = EndTimeAsMoment.format('MMM DD, YYYY HH:mm A');
      const formattedStartTime = StartTimeAsMoment.format('MMM DD, YYYY HH:mm A');

      const now = moment();
      const realAlertedEvents = localStorage.getItem('evaluator_alertedEvents')
        ? JSON.parse(localStorage.getItem('evaluator_alertedEvents'))
        : [];

      const newAlertedEvents = realAlertedEvents.slice();

      alarmsActual.current.forEach((a) => {
        const { Message, pk_Alert, Type } = a;
        const alertMinutes = a.Minutes;
        const key = `evaluator_${formattedStartTime}_${formattedEndTime}_${pk_Alert}_${pk_Timeslot}_${pk_InterviewDate}`;

        // Update top deal with non-filler non-passing period items
        let diffActual = 0;

        if (Type === 'End of Meeting') {
          diffActual = eventEndTime.diff(now, 'minutes', true);
        }

        if (Type === 'End of Passing') {
          diffActual = eventEndTimeWithPassing.diff(now, 'minutes', true);
        }

        const alertIsNow = diffActual >= -1 && diffActual <= alertMinutes;
        const alertIsInThePast = diffActual < -1;

        if (alertIsNow && !realAlertedEvents.includes(key)) {
          if (Type === 'End of Meeting' || (Type === 'End of Passing' && hasPassingPeriod)) {
            // console.log('firing for: ', item);
            // console.log('Type: ', Type);
            // console.log('Message: ', Message);
            // console.log('formattedStartTime: ', formattedStartTime);
            // console.log('hasPassingPeriod: ', hasPassingPeriod);
            // console.log('diffActual: ', diffActual);
            // console.log('alertMinutes: ', alertMinutes);
            try {
              new Notification(`${Message}`);
            } catch (e) {
              console.log('Failed to fire notif. Err: ', e);
            }

            if (a.Sound === 'Bell') {
              let audio = new Audio(BellSound);
              audio.volume = 0.9;
              audio
                .play()
                .then(() => {
                  console.log('alert played fine');
                })
                .catch((err) => {
                  console.log('alert failed to play. err: ', err);
                });
            }

            alert.info(`${Message}`, { timeout: 10000 });
          } else {
            // console.log('was about to fire end of passing, but is a split cell');
          }
        }

        if (alertIsNow || alertIsInThePast) {
          // const newAlertedEvents = realAlertedEvents.slice();
          if (!newAlertedEvents.includes(key)) {
            newAlertedEvents.push(key);

            localStorage.setItem('evaluator_alertedEvents', JSON.stringify(newAlertedEvents));
            setAlertedEvents(newAlertedEvents);
          }
        }
      });
    }
  };

  const determineIcon = (item) => {
    if (item.Schedules4EvalAltText === 'passing period') {
      return <div />;
    }
    const returnBreak = () => {
      if (item.isPassingPeriod) return <div></div>;

      return (
        <div style={{ width: '78px', height: '78px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faCoffee} size="3x" color="rgb(51,51,51)" />
        </div>
      );
    };

    const {
      isCustomMeeting,
      isFiller,
      isScheduleBlock,
      isPassingPeriod,
      Candidates,
      isFlexEvent,
      CustomMeetingTitle = '',
    } = item;

    if (Candidates && Candidates.length > 0) {
      const singleCandidateWithPhoto =
        Candidates.length === 1 && Candidates[0].PhotoUrl && Candidates[0].PhotoUrl !== '' ? true : false;
      const singleCandidateWithoutPhoto = Candidates.length === 1 && !Candidates[0].PhotoUrl;
      const multipleCandidates = Candidates.length > 1;
      return (
        <div style={{ position: 'relative', top: 0 }}>
          <div style={{ minHeight: '78px', display: 'flex', alignItems: 'center' }}>
            {singleCandidateWithPhoto && <img style={{ width: '78px', height: 'auto' }} src={Candidates[0].PhotoUrl} />}
            {singleCandidateWithoutPhoto && (
              <div
                style={{
                  height: '80px',
                  width: '80px',
                  // border: '1px solid rgb(51, 51, 51)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon icon={multipleCandidates ? faUsers : faUser} size="4x" />
              </div>
            )}
            {item.Candidates4DateDNRStatus === 1 && (
              <div style={{ position: 'absolute', bottom: 0, left: '87px' }}>
                <Circle>
                  <p style={{ padding: 0, margin: 0, fontSize: '.7em' }}>DNR</p>
                </Circle>
              </div>
            )}
          </div>
        </div>
      );
    }

    if (isFlexEvent) {
      const { FlexEvent = {}, EventName } = item;
      const { Candidates = [] } = FlexEvent;
      const candidateCount = Candidates.length;
      const showCount =
        EventName.toUpperCase() !== 'LUNCH' &&
        EventName.toUpperCase() !== 'DINNER' &&
        EventName.toUpperCase() !== 'BREAK' &&
        EventName.toUpperCase() !== 'UNAVAILABLE';
      let eventIcon = faHandshake;

      switch (EventName.toUpperCase()) {
        case 'BREAK':
          eventIcon = faCoffee;
          break;
        case 'LUNCH':
          eventIcon = faUtensils;
          break;
        case 'DINNER':
          eventIcon = faUtensils;
          break;
        case 'UNAVAILABLE':
          eventIcon = faBan;
          break;
        default:
          eventIcon = faHandshake;
          break;
      }

      return (
        <div
          style={{
            width: '78px',
            height: '78px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 15,
          }}
        >
          <FontAwesomeIcon icon={eventIcon} size="3x" color="rgb(51,51,51)" />
          {showCount ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: 13,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon icon={faUser} color="rgb(51,51,51)" style={{ marginRight: 2 }} /> x{candidateCount}
            </div>
          ) : null}
        </div>
      );
    }

    if (isCustomMeeting) {
      const firstLetter = item.CustomMeetingTitle.charAt(0).toUpperCase();
      let eventIcon = null;
      switch (CustomMeetingTitle.toUpperCase()) {
        case 'BREAK':
          eventIcon = faCoffee;
          break;
        case 'LUNCH':
          eventIcon = faUtensils;
          break;
        case 'DINNER':
          eventIcon = faUtensils;
          break;
        default:
          eventIcon = null;
      }
      return (
        <div style={{ width: '78px', height: '78px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {eventIcon ? (
            <FontAwesomeIcon icon={eventIcon} size="3x" color="rgb(51,51,51)" />
          ) : (
            <h2 style={{ fontWeight: '700' }}>{firstLetter}</h2>
          )}
        </div>
      );
    }
    return returnBreak();
  };

  const joinMeetingButton = (joinMeetingButtonVisible, interviewIsToday, item) => {
    // if item is a filler or a passing period it shouldn't return a buttton

    const shouldBeVisible = shouldJoinMeetingButtonBeVisisble(item, null, currentTimeAsMoment);

    if (shouldBeVisible && virtualMeetingMode !== 'Disabled') {
      return (
        <span>
          {showJoinMeetingButton(
            '',
            item.Schedules4EvalJoinManualURL,
            item.Schedules4Eval__pkUUID_Schedule,
            item.message,
            item,
          )}
        </span>
      );
    }
  };

  const handleIterativeVariables = (item) => {
    //Variables are being set this way because CandidateAlphabetical needs to know
    // about which schedule item is currently active.  This is being determined
    // by this Schedule component so in order to communicate this to CandidateAlphabetical
    // I'm setting state in their parent component

    if (item.Schedules4Eval__pkUUID_Schedule !== Schedules4Eval__pkUUID_Schedule) {
      setSchedules4Eval__pkUUID_Schedule(item.Schedules4Eval__pkUUID_Schedule);
    }

    if (Schedules4EvalJoinManualURL !== item.Schedules4EvalJoinManualURL) {
      setSchedules4EvalJoinManualURL(item.Schedules4EvalJoinManualURL);
    }

    if (typeof item.Candidates4Date__pkUUID_Candidate === 'undefined' && userIdOnCurrentInterview !== null) {
      setUserIdOnCurrentInterview(null);
    }

    if (item.Candidates4Date__pkUUID_Candidate && item.Candidates4Date__pkUUID_Candidate !== userIdOnCurrentInterview) {
      setUserIdOnCurrentInterview(item.Candidates4Date__pkUUID_Candidate);
    }
  };

  return (
    <>
      <div
        id="evaluation-table-wrapper"
        style={{
          width: '100%',
          height: isMobile ? 'calc(100vh - 290px)' : 'calc(100vh - 430px)',
          backgroundColor: '#FFF',
          overflowY: 'auto',
        }}
      >
        <Table responsive={true} style={{ marginBottom: 0 }}>
          <tbody>
            {schedule && schedule.length > 0 ? (
              schedule.map((item, i) => {
                const { SortOrder } = item;
                const itemBefore = schedule[i - 1];
                let index = i;
                // if (itemBefore && itemBefore.isRedirect) {
                //   index = i - 1;
                // }
                return (
                  <ScheduleItem
                    // TODO: give this a proper key
                    candidateInfo={candidateInfo}
                    changeToCandidateTab={changeToCandidateTab}
                    chosenCandidate={chosenCandidate}
                    currentTime={currentTime}
                    currentTimeAsMoment={currentTimeAsMoment}
                    date={date}
                    determineEvent={determineEvent}
                    determineIcon={determineIcon}
                    differences={differences}
                    firstBlockEarlyJoin={firstBlockEarlyJoin}
                    firstEvent={firstEvent}
                    getCandidate={getCandidate}
                    getPercentageTime={getPercentageTime}
                    handleDateChange={handleDateChange}
                    handleIterativeVariables={handleIterativeVariables}
                    heights={heights}
                    i={index}
                    indexesWithDifferences={indexesWithDifferences}
                    interviewIsToday={interviewIsToday}
                    isMobile={isMobile}
                    isPassingPeriod={item.Schedules4EvalAltText === 'passing period'}
                    item={item}
                    joinMeetingButton={joinMeetingButton}
                    key={`${SortOrder}${i}`}
                    prevItem={schedule[i - 1]}
                    reduxDate={reduxDate}
                    requestVirtualMeetingEvaluator={requestVirtualMeetingEvaluator}
                    selectedCandidateIdLocal={selectedCandidateIdLocal}
                    selectedTimeSlot={selectedTimeSlot}
                    setChosenCandidate={setChosenCandidate}
                    setDate={setDate}
                    setSelectedCandidate={setSelectedCandidate}
                    setSelectedTimeSlot={setSelectedTimeSlot}
                    setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
                    showRed={showRed}
                    tableRowOpacity={tableRowOpacity}
                    userIdOnCurrentInterview={userIdOnCurrentInterview}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={4} style={{ display: 'flex', justifyContent: 'center', padding: 30 }}>
                  No Schedule Available
                </td>
              </tr>
            )}
            <tr></tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

const WrappedSchedule = (props) => {
  return (
    <AlertProvider template={NotifAlertTemplate}>
      <Schedule {...props} />
    </AlertProvider>
  );
};

export default WrappedSchedule;
