import {
  faCopy,
  faDownload,
  faPlus,
  faQuestionCircle,
  faTrashAlt,
  faFileImport,
  faFileCsv,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { Link, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import DragAndDropFiles from '../../../../Body/DragAndDropFiles/DragAndDropFiles';
import { Button, Col, Nav, NavItem, Row } from 'reactstrap';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { formatBearerToken, scrollToElement, swalErrorMessage } from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import { prepareDataTypesForSortUpdate, separateDataTypesByAvailability } from '../../../../Services/candidates';
import { fetchCandidates } from '../../../../Services/CandidatesAPI';
import { deleteData, fetchDataAgnostic, putData } from '../../../../Services/dataApi';
import ConfirmModal from '../../Coordinator/Schedule/ConfirmModal';
import AdminImportCandidates from './AdminImportCandidates/AdminImportCandiates';
import CandidateList from '../CandidateList';
import AddNewCandidateModal from './AddNewCandidateModal';
// chart to use https://nivo.rocks/bar/
import './AdminCandidates.style.css';
import CandidateListWithFiltersX from './CandidateListWithFiltersX';
import CandidateWidget from './CandidateWidget/CandidateWidget';
import DataTypeItem from './DataTypeItem';
import DocumentTypesPopover from './DocumentTypesPopover';
import style from './style.js';
import PrintMaterials from './PrintMaterials/PrintMaterials';
import { clone } from '../../../../Services/schedule';
import moment from 'moment';
import { RouterPrompt } from '../../Common/RouterPrompt/RouterPrompt';
import Swal from 'sweetalert2';
import Loading from 'Body/Statuses/Loading';
import CandidateImports from './CandidateImports/CandidateImports';

const importTypes = {
  SFMatch: 'SFMatch',
  Eras: 'Eras',
};

const wrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  border: '1px solid blue',
};

const card = {
  border: '3px solid',
  padding: '15px',
  height: '175px',
  minWidth: '200px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  borderRadius: '15px',
};

const errorMessage = 'Something went wrong trying to save your edits.';

let getDetailedCandidateTimeout = null; // To stop unnecessary calls to get detailed candidate

const AdminCandidates = ({ activeSeason, activeDepartment, pk_Department, match }) => {
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [allCandidatesDetails, setAllCandidatesDetails] = useState([]);
  const [forcedSelectedCandidateId, setForcedSelectedCandidateId] = useState(null);

  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [dataTypes, setDataTypes] = useState({ enabled: [], disabled: [] }); // To be used for Scores and Awards/Manage Data Fields
  const [dataTypesRaw, setDataTypesRaw] = useState({});

  const [documentTypes, setDocumentTypes] = useState([]);
  const [bulkDocumentImportOpen, setBulkDocumentImportOpen] = useState(false);

  const [importErasOpen, setImportErasOpen] = useState(false);
  const [interviewDates, setInterviewDates] = useState([]);
  const [token, setToken] = useState();

  const [showImportPopover, setShowImportPopover] = useState(false);
  const [showManageFieldsPopover, setShowManageFieldsPopover] = useState(false);
  const [showDocumentTypesPopover, setShowDocumentTypesPopover] = useState(false);
  const [showAddNewCandidate, setShowAddNewCandidate] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showScheduleAlertModal, setShowScheduleAlertModal] = useState(false);

  const [overbookData, setOverbookData] = useState(null);
  const [showOverbookedResponseWidget, setShowOverbookedResponseWidget] = useState(false);

  const [isUpdatingCandidateList, setIsUpdatingCandidateList] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isGettingCandidateDetails, setIsGettingCandidateDetails] = useState(false);
  const [allCandidatesDetailsError, setAllCandidatesDetailsError] = useState(false);

  const [isUpdatingDataType, setIsUpdatingDataType] = useState(false);

  const [changes, setChanges] = useState({});
  const [tabToGo, setTabToGo] = useState(1);
  const resetFunctions = useRef();

  const [currentTab, setCurrentTab] = useState(1);
  const [isInitializedCandidates, setIsInitializedCandidates] = useState(false);
  const [isInitializedDocumentTypes, setIsInitializedDocumentTypes] = useState(false);
  const [isGettingAllDetailedCandidates, setIsGettingAllDetailedCandidates] = useState(false);

  // const [abortController, setAbortController] = useState(new AbortController());
  // const [dateFromURL, setDateFromURL] = useState();
  const dateFromURL = useRef();

  const dContext = useContext(DepartmentContext);
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const query = new URLSearchParams(useLocation().search);
  const { path } = useRouteMatch();

  const params = useParams();
  const history = useHistory();
  const { location } = history;
  const { pathname } = location;

  const alert = useAlert();
  const abortController = useRef();
  const selectedCandidateRef = useRef();

  useEffect(() => {
    if (pathname) {
      if (pathname.indexOf('/candidate_details') >= 0) {
        if (currentTab != 1) {
          setCurrentTab(1);
          setTabToGo(1);
        }
      } else if (pathname.indexOf('/candidate_list') >= 0) {
        if (currentTab != 2) {
          setCurrentTab(2);
          setTabToGo(2);
        }
      } else if (pathname.indexOf('/import_data') >= 0) {
        if (currentTab != 3) {
          setCurrentTab(3);
          setTabToGo(3);
        }
      } else if (pathname.indexOf('/manage_fields') >= 0) {
        if (currentTab != 4) {
          setCurrentTab(4);
          setTabToGo(4);
        }
      } else if (pathname.indexOf('/document_types') >= 0) {
        if (currentTab != 5) {
          setCurrentTab(5);
          setTabToGo(5);
        }
      } else if (pathname.indexOf('/print_materials') >= 0) {
        if (currentTab != 6) {
          setCurrentTab(6);
          setTabToGo(6);
        }
      } else {
        setCurrentTab(1);
        setTabToGo(1);
      }
    }
  }, [pathname]);

  useEffect(() => {
    getCandidates();
    getInterviewDates();
    getDocumentTypes();
    getDepartmentDataTypes();
  }, []);

  useEffect(() => {
    setShowManageFieldsPopover(false);
    setShowDocumentTypesPopover(false);
    setShowImportPopover(false);
    setShowAddNewCandidate(false);
    setShowDeleteConfirmationModal(false);
    setShowScheduleAlertModal(false);
  }, [currentTab]);

  useEffect(() => {
    const controller = new AbortController();
    // Do something here. This breaks for additional details. Need to figure out alternate solution.
    // console.log('selectedCandidateRef.current change: ', selectedCandidateRef.current);
    if (selectedCandidateRef.current) {
      // getCandidateDetails(selectedCandidate, { abortController: controller });
    }

    // }
    return () => {
      controller.abort();
    };
  }, [selectedCandidateRef.current]);

  useEffect(() => {
    if (selectedCandidate) {
      // console.log('selectedCanddiate changed, updating selectedCandidateRef.current: ', selectedCandidate.pk_Candidate);
      selectedCandidateRef.current = selectedCandidate.pk_Candidate;
      // console.log('scrollToElement! ', `candidateList_${selectedCandidate.pk_Candidate}_candidateList`);
      // scrollToElement(`candidateList_${selectedCandidate.pk_Candidate}_candidateList`);
    }

    if (
      (forcedSelectedCandidateId && selectedCandidate && selectedCandidate.pk_Candidate != forcedSelectedCandidateId) ||
      (forcedSelectedCandidateId && !selectedCandidate)
    ) {
      setForcedSelectedCandidateId(null);
    } else if (
      forcedSelectedCandidateId &&
      selectedCandidate &&
      selectedCandidate.pk_Candidate == forcedSelectedCandidateId
    ) {
    }

    return abortController.current && abortController.current.abort();
  }, [selectedCandidate]);

  const getDepartmentDataTypes = () => {
    setIsUpdatingDataType(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/dataTypes',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdatingDataType(false);
            setDataTypes(separateDataTypesByAvailability(res.data));
            setDataTypesRaw(res.data);
          })
          .catch((err) => {
            setIsUpdatingDataType(false);
          });
      })
      .catch((err) => {
        setIsUpdatingDataType(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const updateDataType = (dataTypeToUpdate, updateSortOrders) => {
    if (updateSortOrders) {
      dataTypeToUpdate = prepareDataTypesForSortUpdate(dataTypes, dataTypeToUpdate);
    }

    setIsUpdatingDataType(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/dataTypes',
          { pk_Department: dContext.department.pk_Department },
          { types: dataTypeToUpdate },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdatingDataType(false);
            getDepartmentDataTypes();
          })
          .catch((err) => {
            setIsUpdatingDataType(false);
            getDepartmentDataTypes();
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const discardChanges = () => {
    if (resetFunctions.current) {
      Object.values(resetFunctions.current).forEach((resetFunc) => {
        resetFunc(selectedCandidate);
      });
      setChanges({});
    }
  };

  const renderScheduleAlertModal = () => {
    return (
      <ConfirmModal
        title={'Locked Item!'}
        message={'The selected candidate is registered for an interview date. Please unregister first.'}
        buttonChoices={
          <Button
            color="primary"
            onClick={() => {
              setShowScheduleAlertModal(false);
            }}
          >
            Ok
          </Button>
        }
        isOpen={showScheduleAlertModal}
      />
    );
  };

  const replacePhotoinCandidatesArray = (id, photoUri) => {
    setCandidates((prevState) => {
      // eslint-disable-next-line
      let clone = structuredClone(prevState);

      if (clone[id]) {
        clone[id].PhotoUrl = photoUri;
      }
      return clone;
    });
  };

  const getCandidates = (cb, options) => {
    const { forcedSelectedCandidateId = null, clearSelectedCandidate } = options || {};

    setForcedSelectedCandidateId(forcedSelectedCandidateId);
    selectedCandidateRef.current = null;

    if (dContext.department) {
      setIsUpdatingCandidateList(true);
      getTokenSilently()
        .then((token) => {
          fetchCandidates(token, {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            callback: (newCandidates) => {
              const newCandidateList = clone(newCandidates);
              if (forcedSelectedCandidateId) {
                const newSelectedCandidate = Object.values(newCandidates).find((c) => {
                  return c.pk_Candidate == forcedSelectedCandidateId;
                });

                if (newSelectedCandidate) {
                  setSelectedCandidate(newSelectedCandidate);
                }
              }

              if (clearSelectedCandidate) {
                setSelectedCandidate(null);
              }

              setCandidates(newCandidateList);
              setIsUpdatingCandidateList(false);
              setIsInitializedCandidates(true);
              // discardChanges();
              if (cb) {
                cb();
              }
            },
          });
        })
        .catch((err) => {
          console.log('getToken err: ', err);
          if (err.message === 'Login required') {
            loginWithRedirect();
          }
        });
    } else {
      setTimeout(getCandidates);
    }
  };

  const getAllCandidatesData = (callback) => {
    setIsGettingAllDetailedCandidates(true);
    setAllCandidatesDetailsError(false);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/allCandidatesDetails',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setAllCandidatesDetails(Object.values(res.data));
            setIsGettingAllDetailedCandidates(false);
            if (callback) {
              callback();
            }
          })
          .catch((err) => {
            setIsGettingAllDetailedCandidates(false);
            setAllCandidatesDetailsError(true);
            console.log('getAllCandidatesData err: ', err);
            if (callback) {
              callback(err);
            }
          });
      })
      .catch((err) => {
        setIsGettingAllDetailedCandidates(false);
        setAllCandidatesDetailsError(true);
        console.log('getAllCandidatesData getToken err: ', err);
        if (callback) {
          callback(err);
        }
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const deleteCandidates = (candidateList = []) => {
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/season/candidate',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          { candidateIds: candidateList },
          formatBearerToken(token),
        )
          .then((res) => {
            getCandidates();
          })
          .catch((err) => {
            Swal.fire({
              title: 'Error',
              text: `Something went wrong trying to delete the candidate${candidateList.length > 1 ? 's' : ''}.`,
              icon: 'error',
            });
          });
      })
      .catch((err) => {
        Swal.fire({
          title: 'Error',
          text: `Something went wrong trying to delete the candidate${candidateList.length > 1 ? 's' : ''}.`,
          icon: 'error',
        });

        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getInterviewDates = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/InterviewDates',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            if (res.data && Array.isArray(res.data)) {
              setInterviewDates(
                res.data.sort((a, b) => {
                  const dateA = moment(`${a.DateOfInterview} ${a.StartTime}`).valueOf();
                  const dateB = moment(`${b.DateOfInterview} ${b.StartTime}`).valueOf();

                  return dateA - dateB;
                }),
              );
              const pk_InterviewDate = query.get('pk_InterviewDate');
              if (pk_InterviewDate != null) {
                dateFromURL.current = res.data.find((i) => {
                  return i.pk_InterviewDate == pk_InterviewDate;
                });

                history.push(pathname);
              }
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getDocumentTypes = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'documentTypes',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsInitializedDocumentTypes(true);
            setDocumentTypes(res.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  // need to update the countfor the interview
  const handleCountUpdate = (candidateCount, pk_InterviewDate) => {
    setInterviewDates((prevState) => {
      // eslint-disable-next-line
      let clone = structuredClone(prevState);
      clone.forEach((item) => {
        if (item.pk_InterviewDate === pk_InterviewDate) {
          item.CountOfCandidates = candidateCount;
        }
      });
      return clone;
    });
  };

  // this permission for override specifically triggers if an admin tried to schedule
  // a candidate without the boolean allowedOverbook = true and the interviewdate was overbooked.
  // essentially the api caught the overbooking but the frontend did not
  const permissionForOverride = () => {
    const { data, callback } = overbookData || {};
    const { query, body, candidateCount, interviewDateMeta } = data || {};
    const { DateOfInterview, CandidateRegistrationLimit, Label } = interviewDateMeta || {};

    const message = `"${moment.tz(DateOfInterview, null).format('dddd, MMM. DD, YYYY')}" ${
      Label ? `(${Label})` : ''
    } is fully booked (${candidateCount || 0} of ${CandidateRegistrationLimit || 0} spots filled). Proceed anyway?`;

    return (
      <ConfirmModal
        title={'Overbooked!'}
        message={message}
        isOpen={overbookData !== null}
        onAccept={() => {
          saveResults({ callback, ...body, allowOverbook: true, candidatesToUpdate: body.CandidatePks });
          setOverbookData(null);
        }}
        onCancel={() => {
          setOverbookData(null);
        }}
      />
    );
  };

  const deleteCandidatesPermanently = (data) => {
    const { candidatesToDelete, callback } = data;

    setIsSaving(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/season/candidate/delete/permanent',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          { candidateIds: candidatesToDelete },
          formatBearerToken(token),
        )
          .then((result) => {
            setIsSaving(false);

            if (callback) {
              callback(true);
            }
            getCandidates(callback);
            getInterviewDates();
            alert.success(`Candidate${candidatesToDelete.length > 1 ? 's' : ''} deleted permanently.`);
          })
          .catch((err) => {
            callback(false);
            setIsSaving(false);
            alert.error(errorMessage);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const saveResults = (data) => {
    const { candidatesToUpdate, fieldsToUpdate, callback, allowOverbook, forcedSelectedCandidateId } = data;
    selectedCandidateRef.current = null;

    setIsSaving(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'candidate',
          { pk_Department: dContext.department.pk_Department },
          { CandidatePks: candidatesToUpdate, fieldsToUpdate, allowOverbook },
          formatBearerToken(token),
        )
          .then((results) => {
            setIsSaving(false);
            // the date was overbooked and did not recieve a boolean of allowOverbook = true
            if (results.data.requiresOverbookPermissions) {
              setOverbookData({ data: results.data, callback });
              handleCountUpdate(results.data.candidateCount, fieldsToUpdate.pk_InterviewDate);
              return;
            }

            if (results.error) {
              if (callback) {
                callback(false);
              }
              alert.error(errorMessage);
            } else {
              getCandidates(callback, { forcedSelectedCandidateId: forcedSelectedCandidateId });
              getInterviewDates();
              setChanges({});
              if (callback) {
                return;
              }
              alert.success('Edits saved.');
            }
          })
          .catch((err) => {
            callback(false);
            setIsSaving(false);
            alert.error(errorMessage);
          });
      })
      .catch((err) => {
        setIsSaving(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const renderImportData = () => {
    return (
      <CandidateImports
        documentTypes={documentTypes}
        allCandidatesDetails={allCandidatesDetails}
        getAllCandidatesData={getAllCandidatesData}
        getCandidatesParent={getCandidates}
        setChanges={setChanges}
        changes={changes}
      />
    );
  };

  const renderManageFields = () => {
    const { enabled = [], disabled = [] } = dataTypes;
    const count = enabled ? enabled.length : 0;
    let index = 1;

    const selectOptions = [];

    while (index <= count) {
      selectOptions.push(
        <option key={index} value={index}>
          {index}
        </option>,
      );
      index++;
    }

    const enabledList = enabled.map((dataType) => {
      return (
        <DataTypeItem
          key={dataType.Name}
          dataType={dataType}
          numberOfEnabled={count}
          updateDataType={updateDataType}
          isUpdatingDataType={isUpdatingDataType}
          selectOptions={selectOptions}
        />
      );
    });

    const disabledList = disabled.map((dataType) => {
      return (
        <DataTypeItem
          key={dataType.Name}
          dataType={dataType}
          numberOfEnabled={count}
          enabled={enabled}
          updateDataType={updateDataType}
          isUpdatingDataType={isUpdatingDataType}
          selectOptions={selectOptions}
        />
      );
    });

    if (!isInitializedCandidates && isUpdatingDataType) {
      return (
        <div
          style={{
            width: '100%',
            height: '40vh',
            fontWeight: 'bold',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div style={{ height: 100, width: 100 }}>
            <Loading
              options={{
                labelText: 'Loading. . .',
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <>
        <div>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: '40%', margin: 5, textAlign: 'center', fontWeight: 'bold' }}>
              Available Fields
            </div>
            <div style={{ ...style.simpleColumn, width: '60%', margin: 5, textAlign: 'center', fontWeight: 'bold' }}>
              Selected Fields
            </div>
          </div>
          <div style={style.spacedBetweenRow}>
            <div style={{ ...style.manageFieldsContainer, width: '40%' }}>
              {disabledList && disabledList.length > 0 ? (
                disabledList
              ) : (
                <div style={{ display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center' }}>
                  No Fields
                </div>
              )}
            </div>
            <div style={{ ...style.manageFieldsContainer, width: '60%', overflowY: 'hidden' }}>
              <div style={style.manageFieldsEnabledItemsVisibilityLabels}>
                <div style={style.visibilityLabel}>Rank</div>
                <div style={style.visibilityLabel}>Eval</div>
              </div>
              <div>
                <div style={style.manageFieldsContainerSubContainer}>
                  {enabledList && enabledList.length > 0 ? (
                    enabledList
                  ) : (
                    <div style={{ display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center' }}>
                      No Fields
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderErasImportPopup = () => {
    return (
      <div>
        <AdminImportCandidates
          importType={importTypes.Eras}
          importTypes={importTypes}
          setImportErasOpen={setImportErasOpen}
          allCandidatesDetails={allCandidatesDetails}
          getCandidates={getCandidates}
        />
      </div>
    );
  };

  const renderBulkImportPopup = () => {
    return <DragAndDropFiles documentTypes={documentTypes} />;
  };

  const renderDocumentTypes = () => {
    return (
      <>
        <DocumentTypesPopover
          setShowDocumentTypesPopover={setShowDocumentTypesPopover}
          token={token}
          onUpdate={getDocumentTypes}
        />
      </>
    );
  };

  const renderPrintMaterials = () => {
    return <PrintMaterials />;
  };

  const renderDeleteConfirmationModal = () => {
    const { FirstName, LastName } = selectedCandidate || {};
    const candidateName = FirstName && LastName ? `${FirstName} ${LastName}` : null;
    return (
      <ConfirmModal
        title="Deactivate Candidate"
        message={`Are you sure you want to Deactivate ${candidateName ? candidateName : 'the selected candidate'}?`}
        isOpen={showDeleteConfirmationModal}
        buttonChoices={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {' '}
            <Button
              style={{ width: 150 }}
              color="secondary"
              onClick={() => {
                setShowDeleteConfirmationModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ width: 150 }}
              color="danger"
              onClick={() => {
                setShowDeleteConfirmationModal(false);
                deleteCandidates([selectedCandidate.pk_Candidate]);
              }}
            >
              Yes
            </Button>
          </div>
        }
        onAccept={() => {
          setShowDeleteConfirmationModal(false);
          deleteCandidates([selectedCandidate.pk_Candidate]);
        }}
        onCancel={() => {
          setShowDeleteConfirmationModal(false);
        }}
      />
    );
  };

  const hasChanges = Object.values(changes).includes(true);

  return (
    <div>
      {permissionForOverride()}
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%', flexWrap: 'wrap', paddingBottom: '10px' }}
        >
          <Nav className="nav-tabs">
            <NavItem className="nav-item">
              <Link
                className={'nav-link ' + (currentTab === 1 ? 'active' : '')}
                to={`${path}/candidate_details`}
                onClick={() => {
                  if (!hasChanges) {
                    setCurrentTab(1);
                  }
                  setTabToGo(1);
                }}
              >
                <i className="fa fa-paper-plane"></i> Candidate Details
              </Link>
            </NavItem>
            <NavItem className="nav-item">
              <Link
                className={'nav-link ' + (currentTab === 2 ? 'active' : '')}
                to={`${path}/candidate_list`}
                // onClick={() => setCurrentTab(2)}
                onClick={() => {
                  if (!hasChanges) {
                    setCurrentTab(2);
                  }
                  setTabToGo(2);
                }}
              >
                <i className="fa fa-clipboard-list"></i> Candidate List
              </Link>
            </NavItem>
            <NavItem className="nav-item">
              <Link
                className={'nav-link ' + (currentTab === 3 ? 'active' : '')}
                to={`${path}/import_data/candidates_import`}
                onClick={() => {
                  if (!hasChanges) {
                    setCurrentTab(3);
                  }
                  setTabToGo(3);
                }}
              >
                Import data
              </Link>
            </NavItem>

            <NavItem className="nav-item">
              <Link
                className={'nav-link ' + (currentTab === 6 ? 'active' : '')}
                to={`${path}/print_materials`}
                onClick={() => {
                  if (!hasChanges) {
                    setCurrentTab(6);
                  }
                  setTabToGo(6);
                }}
              >
                Print Materials
              </Link>
            </NavItem>
          </Nav>
        </div>
      </div>

      {currentTab === 1 && (
        <Fragment>
          <Row>
            <Col
              md={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '70vh',
                // minWidth: '300px',
                padding: 10,
                paddingTop: 5,
                // overflowY: 'scroll',
              }}
            >
              <CandidateList
                forcedSelectedCandidateId={forcedSelectedCandidateId}
                setForcedSelectedCandidateId={setForcedSelectedCandidateId}
                useUnsavedChangesAlert={true}
                hasChanges={hasChanges}
                discardChanges={discardChanges}
                selectedCandidate={selectedCandidate}
                setSelectedCandidate={(newSelectedCandidate) => {
                  const newCandidate = newSelectedCandidate;
                  if (newCandidate) {
                    selectedCandidateRef.current = newCandidate ? newCandidate.pk_Candidate : null;
                  }

                  setSelectedCandidate(newCandidate);
                }}
                setParentFilteredCandidates={setFilteredCandidates}
                candidates={candidates}
                pk_Department={activeDepartment ? activeDepartment.pk_Department : null}
                interviewDates={interviewDates}
                dateFromURL={dateFromURL.current ? dateFromURL.current : null}
                showTagFilter={true}
                isGettingCandidateDetails={isGettingCandidateDetails}
                uniqueId="candidateList"
              />

              <Row
                className="removeAndAddWrapper"
                style={{
                  ...style.buttonWrapper,
                  width: 'calc(100% + 21px)',
                  padding: 5,
                  paddingTop: 3,
                  marginTop: 10,
                }}
              >
                <div style={{ ...style.simpleColumn, width: '50%', padding: 5, paddingTop: 0 }}>
                  <Button
                    onClick={() => {
                      if (selectedCandidate.DateOfInterview != null) {
                        setShowScheduleAlertModal(true);
                      } else {
                        setShowDeleteConfirmationModal(!showDeleteConfirmationModal);
                      }
                    }}
                    color="danger"
                  >
                    <div style={style.spacedBetweenRow}>
                      <div>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </div>
                      <div style={style.buttonLabel}>Deactivate Candidate</div>
                    </div>
                  </Button>
                  {renderDeleteConfirmationModal()}
                </div>
                <div style={{ ...style.simpleColumn, width: '50%', padding: 5, paddingTop: 0, paddingRight: 0 }}>
                  <Button
                    color="success"
                    onClick={() => {
                      setShowAddNewCandidate(true);
                    }}
                  >
                    <div style={style.spacedBetweenRow}>
                      <div>
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                      <div style={style.buttonLabel}>New Candidate</div>
                    </div>
                  </Button>
                </div>
              </Row>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  style={{ width: '100%' }}
                  color="primary"
                  onClick={() => {
                    history.push('/administrator/candidates/import_data/candidates_import');
                  }}
                >
                  <FontAwesomeIcon icon={faFileImport} style={{ marginRight: 10 }} />
                  Import Candidate Data
                </Button>
              </div>
            </Col>

            <Col md={9} style={{ ...style.simpleColumn }}>
              <Row>
                <Col
                  md={12}
                  style={{
                    height: '67vh',
                  }}
                >
                  <div style={{ height: '100%' }}>
                    <CandidateWidget
                      abortController={abortController}
                      activeDepartment={activeDepartment}
                      candidates={candidates}
                      dateFromURL={dateFromURL}
                      dataTypes={dataTypes ? dataTypes.enabled : []}
                      deleteCandidatesPermanently={deleteCandidatesPermanently}
                      discardChanges={discardChanges}
                      documentTypes={documentTypes}
                      embedded={false}
                      getCandidates={getCandidates}
                      getInterviewDates={getInterviewDates}
                      interviewDates={interviewDates}
                      isInitializedCandidates={isInitializedCandidates}
                      isSaving={isSaving}
                      isSingleCandidate={true}
                      isUpdatingCandidateList={isUpdatingCandidateList}
                      mainChanges={changes}
                      match={match}
                      replacePhotoinCandidatesArray={replacePhotoinCandidatesArray}
                      resetFunctions={resetFunctions}
                      saveResults={saveResults}
                      selectedCandidate={selectedCandidate}
                      setCandidates={setCandidates}
                      setDocumentTypes={setDocumentTypes}
                      setIsSaving={setIsSaving}
                      setMainChanges={setChanges}
                      setSelectedCandidate={setSelectedCandidate}
                      setIsGettingCandidateDetails={setIsGettingCandidateDetails}
                      isGettingCandidateDetails={isGettingCandidateDetails}
                      token={token}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* {
            Unclear where to put this yet. This has the lock icon
            <Row>
              <AdminImportCandidates activeSeason={activeSeason} activeDepartment={activeDepartment} />
            </Row>
          } */}
        </Fragment>
      )}

      {currentTab === 2 && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <CandidateListWithFiltersX
              activeSeason={activeSeason}
              allCandidatesDetailsError={allCandidatesDetailsError}
              candidates={candidates}
              allCandidatesDetails={allCandidatesDetails}
              dataTypes={dataTypes}
              dContext={dContext}
              deleteCandidatesPermanently={deleteCandidatesPermanently}
              deleteCandidates={deleteCandidates}
              getCandidates={getCandidates}
              getAllCandidatesData={getAllCandidatesData}
              interviewDates={interviewDates}
              isInitializedCandidates={isInitializedCandidates}
              isGettingAllDetailedCandidates={isGettingAllDetailedCandidates}
              saveResults={saveResults}
              token={token}
            />
          </div>
        </div>
      )}
      {currentTab === 3 && renderImportData()}
      {/* {currentTab === 4 && renderManageFields()} */}
      {/* {currentTab === 5 && renderDocumentTypes()} */}
      {currentTab === 6 && renderPrintMaterials()}
      <AddNewCandidateModal
        showModal={showAddNewCandidate}
        token={token}
        setShowModal={setShowAddNewCandidate}
        getCandidates={getCandidates}
      />
      {renderScheduleAlertModal()}
      <RouterPrompt
        hasChanges={hasChanges || currentTab != tabToGo}
        okLabel={'Discard Changes'}
        onOk={() => {
          setCurrentTab(tabToGo);
          discardChanges();
          setChanges({
            candidateDetails: false,
          });

          return true;
        }}
      />
    </div>
  );
};

export default AdminCandidates;
