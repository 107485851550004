import React, { useState, useEffect } from 'react';
import style from './RezRateTable.style';
import { set } from 'lodash';

const RezRateTable = (props) => {
  const {
    columns, // Array of objects with keys: dataKey, label, width, cellFormatter, headerFormatter
    data, // Array of objects
    noDataMessage, // what to display when no data
    onRowClick, // function to call when a row is clicked
    tableBodyHeight,
    rowStyle = () => ({}), // Style for each row in the table
  } = props;

  // State variables
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableColumnsToDisplay, setTableColumnsToDisplay] = useState([]);
  const [tableDataToDisplay, setTableDataToDisplay] = useState([]); // filtered/sorted/processed version of tableData.

  // Effects
  useEffect(() => {
    // Fetch data or perform any side effects here
  }, []);

  useEffect(() => {
    setTableColumns(columns);
    setTableColumnsToDisplay(columns);
  }, [columns]);

  useEffect(() => {
    setTableData(data);
    setTableDataToDisplay(data);
  }, [data]);

  const processHeaderRow = (columnData) => {
    const headerColumns = [];

    columnData.forEach((header) => {
      const { dataKey, label, width, headerFormatter } = header;
      const headerId = `header-${dataKey}-${label}`;

      if (headerFormatter) {
        headerColumns.push(
          <div key={headerId} style={{ ...style.simpleColumn, width }}>
            <div style={{ ...style.simpleRow, padding: 5, fontWeight: 'bold' }}>{headerFormatter(header)}</div>
          </div>,
        );
        return;
      }
      headerColumns.push(
        <div key={headerId} style={{ ...style.simpleColumn, width }}>
          <div style={{ ...style.simpleRow, padding: 5, fontWeight: 'bold', justifyContent: 'center' }}>
            <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', width, textAlign: 'center' }}>{label}</div>
          </div>
        </div>,
      );
    });

    return <div style={{ ...style.simpleRow, border: '2px solid #cccaca' }}>{headerColumns}</div>;
  };

  const renderTable = () => {
    const rows = [];
    const headerRow = processHeaderRow(tableColumnsToDisplay);

    tableDataToDisplay.forEach((dataRow, i) => {
      const newRow = [];

      tableColumnsToDisplay.forEach((header) => {
        const { dataKey, width, cellFormatter } = header;

        if (cellFormatter) {
          newRow.push(
            <div style={{ ...style.simpleColumn, padding: 5, width, textOverflow: 'ellipsis' }}>
              {cellFormatter(dataRow[dataKey], dataRow)}
            </div>,
          );
        } else {
          newRow.push(
            <div
              style={{
                ...style.simpleColumn,
                padding: 5,
                width,
                justifyContent: 'center',
              }}
            >
              <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', width, textAlign: 'center' }}>
                {dataRow[dataKey] || ''}
              </div>
            </div>,
          );
        }
      });

      rows.push(
        <div
          style={{
            ...style.simpleRow,
            borderBottom: '1px solid #cccaca',
            backgroundColor: i % 2 == 0 ? 'white' : '#e3e1e1',
            cursor: onRowClick ? 'pointer' : 'default',
            ...rowStyle(dataRow),
          }}
          onClick={() => {
            if (onRowClick) {
              onRowClick(dataRow);
            }
          }}
        >
          {newRow}
        </div>,
      );
    });

    return (
      <div
        className="rez-rate-table"
        style={{
          ...style.simpleRow,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          overflowX: 'auto',
          width: '100%',
        }}
      >
        <div
          style={{
            ...style.simpleColumn,
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {headerRow}
          <div
            style={{
              ...style.simpleRow,
              overflowY: 'auto',
              height: tableBodyHeight,
              alignItems: 'flex-start',
              width: !rows || rows.length <= 0 ? '100%' : null,
            }}
          >
            <div style={{ ...style.simpleColumn }}>
              {rows.map((row, i) => {
                return row;
              })}
              {!rows || rows.length <= 0 ? (
                <div
                  style={{
                    ...style.simpleRow,
                    padding: 5,
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {noDataMessage || 'No Data'}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderTable();
};

export default RezRateTable;
