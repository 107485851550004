import {
  faCalendarAlt,
  faCheckCircle,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faPen,
  faPortrait,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toPath } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip.js';
import tinycolor from 'tinycolor2';
import style from './style.js';

const CandidateDropDownItem = ({
  candidate,
  onEditCandidate,
  onCalendarClick,
  interviewLimit,
  scheduleData,
  allowInterviewLimit,
  scheduleID,
}) => {
  const { PhotoUrl, CandidateSubHeading, HasBeenSentSchedule, FirstName = '', LastName = '' } = candidate || {};
  const { headers } = scheduleData;

  const history = useHistory();

  let candidateName = `---`;

  if (candidate) {
    candidateName = ` ${LastName ? `${LastName}, ` : ''} ${FirstName || ''}`;
  }
  return (
    <div
      style={{
        ...style.itemRow,
        backgroundColor: candidate.color,
        color: tinycolor(candidate.color).isLight() ? 'black' : 'white',
      }}
    >
      <div style={style.simpleColumn}>
        <div style={style.simpleRow}>
          <div style={{ ...style.simpleColumn, justifyContent: 'center', alignItems: 'center' }}>
            {PhotoUrl ? (
              <img src={PhotoUrl} style={style.imgPlaceHolder} />
            ) : (
              <FontAwesomeIcon style={style.imgPlaceHolder} icon={faPortrait} />
            )}
          </div>

          <div style={style.candidateInfo}>
            <div>{candidateName}</div>
            <div style={{ fontSize: 11, fontWeight: 'normal' }}>
              Inteviews: {candidate.CountOfScheduleInterviews || 0} /{' '}
              {allowInterviewLimit ? interviewLimit : headers.length}
            </div>
            <div style={{ fontSize: 11, fontWeight: 'normal' }}>
              {CandidateSubHeading ? CandidateSubHeading : '---'}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          <FontAwesomeIcon
            style={style.buttonIcon}
            icon={faPen}
            onClick={() => {
              window.open(`/administrator/candidates?pk_Candidate=${candidate.pk_Candidate}`, '_blank');
              // For Candidate AND Date. Doesn't work. Very sad. Could be date listener conflciting with candidate listener.
              // window.open(
              //   `/administrator/candidates?pk_Candidate=${candidate.pk_Candidate}&pk_InterviewDate=${scheduleID}`,
              //   '_blank',
              // );
            }}
          />
        </div>
        <div>
          <FontAwesomeIcon
            style={{ ...style.buttonIcon, cursor: candidate.ScheduleUUID ? 'pointer' : 'not-allowed' }}
            icon={faCalendarAlt}
            onClick={() => {
              if (candidate.ScheduleUUID != null) {
                // history.push(`/candidate/schedule/${candidate.ScheduleUUID}`);
                window.open(`/candidate/schedule/${candidate.ScheduleUUID}?preview=true`, '_blank');
              }
            }}
          />
        </div>
        <div>
          <FontAwesomeIcon
            id={`candidate_status_${candidate.pk_Candidate}`}
            style={{
              ...style.buttonIcon,
              color: HasBeenSentSchedule ? '#a1e3a5' : 'red',
              border: '2px solid',
              borderColor: tinycolor(candidate.color).isLight() ? 'black' : 'white',
              borderRadius: 20,
              backgroundColor: tinycolor(candidate.color).isLight() ? 'black' : 'white',
            }}
            icon={HasBeenSentSchedule ? faCheckCircle : faExclamationCircle}
          />
          <UncontrolledTooltip target={`candidate_status_${candidate.pk_Candidate}`}>
            {HasBeenSentSchedule ? 'Candidate has been sent schedule!' : 'Candidate has not been sent schedule!'}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

export default CandidateDropDownItem;
