import React, { useEffect, useState } from 'react';
import style from './ApplicantLogin.style';
import { Button, FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import Swal from 'sweetalert2';
import { fetchData } from 'Services/dataApi';
import { formatBearerToken } from 'Common.functions';

const ApplicantLogin = ({ setShowMode }) => {
  const [loginFailed, setLoginFailed] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPasswpord] = useState('');

  const [token, setToken] = useState(null);
  const history = useHistory();

  const { login, isAuthenticated, isLoading, getToken } = useKindeAuth();

  useEffect(() => {
    console.log('isLoading: ', isLoading);
    console.log('isAuthenticated: ', isAuthenticated);
    if (login && !isLoading) {
      if (isAuthenticated) {
        history.push('/applicant/applicant_profile');
      } else {
        console.log('login: ', login);
        // handleLogin();
        // login();
      }
    }
  }, [login, isLoading]);

  useEffect(() => {
    if (isAuthenticated) {
      getToken((token) => {
        // console.log('token: ', token);
        setToken(token);
      });
    }
  }, [isAuthenticated, getToken]);

  useEffect(() => {
    console.log('isAuthenticated change: ', isAuthenticated);
  }, [isAuthenticated]);

  const handleLogin = async () => {
    return login();
  };

  if (isLoading) {
    return (
      <div style={style.simpleRow}>
        {' '}
        <b>Loading. . .</b>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div style={style.simpleRow}>
        {' '}
        <b>Redirecting to login. . .</b>
      </div>
    );
  }
  return (
    <div style={style.simpleRow}>
      <div style={{ ...style.simpleColumn /*height: 'calc(100vh - 160px)'*/ }}>
        <div style={{ ...style.simpleRow, width: '80%', maxWidth: 400 }}>
          <div style={style.simpleColumn}>
            <div style={{ ...style.simpleRow, marginBottom: 10 }}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faUserAlt} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  disabled={isLoading}
                  placeholder="Enter Username. . ."
                  id="username"
                  invalid={loginFailed}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </InputGroup>
            </div>

            <div style={{ ...style.simpleRow, marginBottom: 10 }}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faKey} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  disabled={isLoading}
                  type="password"
                  invalid={loginFailed}
                  onChange={(e) => setPasswpord(e.target.value)}
                />
              </InputGroup>
            </div>

            <div style={style.simpleRow}>
              <Button
                color="success"
                disabled={isLoading || !username || !password}
                onClick={() => {
                  handleLogin();
                }}
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantLogin;
