import { faPortrait, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import style from './AdminRegisteredCandidates.style';
import { CustomInput, Label, UncontrolledTooltip } from 'reactstrap';
import Loading from 'Body/Statuses/Loading';

const AdminRegisteredCandidates = ({ candidates = [], interviewDate, isFetchingCandidates }) => {
  const [limitToDisplay, setLimitToDisplay] = useState(0);

  useEffect(() => {
    if (interviewDate) {
      const { EnableTagLimit, CandidateRegistrationLimit, tags } = interviewDate;
      let newLimitToDisplay = CandidateRegistrationLimit || 0;
      if (EnableTagLimit) {
        tags.forEach((tag) => {
          newLimitToDisplay += tag.InterviewDateLimit;
        });
      }

      setLimitToDisplay(newLimitToDisplay);
    }
  }, [interviewDate]);

  const getDatesStrings = (c) => {
    const { interviewDates = [], InterviewDatepk_InterviewDate } = c;

    const dateStrings = [];

    interviewDates.forEach((d) => {
      const { DateOfInterview, Label, StartTime, pk_InterviewDate } = d;
      dateStrings.push(
        `${moment.tz(`${DateOfInterview} ${StartTime}`, null).format('MMM DD, YYYY')} ${Label ? `(${Label})` : ''} ${
          pk_InterviewDate === InterviewDatepk_InterviewDate ? '[REGISTERED]' : ''
        }`,
      );
    });

    return dateStrings;
  };

  return (
    <div style={{ marginTop: 10 }}>
      <div style={style.title}>
        Registered Candidates {candidates.length}/{limitToDisplay || 0}
      </div>
      <div style={{ visibility: 'hidden' }}>
        <CustomInput
          id="showRegisterSwitch"
          type="switch"
          // checked={showRegistered}
          label="Show Registered"
          // onClick={(e) => {
          //   console.log('onCLick!!');
          //   setShowRegistered(!showRegistered);
          //   // e.stopPropagation();
          //   // setShowRegistered(!e.target.checked);
          // }}
          // onChange={(e) => {}}
        />
      </div>
      <div style={style.mainContainer}>
        <div style={{ ...style.column, width: '100%', width: 500 }}>
          {candidates && !isFetchingCandidates ? (
            candidates.map((candidate, i) => {
              let backgroundColor = '#548e2a';

              if (i % 2 === 0) {
                backgroundColor = '#66b132';
              }

              const {
                PhotoUrl,
                LastName,
                FirstName,
                CandidateSubHeading,
                Tags,
                WaitlistPreferenceOrder,
                NumberOfWaitlistedDates,
                CandidateResponseToInvitationTimestamp,
              } = candidate;

              const timeToDisplay = CandidateResponseToInvitationTimestamp
                ? moment(CandidateResponseToInvitationTimestamp, moment.tz.guess()).format('MM-DD-YYYY hh:mm A')
                : '---';

              let tags = 'None';
              if (Tags) {
                Tags.forEach((tag) => {
                  if (tag.Tag) {
                    if (tags === 'None') {
                      tags = `${tag.Tag}`;
                    } else {
                      tags += `, ${tag.Tag}`;
                    }
                  }
                });
              }

              let candidateName = `---`;
              if (candidate && LastName && FirstName) {
                candidateName = `${candidate.LastName}, ${candidate.FirstName}`;
              } else if (candidate && candidate.LastName) {
                candidateName = `${candidate.LastName}`;
              } else if (candidate && candidate.FirstName) {
                candidateName = ` ${candidate.FirstName}`;
              }
              return (
                <div style={{ ...style.itemRow, backgroundColor, color: 'white' }}>
                  {PhotoUrl ? (
                    <img src={PhotoUrl} width={80} style={style.imgPlaceHolder} />
                  ) : (
                    <FontAwesomeIcon style={style.imgPlaceHolder} icon={faPortrait} />
                  )}
                  <div style={{ ...style.column, width: '100%' }}>
                    <div style={style.candidateName}>{candidateName} </div>
                    <div style={style.medicalSchool}>
                      <b>Responded: </b>
                      {timeToDisplay}
                    </div>
                    <div style={style.medicalSchool}>{CandidateSubHeading || '---'}</div>
                    <div style={style.medicalSchool} id={`registered_${candidate.pk_Candidate}`}>
                      #
                      {WaitlistPreferenceOrder != null
                        ? `${WaitlistPreferenceOrder + 1} Choice`
                        : 'Registered by Admin'}{' '}
                      | {NumberOfWaitlistedDates != null ? NumberOfWaitlistedDates : '0'} Selected
                      {NumberOfWaitlistedDates > 0 ? (
                        <UncontrolledTooltip
                          placement="top"
                          flip={false}
                          target={`registered_${candidate.pk_Candidate}`}
                          modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
                        >
                          {getDatesStrings(candidate).map((d, i) => {
                            return (
                              <div style={{ textAlign: 'left' }}>
                                {i + 1}. {d}
                              </div>
                            );
                          })}
                        </UncontrolledTooltip>
                      ) : null}
                    </div>
                    <div style={style.medicalSchool}>
                      <FontAwesomeIcon icon={faTags} style={{ marginRight: 10 }} />
                      <span>{tags}</span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : isFetchingCandidates ? (
            <div style={{ marginTop: 100 }}>
              <Loading options={{ Label: 'Fetching candidates. .  .' }} />
            </div>
          ) : (
            <div>No Candidates</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminRegisteredCandidates;
